import * as translations from "../interface.json";
import { Machine } from "../base-types.gql-gen";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import dayjs from "dayjs";

export const machineMap = (machine: Machine): any => {
  const mainParam = getMachineText(machine);
  // @ts-ignore
  const category = translations[machine.typeData.name];
  return {
    ...machine,
    categoryName: `${category}`,
    mainParam,
    pricePerMinOrder: generatePriceTxt(
      machine.prices?.find((p: any) => p.type === "pricePerMinOrder")?.value,
    ),
    pricePerWorkShift: generatePriceTxt(
      machine.prices?.find((p: any) => p.type === "pricePerWorkShift")?.value,
    ),
    photo: machine.photos?.length && machine.photos[0]?.src,
  };
};

export const paymentType = (type: "none" | "cash" | "cashless" | "cashless_with_taxes"): string => {
  switch (type) {
    case "cashless_with_taxes":
      return "Безналичный расчет с НДС";
    case "cashless":
      return "Безналичный расчет без НДС";
    case "cash":
      return "Наличная оплата";
    default:
      return "Любой тип оплаты";
  }
};

const generatePriceTxt = (price: any) => {
  return Number.isInteger(price) ? price.toLocaleString() : "";
};

const getMachineText = (machine: any) => {
  const mainParam = machine.typeData.params.find((param: any) => param.paramPurpose.main);
  const mainParamWithValue = machine.params.find(
    (p: { refUuid: any }) => p.refUuid === mainParam?.uuid,
  );
  if (mainParam && mainParamWithValue?.value) {
    const valueName =
      typeof mainParamWithValue.value === "object"
        ? mainParamWithValue.value.name
        : mainParamWithValue.value;
    return `${valueName}${mainParam.unit || ""}`;
  }
  return "";
};

/**
 * @deprecated use maskito instead
 */
export function phoneFormatUniversal(phone = "") {
  const phoneNumber = phone.replace(/[-+()\s]/g, "");
  const regexp = /^(7|8)?(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/;
  const parts = phoneNumber.match(regexp);
  if (!phoneNumber.length || !parts) {
    return phone.substr(0, 18);
  }
  return parts.reduce((acc, val, index) => {
    if (index === 1) {
      return "+7";
    } else if (index === 2 && val) {
      return `${acc} (${val}`;
    } else if (index === 3 && val) {
      return `${acc}) ${val}`;
    } else if ((index === 4 || index === 5) && val) {
      return `${acc}-${val}`;
    }
    return acc;
  }, "");
}

/**
 * @deprecated use maskito instead
 */
export function phoneFormatOnlyNumbers(phone = "") {
  const number = phone.replace(/[^0-9]/g, "").match(/\d{10}$/);
  if (number?.[0].length) {
    return "7" + number[0];
  }
  return phone;
}

/**
 * @deprecated use maskito instead
 */
export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): null | { [key: string]: any } => {
    const value = control.value.replace(/\D/g, "");
    const isOk = /^7\d{10}$/.test(value);
    return isOk ? null : { invalidPhoneNumber: { value: control.value } };
  };
}

export function isDefined<T>(val: T): val is NonNullable<T> {
  return val !== undefined && val !== null;
}

export class CustomAssertError extends Error {}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (!isDefined(val)) {
    throw new CustomAssertError(`Expected 'x' to be defined, but received ${val}`);
  }
}

export const SUPPORT_PHONE_NUMBER = "+79052710561";

export const PARSED_ENTRY_MATCH_TTL = 3600; // 1 sec

export const callPhoneNumber = (phoneNumber: string) => {
  window.open(`tel:${phoneNumber}`);
};

export const PHONE_NUMBER_RU_MASK = [
  "+",
  "7",
  " ",
  "(",
  /\d/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export const cleanAddressText = (txt: string) => {
  return txt.replace("Россия,", "");
};

export const superSimpleSearch = (str: string, txt: string) => {
  if (!str) {
    return true;
  }
  const normStr = str.trim().toLowerCase().split(" ");
  const normTxt = txt.toLowerCase();
  return normStr.every((s) => normTxt.includes(s));
};

export const declension = (number: number, one: string, two: string, five: string): string => {
  let abs = Math.abs(number) % 100;
  if (abs >= 5 && abs <= 20) {
    return five;
  }
  abs %= 10;
  return abs === 1 ? one : abs >= 2 && abs <= 4 ? two : five;
};

export const translateWorkAmount = (type: "shift" | "cbm" | "trip", value: number) => {
  let workAmount = "";
  if (type === "shift" && value) {
    const suff = declension(value, "смену", "смены", "смен");
    workAmount = ["на", value, suff].join(" ");
  }
  if (type === "cbm" && value) {
    const suff = declension(value, "кубический метр", "кубических метра", "кубических метров");
    workAmount = ["на", value, suff].join(" ");
  }
  if (type === "trip" && value) {
    const suff = declension(value, "рейс", "рейса", "рейсов");
    workAmount = ["на", value, suff].join(" ");
  }
  return workAmount;
};

export const translateWorkDateTime = (
  conditionDate: {
    type: "date" | "datetime" | "none";
    value: number;
  },
  translateToday: boolean,
) => {
  let workDateTime: string;
  let isWDToday = false;
  const { type, value } = conditionDate;
  const tsms = (value ?? 0) * 1000;
  if (type === "none") {
    workDateTime = "Дата обсуждаема";
  } else {
    const formattedDate = dayjs(tsms).format("YYYY-MM-DD");
    const formattedNow = dayjs().format("YYYY-MM-DD");
    isWDToday = formattedDate === formattedNow;
    if (translateToday && isWDToday) {
      workDateTime = "Сегодня";
    } else {
      workDateTime = dayjs(tsms).format("DD MMMM (dd)");
    }
    if (type === "datetime") {
      const formattedHours = dayjs(tsms).format("HH:mm");
      workDateTime = workDateTime + " " + formattedHours;
    }
  }
  return { workDateTime, isWDToday };
};

export function callNumber(number: string) {
  window.open(`tel:${number}`);
}

export const GRAPHQL_URI = "/v2/graphql";

export const BACKEND_URLS = {
  production: "https://api.rental-club.ru",
  development: "https://api.dev.rclb.ru",
};

export const FRONTEND_URLS = {
  production: "https://mobapp.rental-club.ru",
  development: "https://mobapp.dev.rclb.ru",
  local: "http://localhost:4200",
  localLeraPC: "http://192.168.1.138:4200",
  localDimaMac: "http://192.168.1.134:4200",
};
