<ion-header>
  <ion-toolbar>
    @if (machineId) {
      <ion-title>Редактирование техники</ion-title>
      <ion-buttons slot="secondary">
        <ion-button (click)="onDeleteMachineIconClick($event)">
          <ion-icon color="rcred" slot="icon-only" name="trash-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    } @else {
      <ion-title>Добавление техники</ion-title>
    }
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/home/profile?segment=machines'"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  @if (!selectedMachineType) {
    <ion-list class="ion-padding">
      @for (item of machineTypes; track item.uuid) {
        <ion-item class="ion-no-padding" button detail="true">
          <ion-label (click)="onMachineTypeClick($event, item)">
            <span>{{ item.ruName }}</span>
          </ion-label>
        </ion-item>
      }
    </ion-list>
  } @else {
    <swiper-container #photoSwiperContainer [modules]="swiperModules" [pagination]="true">
      @for (photo of machinePhotos; track photo.url) {
        <swiper-slide (click)="onPhotoItemClick($event, photo)">
          <ion-img [src]="photo.url" class="photo-background"></ion-img>
          <ion-img [src]="photo.url"></ion-img>
        </swiper-slide>
      }
      <ion-action-sheet #changePhotoActionSheet [buttons]="changePhotoButtons"></ion-action-sheet>
      <swiper-slide>
        <div class="machineProfilePic ion-no-padding" id="profile-machine-edit-get-picture">
          <ion-text>Добавить фото</ion-text>
          <ion-icon class="machineProfilePicIcon" name="camera-outline"></ion-icon>
        </div>
      </swiper-slide>
    </swiper-container>
    <ion-action-sheet
      [buttons]="addNewPhotoButtons"
      trigger="profile-machine-edit-get-picture"
    ></ion-action-sheet>
    <ion-list class="ion-padding">
      <ion-item lines="none" class="ion-no-padding">
        <ion-label>
          <h1>{{ selectedMachineType.ruName }}</h1>
        </ion-label>
      </ion-item>
      <ng-container [formGroup]="machineMainGroup">
        <ion-item class="ion-no-padding rcitem">
          <ion-input
            formControlName="model"
            labelPlacement="fixed"
            color="rcyellow"
            class="rcinput-fixed-wide"
          >
            <div slot="label">
              <ion-text>Марка и модель</ion-text>
              <ion-text color="rcred">&nbsp;*</ion-text>
            </div>
          </ion-input>
        </ion-item>
        <ion-item class="ion-no-padding rcitem">
          <ion-input
            formControlName="year"
            labelPlacement="fixed"
            color="rcyellow"
            class="rcinput-fixed-wide"
            inputmode="numeric"
          >
            <div slot="label">
              <ion-text>Год выпуска</ion-text>
              <ion-text color="rcred">&nbsp;*</ion-text>
            </div>
          </ion-input>
        </ion-item>
      </ng-container>
      <ng-container [formGroup]="machineParamRecord">
        @for (param of selectedMachineType.params; track param.uuid) {
          <ion-item class="ion-no-padding rcitem">
            @if (param.type === "range") {
              <ion-input
                [formControlName]="param.name"
                labelPlacement="fixed"
                class="rcinput-fixed-wide"
                inputmode="numeric"
                [placeholder]="param.placeholder"
              >
                <div slot="label">
                  <ion-text>{{ param.ruName }}</ion-text>
                  @if (param.unit) {
                    <ion-text>&nbsp;({{ param.unit }})</ion-text>
                  }
                  @if (param.required) {
                    <ion-text color="rcred">&nbsp;*</ion-text>
                  }
                </div>
              </ion-input>
            }
            @if (param.type === "boolean") {
              <ion-toggle [formControlName]="param.name" color="rcyellow" mode="ios">
                <ion-text>{{ param.ruName }}</ion-text>
              </ion-toggle>
            }
            @if (param.type === "select") {
              <ion-select
                [formControlName]="param.name"
                cancelText="Отменить"
                okText="Подтвердить"
                color="dark"
              >
                <div slot="label">
                  <ion-text>{{ param.ruName }}</ion-text>
                  @if (param.required) {
                    <ion-text color="rcred">&nbsp;*</ion-text>
                  }
                </div>
                @for (option of param.settings.select; track option.value) {
                  <ion-select-option [value]="option.value">{{ option.name }}</ion-select-option>
                }
              </ion-select>
            }
          </ion-item>
        }
      </ng-container>
      <ng-container [formGroup]="machineAdditionalGroup">
        <ion-item class="ion-no-padding rcitem">
          <ion-input
            formControlName="pricePerMinOrder"
            labelPlacement="fixed"
            color="rcyellow"
            class="rcinput-fixed-wide"
            inputmode="numeric"
            [maskito]="priceMask"
            [maskitoElement]="maskPredicate"
          >
            <div slot="label">
              <ion-text>Цена за мин.заказ (руб)</ion-text>
            </div>
          </ion-input>
        </ion-item>
        <ion-item class="ion-no-padding rcitem">
          <ion-input
            formControlName="pricePerWorkShift"
            labelPlacement="fixed"
            color="rcyellow"
            class="rcinput-fixed-wide"
            inputmode="numeric"
            [maskito]="priceMask"
            [maskitoElement]="maskPredicate"
          >
            <div slot="label">
              <ion-text>Цена за смену, 8 ч (руб)</ion-text>
            </div>
          </ion-input>
        </ion-item>
        <ion-item class="ion-no-padding rcitem">
          <ion-input
            formControlName="reg"
            labelPlacement="fixed"
            color="rcyellow"
            class="rcinput-fixed-wide"
          >
            <div slot="label">
              <ion-text>Гос.номер или VIN</ion-text>
            </div>
          </ion-input>
        </ion-item>
      </ng-container>
      @for (equip of machineSelectedEquips; track equip.uuid) {
        <ion-item class="ion-no-padding rcitem">
          <ion-icon
            (click)="onSelectedEquipDeleteClick($event, equip)"
            class="ion-padding-end"
            name="close-circle"
          ></ion-icon>
          <ion-text>{{ equip.ruName }}</ion-text>
          @if (equip.imgUrl) {
            <ion-thumbnail slot="end">
              <img [src]="equip.imgUrl" />
            </ion-thumbnail>
          }
        </ion-item>
      }
      @if (addEquipButtons.length) {
        <ion-item class="ion-no-padding rcitem" id="profile-machine-add-edit-add-equip">
          <ion-icon name="add-circle" class="ion-padding-end"></ion-icon>
          <ion-text>Добавить оборудование</ion-text>
        </ion-item>
        <ion-action-sheet
          trigger="profile-machine-add-edit-add-equip"
          [buttons]="addEquipButtons"
        ></ion-action-sheet>
      }
      <ng-container [formGroup]="machineAdditionalGroup">
        <ion-item class="ion-no-padding rcitem">
          <ion-textarea
            [autoGrow]="true"
            [rows]="5"
            formControlName="description"
            labelPlacement="fixed"
            color="rcyellow"
            class="rcinput-fixed-wide"
            placeholder="Пример: Аренда по всей области, мин.заказ от 5 часов, форма оплаты любая, опытный оператор, техника в хорошем состоянии"
          >
            <div slot="label">
              <ion-text>Описание</ion-text>
            </div>
          </ion-textarea>
        </ion-item>
      </ng-container>
    </ion-list>
  }
</ion-content>
@if (selectedMachineType) {
  <ion-footer class="ion-no-border">
    <ion-button
      [disabled]="
        machineMainGroup.invalid || machineParamRecord.invalid || machineAdditionalGroup.invalid
      "
      (click)="onMachineSaveBtnClick($event)"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      Сохранить
    </ion-button>
  </ion-footer>
}
