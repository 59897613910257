import { Route } from "@angular/router";
import { SupportVersionInfoComponent } from "./support-version-info/support-version-info.component";
import { EntryRequestFormComponent } from "./entry-request-form/entry-request-form.component";
import { EntrySignComponent } from "./entry-sign/entry-sign.component";
import { ProfileMachineBaseSetComponent } from "./profile-machine-base-set/profile-machine-base-set.component";
import { EntriesTabComponent } from "./home-page/entries-tab/entries-tab.component";
import { MatchesTabComponent } from "./home-page/matches-tab/matches-tab.component";
import { ProfileTabComponent } from "./home-page/profile-tab/profile-tab.component";
import { SupportTabComponent } from "./home-page/support-tab/support-tab.component";
import { EntryDetailsMachineComponent } from "./entry-details-machine/entry-details-machine.component";
import { EntryMatchParsedDetailsComponent } from "./entry-match-parsed-details/entry-match-parsed-details.component";
import { EntryMatchInternalDetailsComponent } from "./entry-match-internal-details/entry-match-internal-details.component";
import { ProposeSupplierFormComponent } from "./propose-supplier-form/propose-supplier-form.component";
import { ErrorHandledComponent } from "./error-handled/error-handled.component";
import { SignInWithPhoneNumberComponent } from "./sign-in-with-phone-number/sign-in-with-phone-number.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { RegistrationPageComponent } from "./registration-page/registration-page.component";
import { RegistrationUnavailableRegionComponent } from "./registration-unavailable-region/registration-unavailable-region.component";
import { RegistrationProfileComponent } from "./registration-profile/registration-profile.component";
import { RegistrationCompanyComponent } from "./registration-company/registration-company.component";
import { ProfilePersonEditComponent } from "./profile-person-edit/profile-person-edit.component";
import { ProfileMachineFormComponent } from "./profile-machine-form/profile-machine-form.component";
import { ProfileMachineViewingComponent } from "./profile-machine-viewing/profile-machine-viewing.component";
import { CatalogMachineViewingComponent } from "./catalog-machine-viewing/catalog-machine-viewing.component";
import { ProfileMachineOwnershipComponent } from "./profile-machine-ownership/profile-machine-ownership.component";
import { ProfileNotificationSettingsComponent } from "./profile-notification-settings/profile-notification-settings.component";
import { SecretSettingsComponent } from "./secret-settings/secret-settings.component";
import { Welcome2PageComponent } from "./welcome2-page/welcome2-page.component";

export const appRoutes: Route[] = [
  {
    path: "home",
    component: HomePageComponent,
    children: [
      {
        path: "entries",
        component: EntriesTabComponent,
      },
      {
        path: "matches",
        component: MatchesTabComponent,
      },
      {
        path: "profile",
        component: ProfileTabComponent,
      },
      {
        path: "support",
        component: SupportTabComponent,
      },
    ],
  },
  {
    path: "sign-in",
    component: SignInWithPhoneNumberComponent,
  },
  {
    path: "registration",
    component: RegistrationPageComponent,
  },
  {
    path: "registration-unavailable-region",
    component: RegistrationUnavailableRegionComponent,
  },
  {
    path: "registration-profile",
    component: RegistrationProfileComponent,
  },
  {
    path: "registration-company",
    component: RegistrationCompanyComponent,
  },
  {
    path: "profile-person-edit",
    component: ProfilePersonEditComponent,
  },
  {
    path: "profile-machine-form",
    component: ProfileMachineFormComponent,
  },
  {
    path: "profile-machine-viewing",
    component: ProfileMachineViewingComponent,
  },
  {
    path: "catalog-machine-viewing",
    component: CatalogMachineViewingComponent,
  },
  {
    path: "profile-machine-ownership",
    component: ProfileMachineOwnershipComponent,
  },
  {
    path: "profile-notification-settings",
    component: ProfileNotificationSettingsComponent,
  },
  {
    path: "profile-machine-base-set",
    component: ProfileMachineBaseSetComponent,
  },
  {
    path: "entry-details-machine",
    component: EntryDetailsMachineComponent,
  },
  {
    path: "entry-request-form",
    component: EntryRequestFormComponent,
  },
  {
    path: "entry-sign",
    component: EntrySignComponent,
  },
  {
    path: "support-version-info",
    component: SupportVersionInfoComponent,
  },
  {
    path: "welcome2",
    component: Welcome2PageComponent,
  },
  {
    path: "entry-match-parsed-details",
    component: EntryMatchParsedDetailsComponent,
  },
  {
    path: "entry-match-internal-details",
    component: EntryMatchInternalDetailsComponent,
  },
  {
    path: "propose-supplier-form",
    component: ProposeSupplierFormComponent,
  },
  {
    path: "error-handled",
    component: ErrorHandledComponent,
  },
  {
    path: "secret-settings",
    component: SecretSettingsComponent,
  },
  { path: "", redirectTo: "/welcome2", pathMatch: "full" },
];
