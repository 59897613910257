import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
  Entry,
  EntryProposes,
  EnumEntryParticipantsType,
  Maybe,
} from "../../../base-types.gql-gen";
import * as translations from "../../../interface.json";
import { MaskitoPipe } from "@maskito/angular";
import { MaskitoOptions } from "@maskito/core";
import { maskitoNumberOptionsGenerator } from "@maskito/kit";

@Component({
  selector: "app-entry-sign-price",
  templateUrl: "./entry-sign-price.component.html",
  styleUrls: ["./entry-sign-price.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MaskitoPipe],
})
export class EntrySignPriceComponent {
  @Input() myPropose?: Maybe<EntryProposes>;
  @Input() entry?: Entry;
  @Input() viewAs: EnumEntryParticipantsType = "customer";

  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });

  get payment() {
    return this.myPropose?.requests?.[0]?.payment;
  }

  get proposePaymentType(): string {
    const payment = this.payment?.type;
    switch (payment) {
      case "cashless_with_taxes":
        return "Б/Н с НДС";
      case "cashless":
        return "Б/Н без НДС";
      case "cash":
        return "Наличные/карта";
      default:
        return "неизвестный тип оплаты";
    }
  }

  get price() {
    const paymentValue =
      this.payment?.value || this.myPropose?.responses?.[0]?.data?.budgetValue || 0;
    const proposalPrice = paymentValue / 100;
    const customerPrice =
      (this.entry?.additional?.tsastCustomerPrice / 100) *
        (this.entry?.additional.tsastHoursPerShift || 8) || proposalPrice;
    return this.viewAs === "customer" ? customerPrice : proposalPrice;
  }
  get services() {
    return this.entry?.requests[0]?.data?.services?.map((s) => {
      return {
        // @ts-ignore
        name: translations[s?.name],
        price: ((this.viewAs === "customer" ? s?.priceCustomer : s?.price) || 0) / 100,
      };
    });
  }
  get equipments() {
    return this.entry?.requests[0]?.data?.equipments?.map((e) => {
      return {
        // @ts-ignore
        name: translations[e?.name],
        price: ((this.viewAs === "customer" ? e?.priceCustomer : e?.price) || 0) / 100,
      };
    });
  }
  get unitWithoutCount(): string {
    switch (this.myPropose?.requests?.[0]?.conditions?.work?.type) {
      case "trip":
        return "рейс";
      case "cbm":
        return "куб";
      default:
        return "смена";
    }
  }
}
