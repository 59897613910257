import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryRequestFormDispatcherOrganizationsByNameQueryVariables = Types.Exact<{
  input: Types.Scalars['String']['input'];
}>;


export type EntryRequestFormDispatcherOrganizationsByNameQuery = { __typename?: 'Query', organizationsByName: Array<{ __typename?: 'Organization', id: string, ref: string, name?: string | null }> };

export type EntryRequestFormDispatcherAuthOrganizationsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID']['input'];
}>;


export type EntryRequestFormDispatcherAuthOrganizationsQuery = { __typename?: 'Query', authOrganizations: Array<{ __typename?: 'Organization', id: string, ref: string, objects: Array<{ __typename?: 'Object', ref: string, organizationRef?: string | null, title?: string | null, ownerName?: string | null, address?: { __typename?: 'ObjectAddress', fullAddress?: string | null, coordinates?: Array<number | null> | null } | null }>, contacts: Array<{ __typename?: 'Contact', id: string, ref: string, name: string, contact?: { __typename?: 'ContactContact', value: string, type: string } | null }> }> };

export type EntryRequestFormDispatcherSearchProvidersCountQueryVariables = Types.Exact<{
  machineTypeId: Types.Scalars['String']['input'];
  addressCoords: Array<Types.Scalars['Float']['input']> | Types.Scalars['Float']['input'];
  machineParams: Array<Types.SearchProvidersMachineParam> | Types.SearchProvidersMachineParam;
}>;


export type EntryRequestFormDispatcherSearchProvidersCountQuery = { __typename?: 'Query', searchProvidersCount: number };

export type EntryRequestFormDispatcherAuthCreateContactMutationVariables = Types.Exact<{
  contact: Types.ContactInput;
}>;


export type EntryRequestFormDispatcherAuthCreateContactMutation = { __typename?: 'Mutation', authCreateContact: { __typename?: 'Contact', id: string, ref: string } };

export type EntryRequestFormDispatcherSearchCreateRequestMutationVariables = Types.Exact<{
  data: Types.CreateEntryInput;
}>;


export type EntryRequestFormDispatcherSearchCreateRequestMutation = { __typename?: 'Mutation', searchCreateRequest: { __typename?: 'Entry', id: string, uuid: string, availableActions: Array<Types.EnumEntryAvailableActionsType> } };

export type EntryRequestFormDispatcherAuthCreateObjectMutationVariables = Types.Exact<{
  coordinates: Array<Types.Scalars['Float']['input']> | Types.Scalars['Float']['input'];
  fullAddress: Types.Scalars['String']['input'];
  ownerOrganizationId: Types.Scalars['ID']['input'];
  onDuplicate: Types.OnDuplicate;
}>;


export type EntryRequestFormDispatcherAuthCreateObjectMutation = { __typename?: 'Mutation', authCreateObject: { __typename?: 'Object', id: string, ref: string } };

export const EntryRequestFormDispatcherOrganizationsByNameDocument = gql`
    query EntryRequestFormDispatcherOrganizationsByName($input: String!) {
  organizationsByName(input: $input) {
    id
    ref
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormDispatcherOrganizationsByNameQueryGql extends Apollo.Query<EntryRequestFormDispatcherOrganizationsByNameQuery, EntryRequestFormDispatcherOrganizationsByNameQueryVariables> {
    override document = EntryRequestFormDispatcherOrganizationsByNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormDispatcherAuthOrganizationsDocument = gql`
    query EntryRequestFormDispatcherAuthOrganizations($organizationId: ID!) {
  authOrganizations(ids: [$organizationId]) {
    id
    ref
    objects {
      ref
      organizationRef
      address {
        fullAddress
        coordinates
      }
      title
      ownerName
    }
    contacts {
      id
      ref
      name
      contact {
        value
        type
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormDispatcherAuthOrganizationsQueryGql extends Apollo.Query<EntryRequestFormDispatcherAuthOrganizationsQuery, EntryRequestFormDispatcherAuthOrganizationsQueryVariables> {
    override document = EntryRequestFormDispatcherAuthOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormDispatcherSearchProvidersCountDocument = gql`
    query EntryRequestFormDispatcherSearchProvidersCount($machineTypeId: String!, $addressCoords: [Float!]!, $machineParams: [SearchProvidersMachineParam!]!) {
  searchProvidersCount(
    machineTypeId: $machineTypeId
    machineParams: $machineParams
    addressCoords: $addressCoords
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormDispatcherSearchProvidersCountQueryGql extends Apollo.Query<EntryRequestFormDispatcherSearchProvidersCountQuery, EntryRequestFormDispatcherSearchProvidersCountQueryVariables> {
    override document = EntryRequestFormDispatcherSearchProvidersCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormDispatcherAuthCreateContactDocument = gql`
    mutation EntryRequestFormDispatcherAuthCreateContact($contact: ContactInput!) {
  authCreateContact(contact: $contact) {
    id
    ref
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormDispatcherAuthCreateContactMutationGql extends Apollo.Mutation<EntryRequestFormDispatcherAuthCreateContactMutation, EntryRequestFormDispatcherAuthCreateContactMutationVariables> {
    override document = EntryRequestFormDispatcherAuthCreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormDispatcherSearchCreateRequestDocument = gql`
    mutation EntryRequestFormDispatcherSearchCreateRequest($data: CreateEntryInput!) {
  searchCreateRequest(data: $data) {
    id
    uuid
    availableActions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormDispatcherSearchCreateRequestMutationGql extends Apollo.Mutation<EntryRequestFormDispatcherSearchCreateRequestMutation, EntryRequestFormDispatcherSearchCreateRequestMutationVariables> {
    override document = EntryRequestFormDispatcherSearchCreateRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormDispatcherAuthCreateObjectDocument = gql`
    mutation EntryRequestFormDispatcherAuthCreateObject($coordinates: [Float!]!, $fullAddress: String!, $ownerOrganizationId: ID!, $onDuplicate: OnDuplicate!) {
  authCreateObject(
    coordinates: $coordinates
    fullAddress: $fullAddress
    ownerOrganizationId: $ownerOrganizationId
    onDuplicate: $onDuplicate
  ) {
    id
    ref
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormDispatcherAuthCreateObjectMutationGql extends Apollo.Mutation<EntryRequestFormDispatcherAuthCreateObjectMutation, EntryRequestFormDispatcherAuthCreateObjectMutationVariables> {
    override document = EntryRequestFormDispatcherAuthCreateObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }