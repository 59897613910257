import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfilePersonEditAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfilePersonEditAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', id: string, firstname?: string | null, lastname?: string | null, phone: string, avatar?: string | null, email?: string | null, organizations: Array<{ __typename?: 'Organization', uuid: string } | null> } | null };

export type ProfilePersonEditAuthDeleteUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type ProfilePersonEditAuthDeleteUserMutation = { __typename?: 'Mutation', authDeleteUser: { __typename?: 'OkResult', ok?: boolean | null } };

export const ProfilePersonEditAuthCurrentUserDocument = gql`
    query ProfilePersonEditAuthCurrentUser {
  authCurrentUser {
    id
    firstname
    lastname
    phone
    avatar
    email
    organizations {
      uuid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfilePersonEditAuthCurrentUserQueryGql extends Apollo.Query<ProfilePersonEditAuthCurrentUserQuery, ProfilePersonEditAuthCurrentUserQueryVariables> {
    override document = ProfilePersonEditAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfilePersonEditAuthDeleteUserDocument = gql`
    mutation ProfilePersonEditAuthDeleteUser($userId: String!) {
  authDeleteUser(userId: $userId) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfilePersonEditAuthDeleteUserMutationGql extends Apollo.Mutation<ProfilePersonEditAuthDeleteUserMutation, ProfilePersonEditAuthDeleteUserMutationVariables> {
    override document = ProfilePersonEditAuthDeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }