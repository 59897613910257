import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { AddressPickComponent } from "../address-pick/address-pick";

@Component({
  selector: "app-address-new",
  templateUrl: "address-new.html",
  styleUrls: ["./address-new.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonFooter,
    AddressPickComponent,
  ],
})
export class AddressNewComponent {
  @Input() previousAddresses: any[] = [];
  @Input() address?: any;

  constructor(private modalCtrl: ModalController) {}

  setAddress(address: any) {
    this.address = address;
  }

  async close(sendData: boolean = false) {
    if (!sendData) {
      return this.modalCtrl.dismiss();
    }
    if (!this.address || !this.address.full) {
      return this.modalCtrl.dismiss(null);
    }

    return this.modalCtrl.dismiss({
      fullAddress: this.address.full,
      coordinates: [this.address.lng, this.address.lat],
      type: "point",
    });
  }
}
