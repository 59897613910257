import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  ToastController,
} from "@ionic/angular/standalone";
import { MaskitoOptions, maskitoTransform } from "@maskito/core";
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from "@maskito/kit";
import { MaskitoDirective } from "@maskito/angular";
import {
  ProposeSupplierFormSearchEditProposeMutationGql,
  ProposeSupplierFormSearchSendProposeFromSupplierMutationGql,
  ProposeSupplierFormSearchSupplierProposeDataQueryGql,
} from "./propose-supplier-form.component.gql-gen";
import { Subscription } from "rxjs";
import { assertIsDefined } from "../utils";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  SearchEditProposeData,
  SearchSendProposeFromSupplierInput,
  SearchSendProposeFromSupplierPaymentType,
} from "../../base-types.gql-gen";
import { EntryMatchInternalDetailsQueryParams } from "../entry-match-internal-details/entry-match-internal-details.component";

type MachineUIData = {
  id: string;
  title: string;
};

export type ProposeSupplierFormQueryParams = {
  entryId: string;
};

@Component({
  selector: "app-propose-supplier-form",
  standalone: true,
  imports: [
    IonBackButton,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonList,
    IonInput,
    IonTextarea,
    IonText,
    IonFooter,
    IonButton,
    MaskitoDirective,
    ReactiveFormsModule,
  ],
  templateUrl: "./propose-supplier-form.component.html",
  styleUrl: "./propose-supplier-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposeSupplierFormComponent implements OnInit, OnDestroy {
  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });
  protected readonly maskPredicate = async (el: unknown) =>
    (el as HTMLIonInputElement).getInputElement();
  protected machineState = {
    pending: false,
    machines: [] as MachineUIData[],
  };
  protected paymentTypes = [
    { value: "cash", label: "Наличные/карта" },
    { value: "cashless_with_taxes", label: "Безнал. НДС" },
    { value: "cashless", label: "Безнал. без НДС" },
  ];
  protected sendProposePending = false;

  private entrySub?: Subscription;
  private sendProposalSub?: Subscription;

  protected proposalForm = this.fb.nonNullable.group({
    entryMatchId: this.fb.control("", [Validators.required, Validators.minLength(1)]),
    proposeId: this.fb.control("", []),
    machineId: this.fb.control("", [Validators.required, Validators.minLength(1)]),
    paymentType: this.fb.control("", [Validators.required]),
    price: this.fb.control("", [Validators.required]),
    comment: this.fb.control(""),
  });

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private searchSupplierProposeDataQueryGql: ProposeSupplierFormSearchSupplierProposeDataQueryGql,
    private searchSendProposeFromSupplierMutationGql: ProposeSupplierFormSearchSendProposeFromSupplierMutationGql,
    private searchEditProposeMutationGql: ProposeSupplierFormSearchEditProposeMutationGql,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
  ) {}

  ngOnInit() {
    this.machineState.pending = true;
    const queryParams = this.activatedRoute.snapshot.queryParams as ProposeSupplierFormQueryParams;
    const entryId = queryParams.entryId;
    assertIsDefined(entryId);
    this.proposalForm.controls.entryMatchId.patchValue(entryId);
    this.entrySub = this.searchSupplierProposeDataQueryGql.fetch({ entryId }).subscribe({
      next: (res) => {
        const { searchSupplierProposeData } = res.data;
        assertIsDefined(searchSupplierProposeData);
        const { sutableMachines, propose } = searchSupplierProposeData;
        assertIsDefined(sutableMachines);

        for (const machine of sutableMachines) {
          const mach: MachineUIData = {
            id: machine.machineId,
            title: machine.machineName,
          };
          this.machineState.machines.push(mach);

          if (propose) {
            this.proposalForm.controls.proposeId.patchValue(propose.proposeId);
            this.proposalForm.controls.machineId.patchValue(propose.machineId);
            const price = maskitoTransform(propose.price + "", this.priceMask);
            this.proposalForm.controls.price.patchValue(price);
            this.proposalForm.controls.comment.patchValue(propose.comment ?? "");
            this.proposalForm.controls.paymentType.patchValue(propose.paymentType);
          }
        }

        this.machineState.pending = false;
        this.cdRef.markForCheck();
      },
    });
  }

  ngOnDestroy() {
    this.entrySub?.unsubscribe();
    this.sendProposalSub?.unsubscribe();
  }

  onSendProposeBtnClick(ev: Event) {
    ev.preventDefault();
    if (!this.proposalForm.valid) {
      return;
    }

    let price = 0;
    if (this.proposalForm.value.price) {
      const val = maskitoParseNumber(this.proposalForm.value.price);
      if (val) {
        price = val;
      }
    }
    const { machineId, entryMatchId, proposeId } = this.proposalForm.value;
    assertIsDefined(machineId);
    assertIsDefined(entryMatchId);

    let paymentType: SearchSendProposeFromSupplierPaymentType | undefined;
    if (this.proposalForm.value.paymentType === "cash") {
      paymentType = this.proposalForm.value.paymentType;
    }
    if (this.proposalForm.value.paymentType === "cashless_with_taxes") {
      paymentType = this.proposalForm.value.paymentType;
    }
    if (this.proposalForm.value.paymentType === "cashless") {
      paymentType = this.proposalForm.value.paymentType;
    }
    assertIsDefined(paymentType);

    const comment = this.proposalForm.value.comment ?? null;

    this.sendProposePending = true;
    this.sendProposalSub?.unsubscribe();
    if (proposeId) {
      const dto: SearchEditProposeData = {
        proposeId,
        machineId,
        comment,
        paymentType,
        price,
      };
      this.sendProposalSub = this.searchEditProposeMutationGql.mutate({ dto }).subscribe({
        next: async () => {
          this.sendProposePending = false;
          await this.showProposeToast(ev);
          await this.navigateToMatchDetails();
        },
      });
    } else {
      const dto: SearchSendProposeFromSupplierInput = {
        price,
        machineId,
        entryMatchId,
        paymentType,
        comment,
      };
      this.sendProposalSub = this.searchSendProposeFromSupplierMutationGql
        .mutate({ dto })
        .subscribe({
          next: async () => {
            this.sendProposePending = false;
            await this.showProposeToast(ev);
            await this.navigateToMatchDetails();
          },
        });
    }
  }

  private async showProposeToast(ev: Event) {
    const toast = await this.toastCtrl.create({
      icon: "checkmark-circle",
      message: "Предложение отправлено",
      position: "bottom",
      positionAnchor: (ev.target as HTMLElement).closest("ion-button") as HTMLElement,
      color: "rcgreen",
      duration: 5000,
    });
    await toast.present();
  }

  private async navigateToMatchDetails() {
    const { entryMatchId } = this.proposalForm.value;
    assertIsDefined(entryMatchId);

    const queryParams: EntryMatchInternalDetailsQueryParams = {
      entryId: entryMatchId,
      segment: "responses",
    };
    await this.router.navigate(["/entry-match-internal-details"], {
      queryParams,
      replaceUrl: true,
    });
  }
}
