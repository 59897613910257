import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { MachineType, MachineTypeParams } from "../../../base-types.gql-gen";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonListHeader,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { EntryParamsListComponent } from "../params-list/entry-params-list.component";

export interface MachineParamsValues extends MachineTypeParams {
  value: any;
}

@Component({
  selector: "app-entry-params",
  templateUrl: "entry-params.component.html",
  styleUrls: ["./entry-params.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonItem,
    IonFooter,
    IonListHeader,
    EntryParamsListComponent,
    IonButton,
  ],
})
export class EntryParamsComponent implements OnInit {
  @Input() params: any[] = [];
  @Input() category!: MachineType;
  modal = true;
  onlyApproved = false;
  machineParams: MachineParamsValues[] = [];
  countParams = 0;
  public dataWasChanged = false;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.category.params?.forEach((cp: MachineTypeParams | null) => {
      const p = this.params.find((mp) => cp?.uuid === mp?.refUuid);
      const value =
        cp?.type === "range"
          ? {
              lower: p?.value?.[0] || cp?.settings.min,
              upper: p?.value?.[1] || cp?.settings.max,
            }
          : p?.value;
      if (cp) this.machineParams.push({ ...cp, value });
    });

    this.countParams = this.machineParams.filter((v) => !v?.isEquipment).length;
  }

  get optionalEquipmentExists(): boolean {
    return this.machineParams.filter((param) => param.isEquipment).length > 0;
  }

  close(saveData?: boolean) {
    if (saveData) {
      let errors = "";
      this.machineParams.forEach((v) => {
        if (v.type === "range" && (v.settings?.min || v.settings?.max)) {
          if (
            v.settings?.min &&
            v.settings?.max &&
            v.value &&
            (v.value > v.settings?.max || v.value < v.settings?.min)
          ) {
            errors += `<br><strong>- ${v.name} - значение может быть в диапазоне от ${v.settings?.min} до ${v.settings?.max}</strong>`;
          } else if (v.settings?.min && v.value && v.value < v.settings?.min) {
            errors += `<br><strong>- ${v.name} - значение не может быть меньше ${v.settings?.min}</strong>`;
          } else if (v.settings?.max && v.value && v.value > v.settings?.max) {
            errors += `<br><strong>- ${v.name} - значение не может быть больше ${v.settings?.max}</strong>`;
          }
        }
      });
      if (errors.length > 0) {
        return false;
      }
      this.saveMachineParams();
    }
    return this.modalCtrl.dismiss();
  }

  saveMachineParams() {
    const machineParams =
      this.machineParams && this.machineParams.length > 0
        ? this.machineParams.map((v) => {
            if (v.type === "range") {
              v.value = [
                v.value?.lower <= v.settings?.min ? null : v.value?.lower,
                v.value?.upper >= v.settings?.max ? null : v.value?.upper,
              ];
            }
            return {
              refUuid: v.uuid,
              value: v.value,
            };
          })
        : [];

    this.modalCtrl.dismiss(machineParams);
  }
}
