import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { ApolloQueryResult } from "@apollo/client";
import { assertIsDefined, declension, isDefined, superSimpleSearch } from "../utils";
import { firstValueFrom } from "rxjs";
import {
  EntryRequestFormSimpleAuthCreateObjectMutationGql,
  EntryRequestFormSimpleAuthCurrentUserQuery,
  EntryRequestFormSimpleAuthCurrentUserQueryGql,
  EntryRequestFormSimpleSearchCreateRequestMutationGql,
  EntryRequestFormSimpleSearchProvidersCountQueryGql,
} from "./entry-request-form-simple.gql-gen";
import { InputCustomEvent, SelectCustomEvent, TextareaCustomEvent } from "@ionic/angular";
import dayjs from "dayjs";
import {
  SearchAddressSelectEvent,
  SearchAddressWithMapModalComponent,
} from "../search-address-with-map-modal/search-address-with-map-modal.component";
import { CreateEntryInput, EntryWorkType } from "../../base-types.gql-gen";
import { MaskitoDirective } from "@maskito/angular";
import { MaskitoOptions } from "@maskito/core";
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from "@maskito/kit";
import {
  MachineTypeSelectionModalComponent,
  MachineTypeX,
  RcSelectEvent,
} from "../machine-type-selection-modal/machine-type-selection-modal.component";
import {
  MachineParamsAppSelectEvent,
  MachineParamsSelectionModalComponent,
  MachineParamValueDto,
} from "../machine-params-selection-modal/machine-params-selection-modal.component";
import {
  DatetimeWheelsModalComponent,
  RcDateTimeChangeEvent,
} from "../datetime-wheels-modal/datetime-wheels-modal.component";
import { HideOnKeyboardDirective } from "../hide-on-keyboard.directive";
import { PreviewAddressWithMapModalComponent } from "../preview-adress-with-map-modal/preview-address-with-map-modal.component";
import {
  AddressObjectGroupUIData,
  AddressObjectUIData,
  createAddressObjectGroups,
  PAYMENT_TYPES,
  PaymentTypeUIData,
  WorkUnitUIData,
} from "../entry-request-form/entry-request-form.utils";
import { EntryRequestFormDispatcherSearchProvidersCountQueryVariables } from "../entry-request-form-dispatcher/entry-request-form-dispatcher.gql-gen";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

type AddressUIData = {
  objectRef?: string;
  fullAddress: string;
  coords: number[];
  placeholder: string;
};

@Component({
  selector: "app-entry-request-form-simple",
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonInput,
    IonTextarea,
    IonModal,
    IonButton,
    IonFooter,
    IonText,
    IonSelect,
    IonSelectOption,
    SearchAddressWithMapModalComponent,
    IonItemDivider,
    MaskitoDirective,
    MachineTypeSelectionModalComponent,
    MachineParamsSelectionModalComponent,
    DatetimeWheelsModalComponent,
    IonNote,
    HideOnKeyboardDirective,
    PreviewAddressWithMapModalComponent,
    IonSpinner,
  ],
  templateUrl: "./entry-request-form-simple.component.html",
  styleUrl: "./entry-request-form-simple.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryRequestFormSimpleComponent implements OnInit {
  protected selectedMachineType?: MachineTypeX;
  protected isMachineTypeModalOpen = false;

  protected selectedMachineParams: MachineParamValueDto[] = [];
  protected selectedMachineParamsText = "";
  protected isMachineParamsPickerOpen = false;

  // todo: use the same type as in dispatcher
  private selectedOrganizationRef?: string;
  private selectedOrganizationId?: string;

  protected addressObjects: AddressUIData[] = [];
  protected selectedAddressInput?: AddressUIData;
  protected focusedAddressObjectInputIdx: number = -1;
  protected addressObjectsAll: AddressObjectUIData[] = [];
  protected addressObjectsSuggests: AddressObjectGroupUIData[] = [];
  protected isAddressObjectGroupsEmpty = true;
  protected isAddressModalOpen = false;
  protected previewAddressCoords: number[] = [];

  protected selectedDate = "";
  protected selectedDateFormatted = "Дата обсуждаема";
  protected isDateFlexible = true;
  protected isTimeFlexible = true;
  protected isDateTineModalOpen = false;

  protected workUnits: WorkUnitUIData[] = [];
  protected selectedWorkUnit?: EntryWorkType;
  protected selectedWorkValue = 1;

  protected price = "";
  protected comment = "";

  protected paymentTypes: PaymentTypeUIData[] = PAYMENT_TYPES;
  protected selectedPaymentType: PaymentTypeUIData = this.paymentTypes[0];
  protected providersCountTxt = "";

  protected searchCreateRequestPending = false;

  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });
  readonly maskPredicate = async (el: unknown) => (el as HTMLIonInputElement).getInputElement();

  constructor(
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    private authCurrentUserQueryGql: EntryRequestFormSimpleAuthCurrentUserQueryGql,
    private searchCreateRequestMutationGql: EntryRequestFormSimpleSearchCreateRequestMutationGql,
    private authCreateObjectMutationGql: EntryRequestFormSimpleAuthCreateObjectMutationGql,
    private searchProvidersCountQueryGql: EntryRequestFormSimpleSearchProvidersCountQueryGql,
  ) {}

  async ngOnInit() {
    this.isMachineTypeModalOpen = true;
    this.addOneMoreObject();

    const currentUserReq = firstValueFrom(this.authCurrentUserQueryGql.fetch());
    const [currentUserRes] = await Promise.all([currentUserReq]);

    const { authCurrentUser } = currentUserRes.data;
    assertIsDefined(authCurrentUser); // if machine types are not found by id - it is error
    this.initAddressObjects(authCurrentUser);

    for (const org of authCurrentUser.organizations) {
      if (isDefined(org)) {
        this.selectedOrganizationRef = org.ref;
        this.selectedOrganizationId = org.id;
        break;
      }
    }
    this.cdRef.detectChanges();
  }

  get isFormAddressValid() {
    let addrValid = this.addressObjects.length > 0;
    for (const addr of this.addressObjects) {
      addrValid &&= addr.coords.length > 0;
    }
    return addrValid;
  }

  get isFormValid(): boolean {
    const machineTypeValid = isDefined(this.selectedMachineType);
    return machineTypeValid && this.isFormAddressValid;
  }

  onMachineTypeInputClick(ev: Event) {
    ev.preventDefault();
    this.isMachineTypeModalOpen = true;
  }

  onMachineTypeModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isMachineTypeModalOpen = false;
    this.getProvidersCount();
  }

  onMachineTypeModalRcSelect(ev: RcSelectEvent) {
    ev.cause.preventDefault();
    this.selectMachineType(ev.data);
    this.isMachineTypeModalOpen = false;
  }

  onMachineTypeModalRcClose() {
    this.isMachineTypeModalOpen = false;
    if (!this.selectedMachineType) {
      this.location.back();
    }
  }

  onMachineParamsInputClick(ev: Event) {
    ev.preventDefault();
    this.isMachineParamsPickerOpen = true;
  }

  onMachineParamsModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isMachineParamsPickerOpen = false;
    this.getProvidersCount();
  }

  onMachineParamsModalRcClose() {
    this.isMachineParamsPickerOpen = false;
  }

  onMachineParamsRcSelect(ev: MachineParamsAppSelectEvent) {
    this.isMachineParamsPickerOpen = false;
    this.selectedMachineParams = ev.data;
    this.selectedMachineParamsText = ev.txtShort;
  }

  onAddressObjectInputFocus(ev: Event, idx: number) {
    ev.preventDefault();
    this.focusedAddressObjectInputIdx = idx;
    this.updateAddressObjectSuggests(this.addressObjectsAll);
  }

  onAddressObjectInputBlur(ev: Event) {
    ev.preventDefault();
    // add async to be able to handle click by suggest
    setTimeout(() => {
      this.focusedAddressObjectInputIdx = -1;
      this.cdRef.markForCheck();
    }, 0);
  }

  onAddressObjectInputInput(ev: InputCustomEvent) {
    ev.preventDefault();
    const { value } = ev.detail;
    const filteredSuggests: AddressObjectUIData[] = [];
    if (value) {
      for (const addrObs of this.addressObjectsAll) {
        if (superSimpleSearch(value, addrObs.fullAddress)) {
          filteredSuggests.push(addrObs);
        }
      }
    } else {
      for (const addrObs of this.addressObjectsAll) {
        filteredSuggests.push(addrObs);
      }
    }
    this.updateAddressObjectSuggests(filteredSuggests);
  }

  onAddAddressObjectBtnClick(ev: Event) {
    ev.preventDefault();
    this.addOneMoreObject();
  }

  onRemoveAddressIconClick(ev: Event, addressInput: AddressUIData) {
    ev.preventDefault();
    const idx = this.addressObjects.indexOf(addressInput);
    if (idx > -1) {
      this.addressObjects.splice(idx, 1);
      this.updateAddressPlaceholders();
    }
  }

  onNewAddressObjectItemClick(ev: Event, addressInput: AddressUIData) {
    ev.preventDefault();
    this.selectedAddressInput = addressInput;
    this.isAddressModalOpen = true;
  }

  onAddressObjectSuggestClick(
    ev: Event,
    addrObj: AddressObjectUIData,
    newAddressInput: AddressUIData,
  ) {
    ev.preventDefault();
    newAddressInput.fullAddress = addrObj.fullAddress;
    newAddressInput.coords = addrObj.coords;
    this.focusedAddressObjectInputIdx = -1;
    this.getProvidersCount();
  }

  onSearchAddressModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isAddressModalOpen = false;
    this.getProvidersCount();
  }

  onSearchAddressModalClose() {
    setTimeout(() => {
      //run in new macrotask, because stupid bug with changeDetection
      this.isAddressModalOpen = false;
      this.cdRef.markForCheck();
    });
  }

  onSearchAddressModalSelect(ev: SearchAddressSelectEvent) {
    if (this.selectedAddressInput) {
      this.selectedAddressInput.fullAddress = ev.fullAddress;
      this.selectedAddressInput.coords = ev.coords;
    }
    this.isAddressModalOpen = false;
  }

  onPreviewAddressIconClick(ev: Event, coords: number[]) {
    ev.preventDefault();
    this.previewAddressCoords = coords;
  }

  onPreviewAddressModalDismiss(ev: Event) {
    ev.preventDefault();
    this.previewAddressCoords = [];
  }

  onPreviewAddressModalCancel() {
    this.previewAddressCoords = [];
  }

  onDateTimeInputClick(ev: Event) {
    ev.preventDefault();
    this.isDateTineModalOpen = true;
  }

  onDateTimeModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isDateTineModalOpen = false;
  }

  onRcDateTimeChange(ev: RcDateTimeChangeEvent) {
    this.selectedDate = ev.datetime;
    if (ev.date && ev.time) {
      this.selectedDateFormatted = dayjs(ev.datetime).format("D MMMM YYYY, HH:mm");
      this.isDateFlexible = false;
      this.isTimeFlexible = false;
    } else if (ev.date) {
      this.selectedDateFormatted = dayjs(ev.datetime).format("D MMMM YYYY");
      this.isDateFlexible = false;
      this.isTimeFlexible = true;
    } else {
      this.selectedDateFormatted = "Дата обсуждаема";
      this.isDateFlexible = true;
      this.isTimeFlexible = true;
    }
    this.isDateTineModalOpen = false;
  }

  onWorkUnitSelectChange(ev: CustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.selectedWorkUnit = ev.detail.value;
    }
  }

  onWorkValueInputChange(ev: InputCustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.selectedWorkValue = parseFloat(ev.detail.value);
    }
  }

  onPaymentSelectChange(ev: SelectCustomEvent) {
    ev.preventDefault();
    for (const pt of this.paymentTypes) {
      if (pt.value === ev.detail.value) {
        this.selectedPaymentType = pt;
        break;
      }
    }
  }

  onPriceInputChange(ev: InputCustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.price = ev.detail.value;
    }
  }

  onCommentInputChange(ev: TextareaCustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.comment = ev.detail.value;
    }
  }

  async onCreateEntryBtnClick(ev: Event) {
    ev.preventDefault();
    if (
      !this.selectedMachineType ||
      !this.selectedWorkUnit ||
      !this.selectedWorkValue ||
      !this.selectedOrganizationId ||
      this.searchCreateRequestPending
    ) {
      return;
    }
    this.searchCreateRequestPending = true;
    this.cdRef.markForCheck();
    const searchRequestObjects: CreateEntryInput["objects"] = [];
    for (const a of this.addressObjects) {
      const res = await firstValueFrom(
        this.authCreateObjectMutationGql.mutate({
          fullAddress: a.fullAddress,
          coordinates: a.coords,
          onDuplicate: "returnExisting",
          ownerOrganizationId: this.selectedOrganizationId,
        }),
      );
      if (res.data?.authCreateObject.ref) {
        searchRequestObjects.push({ objectRef: res.data.authCreateObject.ref });
      }
    }

    let dateFormat: CreateEntryInput["dateType"] = "datetime";
    if (this.isTimeFlexible) {
      dateFormat = "date";
    }
    if (this.isDateFlexible) {
      dateFormat = "none";
    }

    const searchRequestData: CreateEntryInput = {
      machineTypeRef: this.selectedMachineType.ref,
      objects: searchRequestObjects,
      dateType: dateFormat,
      workType: this.selectedWorkUnit,
      paymentType: this.selectedPaymentType.value,
      workValue: this.selectedWorkValue,
      date: this.selectedDate,
      comment: this.comment,
      machineParams: this.selectedMachineParams,
      price: maskitoParseNumber(this.price),
    };
    // todo: handle errors
    const res = await firstValueFrom(
      this.searchCreateRequestMutationGql.mutate({
        data: searchRequestData,
      }),
    );

    if (res.data?.searchCreateRequest.id) {
      if (
        res.data?.searchCreateRequest.availableActions.includes("SEE_ENTRY_CATALOG_AND_RESPONSES")
      ) {
        const entryId = res.data?.searchCreateRequest.id;
        const queryParams = {
          entryId,
        };
        await this.router.navigate(["/entry-details-machine"], { queryParams, replaceUrl: true });
      } else {
        await this.router.navigate(["/home/entries"], { replaceUrl: true });
      }
    }
    this.searchCreateRequestPending = false;
    this.cdRef.markForCheck();
  }

  private selectMachineType(mt: RcSelectEvent["data"]) {
    this.selectedMachineType = mt;
    this.selectedMachineParams = [];
    this.selectedMachineParamsText = "";
    this.workUnits = [];
    for (const unit of this.selectedMachineType?.unit ?? []) {
      if (unit === "shift") {
        this.workUnits.push({ name: "shift", ruName: "Количество смен" });
      }
      if (unit === "trip") {
        this.workUnits.push({ name: "trip", ruName: "Количество рейсов" });
      }
      if (unit === "cbm") {
        this.workUnits.push({ name: "cbm", ruName: "Количество кубов" });
      }
    }
    this.selectedWorkUnit = this.workUnits[0].name;
  }

  private addOneMoreObject() {
    this.addressObjects.push({
      fullAddress: "",
      coords: [],
      placeholder: "",
    });
    this.updateAddressPlaceholders();
  }

  private updateAddressPlaceholders() {
    for (let i = 0; i < this.addressObjects.length; i += 1) {
      if (i === 0) {
        this.addressObjects[i].placeholder = "Введите адрес подачи";
      } else {
        this.addressObjects[i].placeholder = `Введите ${i + 1}-й адрес`;
      }
    }
  }

  private updateAddressObjectSuggests(suggests: AddressObjectUIData[]) {
    assertIsDefined(this.selectedOrganizationId);
    this.addressObjectsSuggests = createAddressObjectGroups(suggests, this.selectedOrganizationId);
    this.isAddressObjectGroupsEmpty = true;
    for (const g of this.addressObjectsSuggests) {
      if (g.address.length) {
        this.isAddressObjectGroupsEmpty = false;
        break;
      }
    }
  }

  private async getProvidersCount() {
    this.providersCountTxt = "";
    const coords = this.addressObjects[0]?.coords ?? [];
    if (!this.selectedMachineType || !coords.length) {
      return;
    }
    const machineTypeId: EntryRequestFormDispatcherSearchProvidersCountQueryVariables["machineTypeId"] =
      this.selectedMachineType.id;
    const machineParams: EntryRequestFormDispatcherSearchProvidersCountQueryVariables["machineParams"] =
      [];
    for (const param of this.selectedMachineParams) {
      const { paramName, boolValue, rangeValue, selectNames } = param;
      if (paramName) {
        machineParams.push({ paramName, boolValue, rangeValue, selectNames });
      }
    }
    const addressCoords: EntryRequestFormDispatcherSearchProvidersCountQueryVariables["addressCoords"] =
      coords;
    const res = await firstValueFrom(
      this.searchProvidersCountQueryGql.fetch({
        machineTypeId,
        addressCoords,
        machineParams,
      }),
    );
    const { searchProvidersCount } = res.data;
    if (isDefined(searchProvidersCount)) {
      this.providersCountTxt =
        searchProvidersCount +
        " " +
        declension(searchProvidersCount, "поставщик", "поставщика", "поставщиков");
    }
    this.cdRef.markForCheck();
  }

  private initAddressObjects(
    authCurrentUser: NonNullable<
      ApolloQueryResult<EntryRequestFormSimpleAuthCurrentUserQuery>["data"]["authCurrentUser"]
    >,
  ) {
    this.addressObjectsAll = [];
    for (const org of authCurrentUser.organizations) {
      for (const addrObj of org?.objects ?? []) {
        if (addrObj?.address?.fullAddress && addrObj?.address?.coordinates) {
          this.addressObjectsAll.push({
            coords: addrObj.address.coordinates.filter(isDefined),
            title: addrObj.title?.trim() ?? "",
            fullAddress: addrObj.address.fullAddress,
            ownerOrganization: {
              id: addrObj.organizationRef ?? "",
              name: addrObj.ownerName ?? "",
            },
          });
        }
      }
    }
  }
}
