import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { MachinePhotoSliderComponent } from "../machine-photo-slider/machine-photo-slider.component";
import { MachineViewingParamsComponent } from "../machine-viewing-params/machine-viewing-params.component";
import {
  CatalogMachineViewingMachineQuery,
  CatalogMachineViewingMachineQueryGql,
} from "./catalog-machine-viewing.gql-gen";
import { firstValueFrom } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { EnumMachineAvailabilityStatusStatus } from "../../base-types.gql-gen";
import dayjs from "dayjs";
import { MachineVerificationShelfComponent } from "../machine-verification-shelf/machine-verification-shelf.component";
import { MachineAvailabilityStatusShelfComponent } from "../machine-availability-status-shelf/machine-availability-status-shelf.component";
import { assertIsDefined } from "../utils";

type CatalogMachineViewingUI = CatalogMachineViewingMachineQuery["machine"] & {
  availabilityStatusFixed: {
    status: EnumMachineAvailabilityStatusStatus;
    statusAvailableStarts: string;
    statusBusyEnds: string;
  };
};

@Component({
  selector: "app-catalog-machine-viewing",
  standalone: true,
  imports: [
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    MachinePhotoSliderComponent,
    MachineViewingParamsComponent,
    IonLabel,
    IonList,
    MachineVerificationShelfComponent,
    MachineAvailabilityStatusShelfComponent,
  ],
  templateUrl: "./catalog-machine-viewing.component.html",
  styleUrl: "./catalog-machine-viewing.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogMachineViewingComponent implements OnInit {
  protected machine?: CatalogMachineViewingUI;

  constructor(
    private machineQueryGql: CatalogMachineViewingMachineQueryGql,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    const machineUuid = this.activatedRoute.snapshot.queryParams["machineUuid"] ?? "";
    const machineRes = await firstValueFrom(this.machineQueryGql.fetch({ machineUuid }));
    const { machine } = machineRes.data;
    assertIsDefined(machine);
    assertIsDefined(machine.availabilityStatus);
    const statusAvailableDate = machine.date?.available
      ? dayjs(machine.date?.available * 1000)
      : dayjs();
    const statusBusyDate = dayjs(machine.availabilityStatus.statusEnds);
    this.machine = {
      ...machine,
      availabilityStatusFixed: {
        status: machine.availabilityStatus.status ?? "unknown",
        statusAvailableStarts: statusAvailableDate.fromNow(),
        statusBusyEnds: statusBusyDate.fromNow(),
      },
    };
    this.cdRef.markForCheck();
  }
}
