<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Secret Settings</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-list-header>
      <ion-label>Backend URL</ion-label>
    </ion-list-header>
    <ion-radio-group [formControl]="urls.controls.backendUrl">
      @for (burl of backendURLs; track burl.value) {
        <ion-item>
          <ion-radio [value]="burl.value">{{ burl.label }}</ion-radio>
        </ion-item>
      }
    </ion-radio-group>
    <ion-list-header>
      <ion-label>Frontend URL</ion-label>
    </ion-list-header>
    <ion-radio-group [formControl]="urls.controls.frontendUrl">
      @for (furl of frontendURLs; track furl.value) {
        <ion-item>
          <ion-radio [value]="furl.value">{{ furl.label }}</ion-radio>
        </ion-item>
      }
    </ion-radio-group>
  </ion-list>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button
    [disabled]="urls.invalid || urls.pristine"
    (click)="onSaveBtnClick($event)"
    expand="block"
    size="large"
  >
    Сохранить
  </ion-button>
</ion-footer>
