import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AuthRegionListPublicQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthRegionListPublicQuery = { __typename?: 'Query', authRegionListPublic: Array<{ __typename?: 'RegionPublic', code: string, name: string, status: string }> };

export const AuthRegionListPublicDocument = gql`
    query authRegionListPublic {
  authRegionListPublic {
    code
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRegionListPublicQueryGql extends Apollo.Query<AuthRegionListPublicQuery, AuthRegionListPublicQueryVariables> {
    override document = AuthRegionListPublicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }