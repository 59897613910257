<ion-header>
  <ion-toolbar>
    <ion-title>Мой профиль</ion-title>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button id="delete-profile-alert" color="rcred">Удалить аккаунт</ion-button>
      <ion-alert
        trigger="delete-profile-alert"
        header="Удалить аккаунт?"
        message="Восстановить профиль после этого действия будет невозможно."
        [buttons]="deleteProfileButtons"
      ></ion-alert>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" [formGroup]="profileEditFormGroup">
  <div class="profilePic" id="profile-person-edit-get-picture">
    @if (profileEditFormGroup.value.avatarUrl) {
      <img [src]="profileEditFormGroup.value.avatarUrl" alt="" />
    } @else {
      <ion-icon class="profileIcon" name="camera-outline"></ion-icon>
    }
    <ion-action-sheet
      [buttons]="selectAvatarButtons"
      trigger="profile-person-edit-get-picture"
    ></ion-action-sheet>
  </div>
  <ion-list>
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'firstName'" label="Имя" labelPlacement="floating"></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input
        [formControlName]="'lastName'"
        label="Фамилия"
        labelPlacement="floating"
      ></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input
        [formControlName]="'phoneNumber'"
        [readonly]="true"
        label="Телефон"
        labelPlacement="floating"
      ></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'email'" label="Email" labelPlacement="floating"></ion-input>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    [disabled]="profileEditFormGroup.invalid || profileEditFormGroup.pristine"
    (click)="onSavePersonProfileClick($event)"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Сохранить
  </ion-button>
</ion-footer>
