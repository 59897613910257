<ion-header>
  <ion-toolbar>
    @if (kind !== "unauthorized") {
      <ion-buttons slot="start">
        <ion-back-button [defaultHref]="'/home/support'"></ion-back-button>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-padding error-content">
    @if (kind === "unauthorized") {
      <ion-icon color="rcyellow" class="error-icon" name="warning-outline"></ion-icon>
      <h1 class="ion-no-margin">Ваша сессия истекла</h1>
      <ion-text>Пожалуйста, повторите вход</ion-text>
      @if (traceId) {
        <ion-text>
          Код ошибки:
          <span class="error-id">
            {{ traceId }}
          </span>
        </ion-text>
      }
    } @else {
      <ion-icon color="rcred" class="error-icon" name="warning-outline"></ion-icon>
      <h1 class="ion-no-margin">Что-то сломалось :(</h1>
      @if (traceId) {
        <ion-text>
          Код ошибки:
          <span class="error-id">{{ traceId }}</span>
        </ion-text>
        <ion-text>Пожалуйста, сообщите этот код технической поддержке</ion-text>
      }
    }
  </div>
</ion-content>
@if (kind === "unauthorized") {
  <ion-footer class="ion-no-border">
    <ion-button
      [routerLink]="'/sign-in'"
      [replaceUrl]="true"
      class="ion-padding"
      color="rcyellow"
      expand="block"
      size="large"
    >
      Войти
    </ion-button>
  </ion-footer>
}
