import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Entry, EntryProposes, Maybe } from "../../../base-types.gql-gen";
import { Api } from "../../apollo/api";
import { IonBackdrop, IonButton, IonProgressBar } from "@ionic/angular/standalone";

@Component({
  selector: "app-cancel-modal",
  templateUrl: "cancel-modal.component.html",
  styleUrls: ["./cancel-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonButton, IonProgressBar, IonBackdrop],
})
export class CancelModalComponent implements OnChanges {
  @Input() proposeForSign?: Maybe<EntryProposes>;
  @Input() entry!: Entry;
  @Input() hasPropose = false;
  @Output() closeAndRefresh = new EventEmitter();
  showModal = false;
  loading = false;

  maybeMobappV1Window = window.parent;
  mobappV1Origin = "*";

  constructor(private api: Api) {}

  ngOnChanges(changes: SimpleChanges) {
    this.showModal = !!changes?.["proposeForSign"]?.currentValue;
  }

  closeModal() {
    this.showModal = false;
    this.closeAndRefresh.emit(false);
  }

  async cancelSign() {
    const proposeForCancel = this.entry.proposes?.find((p) => p?.initiator === "customer");
    this.loading = true;
    await this.api.cancelPropose(proposeForCancel?.ref || "");
    this.showModal = false;
    this.loading = false;
    this.closeAndRefresh.emit(true);
    const msg: any = { from: "mobappV2", cmd: "signPropose" };
    this.hasPropose
      ? (msg.proposeId = this.proposeForSign?.uuid)
      : (msg.supplier = this.proposeForSign);
    this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
  }
}
