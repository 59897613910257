<ion-header>
  <ion-toolbar>
    <ion-title>Техника</ion-title>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/home/profile?segment=machines'"></ion-back-button>
    </ion-buttons>
    @if (machine) {
      <ion-buttons slot="end">
        <ion-button
          [routerLink]="'/profile-machine-form'"
          [queryParams]="{ machineId: machine.id }"
        >
          Изменить
        </ion-button>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>
<ion-content>
  @if (machine) {
    <div class="ownership-status-bar ion-padding">
      <app-machine-verification-shelf [machine]="machine"></app-machine-verification-shelf>
    </div>
    <app-machine-photo-slider [machine]="machine"></app-machine-photo-slider>
    <ion-list class="ion-no-padding">
      <app-machine-availability-status-shelf
        [machine]="machine"
      ></app-machine-availability-status-shelf>
    </ion-list>
    <div class="ion-padding action-buttons">
      @if (machine.verificationStatus === "none" || machine.verificationStatus === "declined") {
        <ion-button
          [routerLink]="'/profile-machine-ownership'"
          [queryParams]="{ machineId: machine.id }"
          class="ion-margin-bottom rcbutton verification-btn"
          expand="block"
          size="large"
          color="rcyellow"
        >
          Подтвердить владение
        </ion-button>
      }
      <div class="availability-buttons">
        <ion-button
          id="profile-machine-viewing-set-status-free-alert"
          class="rcbutton availability-btn"
          size="large"
          color="rcgreen"
        >
          Свободна
        </ion-button>
        <ion-alert
          [buttons]="setStatusFreeAlertButtons"
          trigger="profile-machine-viewing-set-status-free-alert"
          header='Статус "Свободна"'
          subHeader='Устанавливая статус "Свободна", вы поднимаете свою технику в каталоге и получаете новые заявки на нее в первую очередь. Статус действует 3 дня'
          message='Чем свежее статус, тем выше ваша позиция в каталоге. Вы можете как обновить статус в любой момент, так и отменить его, установив статус "Занята"'
        ></ion-alert>
        <ion-button
          id="profile-machine-viewing-set-status-busy-alert"
          class="ion-margin-start rcbutton availability-btn"
          size="large"
          color="rcred"
        >
          Занята
        </ion-button>
        <ion-alert
          [inputs]="setStatusBusyAlertInputs"
          [buttons]="setStatusBusyAlertButtons"
          trigger="profile-machine-viewing-set-status-busy-alert"
          header='Статус "Занята"'
          subHeader="Устанавливая статус “Занята”, вы скрываете технику из показа в каталоге и не будете получать на нее заявки"
          message="Вы можете установить статус на:"
        ></ion-alert>
      </div>
    </div>
    <div class="ion-padding-horizontal">
      <ion-label>
        <h1>{{ machine.typeData.nameRU }}</h1>
      </ion-label>
    </div>
    <app-machine-viewing-params [machine]="machine"></app-machine-viewing-params>
  }
</ion-content>
