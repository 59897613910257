<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Ваш регион</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list class="ion-padding">
    @for (item of regions; track item.code) {
      @if (item.status === "active") {
        <ion-item class="ion-no-padding" [routerLink]="'/registration-profile'">
          <ion-label class="itemList">
            <span>{{ item.name }}</span>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </ion-label>
        </ion-item>
      } @else {
        <ion-item
          class="ion-no-padding"
          [routerLink]="'/registration-unavailable-region'"
          [queryParams]="{ regionCode: item.code }"
        >
          <ion-label class="itemList">
            <span>{{ item.name }}</span>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </ion-label>
        </ion-item>
      }
    }
  </ion-list>
</ion-content>
