import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProposeSupplierFormSearchSupplierProposeDataQueryVariables = Types.Exact<{
  entryId: Types.Scalars['String']['input'];
}>;


export type ProposeSupplierFormSearchSupplierProposeDataQuery = { __typename?: 'Query', searchSupplierProposeData: { __typename?: 'SearchSupplierProposeData', sutableMachines: Array<{ __typename?: 'SearchSupplierProposeDataSutableMachine', machineId: string, machineName: string }>, propose?: { __typename?: 'SearchSupplierProposeDataPropose', machineName: string, machineId: string, paymentType: Types.SearchSendProposeFromSupplierPaymentType, comment?: string | null, price: number, proposeId: string } | null } };

export type ProposeSupplierFormSearchSendProposeFromSupplierMutationVariables = Types.Exact<{
  dto: Types.SearchSendProposeFromSupplierInput;
}>;


export type ProposeSupplierFormSearchSendProposeFromSupplierMutation = { __typename?: 'Mutation', searchSendProposeFromSupplier: { __typename?: 'OkResult', ok?: boolean | null } };

export type ProposeSupplierFormSearchEditProposeMutationVariables = Types.Exact<{
  dto: Types.SearchEditProposeData;
}>;


export type ProposeSupplierFormSearchEditProposeMutation = { __typename?: 'Mutation', searchEditPropose: { __typename?: 'OkResult', ok?: boolean | null } };

export const ProposeSupplierFormSearchSupplierProposeDataDocument = gql`
    query ProposeSupplierFormSearchSupplierProposeData($entryId: String!) {
  searchSupplierProposeData(entryId: $entryId) {
    sutableMachines {
      machineId
      machineName
    }
    propose {
      machineName
      machineId
      paymentType
      comment
      price
      proposeId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProposeSupplierFormSearchSupplierProposeDataQueryGql extends Apollo.Query<ProposeSupplierFormSearchSupplierProposeDataQuery, ProposeSupplierFormSearchSupplierProposeDataQueryVariables> {
    override document = ProposeSupplierFormSearchSupplierProposeDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProposeSupplierFormSearchSendProposeFromSupplierDocument = gql`
    mutation ProposeSupplierFormSearchSendProposeFromSupplier($dto: SearchSendProposeFromSupplierInput!) {
  searchSendProposeFromSupplier(input: $dto) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProposeSupplierFormSearchSendProposeFromSupplierMutationGql extends Apollo.Mutation<ProposeSupplierFormSearchSendProposeFromSupplierMutation, ProposeSupplierFormSearchSendProposeFromSupplierMutationVariables> {
    override document = ProposeSupplierFormSearchSendProposeFromSupplierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProposeSupplierFormSearchEditProposeDocument = gql`
    mutation ProposeSupplierFormSearchEditPropose($dto: SearchEditProposeData!) {
  searchEditPropose(data: $dto) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProposeSupplierFormSearchEditProposeMutationGql extends Apollo.Mutation<ProposeSupplierFormSearchEditProposeMutation, ProposeSupplierFormSearchEditProposeMutationVariables> {
    override document = ProposeSupplierFormSearchEditProposeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }