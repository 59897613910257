<ion-tabs>
  <ion-tab-bar slot="bottom" appHideOnKeyboard>
    <ion-tab-button tab="entries">
      <ion-icon name="search-outline"></ion-icon>
      <ion-label>Заказать</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="matches">
      <ion-icon name="briefcase-outline"></ion-icon>
      <ion-label>Сдать</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="profile">
      <ion-icon name="person-outline"></ion-icon>
      <ion-label>Профиль</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="partners" disabled>
      <ion-icon name="people-outline"></ion-icon>
      <ion-label>Партнеры</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="support">
      <ion-icon name="help-buoy-outline"></ion-icon>
      <ion-label>Помощь</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
