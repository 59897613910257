<ion-header>
  <ion-toolbar>
    <ion-title color="dark">Профиль</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-segment [value]="selectedSegment" (ionChange)="onSegmentChange($event)">
      <ion-segment-button value="info">
        <ion-label>Компания</ion-label>
      </ion-segment-button>
      <ion-segment-button value="machines">
        <ion-label>Техника</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content>
  @if (!myUserData) {
    <div class="spinner-wrapper">
      <ion-spinner name="lines-sharp"></ion-spinner>
    </div>
  } @else if (selectedSegment === "info") {
    <div class="ion-padding">
      <ion-list>
        @if (companyData) {
          <ion-item lines="none" class="ion-no-padding section-header">
            <div class="section-titles">
              <h2 class="ion-no-margin">
                <ion-text>{{ companyData.name }}</ion-text>
                @if (companyData.status === "approved") {
                  <ion-icon name="checkmark-circle" color="rcgreen"></ion-icon>
                }
              </h2>
              <ion-note>{{ companyData.dateFromNow }} на Rental Club</ion-note>
            </div>
            <div class="section-thumbnail">
              @if (companyData.organizationLogo) {
                <ion-avatar slot="end">
                  <img alt="Organisation picture" [src]="companyData.organizationLogo" />
                </ion-avatar>
              } @else {
                <ion-avatar class="avatar-with-icon" slot="end">
                  <ion-icon name="business-outline"></ion-icon>
                </ion-avatar>
              }
            </div>
          </ion-item>
          <ion-item class="ion-no-padding">
            <ion-label>ИНН</ion-label>
            <ion-text class="bold" slot="end">{{ companyData.requisite2.inn }}</ion-text>
          </ion-item>
          <ion-item class="ion-no-padding">
            <ion-label>Телефон</ion-label>
            <ion-text class="bold" slot="end">
              {{ companyData.publicPhone | maskito: phoneNumberMask }}
            </ion-text>
          </ion-item>
        }
        @if (myUserData) {
          <ion-item lines="none"></ion-item>
          <ion-item lines="none" class="ion-no-padding section-header">
            <div class="section-titles">
              <h2 class="ion-no-margin">
                <ion-text>{{ myUserData.firstname }} {{ myUserData.lastname }}</ion-text>
              </h2>
              <a [routerLink]="'/profile-person-edit'" class="text-with-icon">
                <ion-icon name="pencil"></ion-icon>
                <ion-text>Редактировать</ion-text>
              </a>
            </div>
            <div class="section-thumbnail">
              @if (myUserData.avatar) {
                <ion-avatar slot="end">
                  <img alt="Beautiful user's photo" [src]="myUserData.avatar" />
                </ion-avatar>
              } @else {
                <ion-avatar class="avatar-with-icon" slot="end">
                  <ion-icon name="person-outline"></ion-icon>
                </ion-avatar>
              }
            </div>
          </ion-item>
          <ion-item [routerLink]="'/profile-notification-settings'" class="ion-no-padding">
            Управление уведомлениями
          </ion-item>
          <ion-item class="ion-no-padding" id="home-profile-logout-btn">Выйти</ion-item>
          <ion-alert
            trigger="home-profile-logout-btn"
            header="Вы точно хотите выйти?"
            [buttons]="logoutAlertBtns"
          ></ion-alert>
        }
        <ion-item lines="none"></ion-item>
        <ion-item lines="none" class="ion-no-padding">
          <h2 class="ion-no-margin">База техники</h2>
        </ion-item>
        <ion-item class="ion-no-padding" (click)="onMachineBaseClick($event)">
          <ion-label class="address">Адрес</ion-label>
          @if (machineBaseData) {
            <ion-text class="bold word-break" slot="end">
              {{ machineBaseData.fullAddress }}
            </ion-text>
          } @else {
            <ion-button
              class="ion-margin-vertical"
              shape="round"
              size="medium"
              color="rcyellow"
              slot="end"
            >
              <ion-icon slot="icon-only" name="add-outline"></ion-icon>
            </ion-button>
          }
        </ion-item>

        <ion-item lines="none"></ion-item>
        <ion-item lines="none" class="ion-no-padding">
          <h2 class="ion-no-margin">Мои коллеги</h2>
        </ion-item>
        @for (u of otherUsersData; track u.id) {
          <ion-item class="ion-no-padding">
            @if (u.avatar) {
              <ion-avatar slot="start">
                <img alt="Beautiful user's photo" [src]="u.avatar" />
              </ion-avatar>
            } @else {
              <ion-avatar class="avatar-with-icon" slot="start">
                <ion-icon name="person-outline"></ion-icon>
              </ion-avatar>
            }
            <ion-label>
              {{ u.firstname }}
              <br />
              {{ u.lastname }}
            </ion-label>
            <ion-button
              (click)="onBtnCallClick($event, u)"
              shape="round"
              slot="end"
              size="medium"
              color="rcyellow"
              class="ion-margin-vertical"
            >
              <ion-icon slot="icon-only" name="call"></ion-icon>
            </ion-button>
          </ion-item>
        }
      </ion-list>
    </div>
  } @else if (selectedSegment === "machines") {
    <ion-button
      (click)="onAddMachineClick($event)"
      class="ion-padding"
      color="rcyellow"
      expand="block"
      size="large"
    >
      Добавить технику
    </ion-button>
    @for (m of renderedMachinesData; track m.id; let idx = $index) {
      @if (m.typeData.nameRU !== renderedMachinesData[idx - 1]?.typeData?.nameRU) {
        <h2 class="ion-padding-horizontal">{{ m.typeData.nameRU }}</h2>
      }
      <ion-card
        [routerLink]="'/profile-machine-viewing'"
        [queryParams]="{ machineUuid: m.uuid }"
        class="machine-card"
      >
        <div class="machine-header ion-padding">
          <div class="machine-thumbnail">
            <ion-thumbnail>
              @if (m.firstPhoto) {
                <ion-img alt="Machine's picture" [src]="m.firstPhoto" />
              } @else {
                <ion-img alt="Default machine's picture" src="assets/icon/machine.png" />
              }
            </ion-thumbnail>
          </div>
          <div class="ion-margin-start machine-titles">
            <h3 class="ion-no-margin text-with-icon">
              <ion-text color="dark" class="text-ellipsis">
                <strong>{{ m.model }}</strong>
              </ion-text>
              @if (m.verificationStatus === "approved") {
                <ion-icon name="checkmark-circle" color="rcgreen"></ion-icon>
              }
              @if (m.verificationStatus === "pending") {
                <ion-icon name="warning" color="rcyellow"></ion-icon>
              }
              @if (m.verificationStatus === "declined" || m.verificationStatus === "none") {
                <ion-icon name="warning" color="rcred"></ion-icon>
              }
            </h3>
            <ion-text color="dark" class="text-ellipsis">{{ m.year }} г.&nbsp;</ion-text>
            <ion-text color="dark" class="text-ellipsis">{{ m.reg }}&nbsp;</ion-text>
          </div>
        </div>
        <app-machine-availability-status-shelf
          [machine]="m"
        ></app-machine-availability-status-shelf>
      </ion-card>
    }
  }
  <ion-modal
    [isOpen]="isAddressModalOpen"
    (ionModalDidDismiss)="onSearchAddressModalDismiss($event)"
  >
    <ng-template>
      <app-search-address-with-map-modal
        [initAddress]="machineBaseData"
        (appClose)="onSearchAddressModalClose()"
        (addressSelect)="onSearchAddressModalSelect($event)"
      ></app-search-address-with-map-modal>
    </ng-template>
  </ion-modal>
</ion-content>
