import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { IonSpinner, IonToolbar } from "@ionic/angular/standalone";
import { YaCoreModule } from "../../yamapng/src/lib/core.module";
import { AddressPickFieldComponent } from "../address-pick-field/address-pick-field";
import { FormsModule } from "@angular/forms";

const defaultAddress = {
  full: "",
  lat: 55.75222,
  lng: 37.61556,
};

@Component({
  selector: "app-address-pick",
  templateUrl: "address-pick.html",
  styleUrls: ["./address-pick.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonToolbar, IonSpinner, YaCoreModule, AddressPickFieldComponent, FormsModule],
})
export class AddressPickComponent implements OnChanges, OnInit {
  @Output() entryAddressChanges = new EventEmitter();
  @Input() addressOrig?: any;
  @Input() previousAddresses: any[] = [];

  public address = defaultAddress;
  public searchbarDisabled = false;
  private mapTimer: any = null;
  private mapCoords: any = {
    lat: null,
    lng: null,
  };
  componentIsActive = true;
  zoom = 14;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.componentIsActive = true;
      this.cdRef.markForCheck();
    }, 400);
  }

  ngOnChanges() {
    if (this.addressOrig) {
      if (this.addressOrig.full !== this.address.full) {
        this.address = this.addressOrig;
      }
    } else {
      this.address = defaultAddress;
    }
  }

  private getAddressFromCoords(lat: number, lng: number) {
    ymaps.geocode([lat, lng]).then((results: any) => {
      this.cdRef.markForCheck();
      if (results.metaData.geocoder.found < 1) {
        this.address.full = "Адрес не найден";
        this.address.lat = lat;
        this.address.lng = lng;
        this.searchbarDisabled = false;
        this.entryAddressChanges.emit(this.address);
        return;
      }

      if (
        this.mapCoords.lat &&
        this.mapCoords.lng &&
        this.mapCoords.lat !== lat &&
        this.mapCoords.lng !== lng
      ) {
        return;
      }

      this.mapCoords = {
        lat: null,
        lng: null,
      };

      const geoObjectProps = results.geoObjects.get(0).properties.getAll();
      this.address = {
        full: `${geoObjectProps.name}, ${geoObjectProps.description}`,
        lat,
        lng,
      };
      this.searchbarDisabled = false;
      this.entryAddressChanges.emit(this.address);
      this.cdRef.detectChanges();
    });
  }

  mapChangeCenter(mapCenterCoord: any) {
    if (this.mapTimer) {
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }

    if (
      this.address?.lat?.toFixed(4) === mapCenterCoord.lat.toFixed(4) &&
      this.address?.lng?.toFixed(4) === mapCenterCoord.lng.toFixed(4)
    ) {
      return false;
    }

    this.searchbarDisabled = true;
    this.mapCoords = {
      lat: mapCenterCoord.lat,
      lng: mapCenterCoord.lng,
    };

    this.mapTimer = setInterval(() => {
      this.getAddressFromCoords(mapCenterCoord.lat, mapCenterCoord.lng);
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }, 400);
    return;
  }

  setNewAddress(newAddress: any) {
    this.address = newAddress;
    this.entryAddressChanges.emit(this.address);
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }
}
