import { Injectable } from "@angular/core";
import { RegistrationInput } from "../../base-types.gql-gen";

@Injectable({
  providedIn: "root",
})
export class RegistrationDataService {
  phoneNumber = "";
  confirmationCode = "";
  firstname = "";
  lastname = "";
  email = "";
  avatar = "";
  profileType = "";
  inn = "";
  ogrn = "";

  constructor() {}

  asPersonOnly(): RegistrationInput {
    const { phoneNumber, confirmationCode, firstname, lastname, email, avatar } = this;
    return {
      phoneNumber,
      confirmationCode,
      firstname,
      lastname,
      email,
      avatar,
      profileType: "person",
    };
  }

  asPersonWithCompany(): RegistrationInput {
    const { phoneNumber, confirmationCode, firstname, lastname, email, avatar, inn, ogrn } = this;
    return {
      phoneNumber,
      confirmationCode,
      firstname,
      lastname,
      email,
      avatar,
      inn,
      ogrn,
      profileType: "company",
    };
  }
}
