import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { callPhoneNumber, machineMap } from "../../utils";
import { EntryProposes, Machine } from "../../../base-types.gql-gen";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonIcon,
  ModalController,
} from "@ionic/angular/standalone";
import { CompanyInfoComponent } from "../company-info/company-info.component";
import dayjs from "dayjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-provider-card",
  templateUrl: "provider-card.component.html",
  styleUrls: ["./provider-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonButtons, IonButton, IonIcon, IonAvatar],
})
export class ProviderCardComponent implements OnInit {
  @Input() entry: any;
  @Input() autoSearchResult: any;
  @Output() showCancelModal = new EventEmitter();
  maybeMobappV1Window = window.parent;
  mobappV1Origin = "*";
  showAllMachines = false;
  partnerMachines: any[] = [];
  company: any;
  user: any;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {
    this.company = this.autoSearchResult.company;
    this.user = this.autoSearchResult.organization.users[0]?.user;
    this.partnerMachines = this.autoSearchResult.machines.map((m: Machine) => machineMap(m));
  }

  get distanceToMachineBase(): number {
    return (
      this.autoSearchResult.searchInfo[0].distance &&
      this.autoSearchResult.searchInfo[0].distance.toFixed(1)
    );
  }

  getStatusText(machine: Machine): string {
    if (machine.availabilityStatus?.status === "available" && machine.date?.available) {
      return "свободна, " + dayjs.unix(machine.date?.available).fromNow();
    }
    return "";
  }

  trackByForMachines(_index: any, item: any) {
    return item.uuid;
  }

  async showCompany() {
    const modal = await this.modalCtrl.create({
      component: CompanyInfoComponent,
      componentProps: {
        org: this.autoSearchResult?.organization,
        companyId: this.company?.uuid,
      },
    });
    await modal.present();
  }

  async showMachine(machine: any) {
    await this.router.navigate(["catalog-machine-viewing"], {
      queryParams: { machineUuid: machine.uuid },
    });
  }

  get moreMachinesText(): string {
    const machinesCount = this.partnerMachines.length - 1;
    return `${machinesCount}`;
  }

  async sign() {
    const signedPropose = this.entry.proposes?.find((p: EntryProposes) => p.customerSignature);
    if (signedPropose) this.showCancelModal.emit(this.autoSearchResult);
    else {
      await this.router.navigate(["/entry-sign"], {
        queryParams: {
          entryId: this.entry.uuid,
          supplierId: this.autoSearchResult?.organization?.uuid,
          viewAs: "customer",
        },
      });
    }
  }

  callToProvider() {
    const phone = this.user.phone;
    const phoneNumber = `+${phone}`;
    callPhoneNumber(phoneNumber);
  }
}
