import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs } from "@ionic/angular/standalone";
import { ChaportLoaderService } from "./support-tab/chaport-loader.service";
import { HideOnKeyboardDirective } from "../hide-on-keyboard.directive";

@Component({
  selector: "app-tabs",
  templateUrl: "home-page.component.html",
  styleUrls: ["home-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, HideOnKeyboardDirective],
  providers: [ChaportLoaderService],
})
export class HomePageComponent implements OnInit {
  constructor(private chaportLoader: ChaportLoaderService) {}

  ngOnInit() {
    this.chaportLoader.init().then(() => {
      /* preload chaport */
    });
  }
}
