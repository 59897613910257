import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonToggle,
} from "@ionic/angular/standalone";
import { MachineParamsValues } from "../entry-params/entry-params.component";
import { FormsModule } from "@angular/forms";
import { CheckboxCustomEvent } from "@ionic/angular";

@Component({
  selector: "app-entry-params-list",
  templateUrl: "entry-params-list.component.html",
  styleUrls: ["./entry-params-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonList, IonItem, IonLabel, IonRange, IonToggle, IonCheckbox, FormsModule],
})
export class EntryParamsListComponent implements OnChanges {
  @Input() includeExtended = false;
  @Input() params: MachineParamsValues[] = [];

  protected filteredParams: MachineParamsValues[] = [];

  ngOnChanges(_changes: SimpleChanges): void {
    this.filteredParams = [];
    for (const p of this.params) {
      if (p.paramPurpose?.entry) {
        if (p.isEquipment && this.includeExtended) {
          this.filteredParams.push(p);
        }
        if (!p.isEquipment && !this.includeExtended) {
          this.filteredParams.push(p);
        }
      }
    }
  }

  validateClass(param: MachineParamsValues): boolean {
    if (param.type === "select" && param.settings?.min && param.settings?.max) {
      if (
        param.value === undefined ||
        (param.value.from && /\D/g.test(param.value.from)) ||
        (param.value.to && /\D/g.test(param.value.to)) ||
        param.value.to > param.settings.max ||
        param.value.from < param.settings.min
      ) {
        return true;
      }
    }
    return false;
  }

  updateRange($event: any, param: MachineParamsValues) {
    const min = $event.detail.value.lower;
    const max = $event.detail.value.upper;
    if (!!min && !!max) {
      param.value.from = min;
      param.value.to = max;
    }
  }

  isCheckedParamOption(param: MachineParamsValues, option: any) {
    return param.value?.find((pv: any) => pv === option.value);
  }

  toggleParamOption($event: CheckboxCustomEvent, param: MachineParamsValues, option: any) {
    if (!param.value) {
      param.value = [];
    }
    if ($event.target.checked) {
      param.value.push(option.value);
    } else {
      param.value = param.value?.filter((v: any) => v !== option.value);
    }
  }

  paramsSelectOptions(param: MachineParamsValues) {
    return param.settings ? param.settings.select : [];
  }
}
