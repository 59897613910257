<ion-header>
  <ion-toolbar>
    <ion-title>Просмотр техники</ion-title>
    <ion-buttons slot="start">
      <ion-button>
        <ion-back-button></ion-back-button>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  @if (machine) {
    <div class="ion-padding header">
      <ion-label>{{ machine.typeData.nameRU }}</ion-label>
    </div>
    <div class="ownership-status-bar">
      @if (machine.verificationStatus === "approved") {
        <app-machine-verification-shelf [machine]="machine"></app-machine-verification-shelf>
      }
    </div>
    <app-machine-photo-slider [machine]="machine"></app-machine-photo-slider>
    <ion-list class="ion-no-padding">
      <app-machine-availability-status-shelf
        [machine]="machine"
      ></app-machine-availability-status-shelf>
    </ion-list>
    <app-machine-viewing-params [machine]="machine"></app-machine-viewing-params>
  }
</ion-content>
