import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import {
  AlertController,
  IonActionSheet,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonNote,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { ProfileMachineOwnershipMachineQueryGql } from "./profile-machine-ownership.gql-gen";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ActionSheetButton } from "@ionic/angular";
import { Api } from "../apollo/api";
import { OwnershipRequestMachineDto } from "../apollo/api.interfaces";

type Machine = {
  id: string;
  model: string;
  year: string;
  reg: string;
  description: string;
  pricePerMinOrder?: number;
  pricePerWorkShift?: number;
  uuid: string;
  organizationUuid: string;
};

@Component({
  selector: "app-profile-machine-ownership",
  standalone: true,
  imports: [
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonContent,
    IonText,
    IonFooter,
    IonCard,
    IonCardContent,
    IonThumbnail,
    IonItem,
    FormsModule,
    IonInput,
    ReactiveFormsModule,
    IonList,
    IonNote,
    IonBackButton,
    IonActionSheet,
    IonImg,
  ],
  templateUrl: "./profile-machine-ownership.component.html",
  styleUrl: "./profile-machine-ownership.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMachineOwnershipComponent implements OnInit {
  protected firstLayer = true;
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin
  protected machine?: Machine;
  protected reg = new FormControl("", [Validators.required]);
  protected model = new FormControl("", [Validators.required]);
  protected addNewPhotoButtons: ActionSheetButton[] = [];
  private cardIdx = -1;
  protected checkRequirement = false;
  protected machineOwnershipCards = [
    {
      photoSrc: "",
      title: "Фото для каталога",
      description: "",
      required: true,
    },
    {
      photoSrc: "",
      title: "Фото гос. номера",
      description: "Фото техники с читаемым гос. номером или VIN",
      required: true,
    },
    {
      photoSrc: "",
      title: "Документ на технику",
      description:
        "ПТС, ПСМ или свидетельство о регистрации, где указан собственник и данные на технику",
      required: true,
    },
  ];

  constructor(
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private ownershipMachineQueryGql: ProfileMachineOwnershipMachineQueryGql,
    private location: Location,
    private api: Api,
    private alertController: AlertController,
  ) {}

  async ngOnInit(): Promise<void> {
    const machineId = this.activatedRoute.snapshot.queryParams["machineId"] ?? "";
    const machineRes = await firstValueFrom(this.ownershipMachineQueryGql.fetch({ machineId }));
    const { machine } = machineRes.data;

    if (!machine || !machine.organization) {
      return;
    }
    this.machine = {
      id: machine.id,
      uuid: machine.uuid,
      organizationUuid: machine.organization.uuid,
      year: `${machine.year ?? ""}`,
      model: machine.model,
      reg: `${machine.reg ?? ""}`,
      description: machine.description ?? "",
      pricePerWorkShift: machine.pricePerWorkShift ?? undefined,
      pricePerMinOrder: machine.pricePerMinOrder ?? undefined,
    };
    if (machine.photos[0]?.src) {
      this.machineOwnershipCards[0].photoSrc = machine.photos[0].src;
    }
    this.reg.patchValue(machine.reg ?? "");
    this.model.patchValue(machine.model);
    this.cdRef.markForCheck();
  }

  async onBackButtonClick(ev: MouseEvent) {
    ev.preventDefault();
    this.location.back();
  }

  onChangeLayerClick(ev: Event) {
    ev.preventDefault();
    this.firstLayer = false;
  }

  onImgCardClick(ev: Event, idx: number) {
    ev.preventDefault();
    this.addNewPhotoButtons.push(
      {
        text: "C камеры",
        handler: () => {
          const msg = { from: "mobappV2", cmd: "getPictureFromCamera" };
          this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
        },
      },
      {
        text: "Из галереи",
        handler: () => {
          const msg = { from: "mobappV2", cmd: "getPictureFromGallery" };
          this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
        },
      },
    );
    const card = this.machineOwnershipCards[idx];
    if (card?.photoSrc) {
      this.addNewPhotoButtons.push({
        text: "Удалить фото",
        role: "destructive",
        handler: () => {
          card.photoSrc = "";
          this.syncCheckRequirement();
        },
      });
    }
    this.cardIdx = idx;
  }

  onNewDocumentClick(ev: Event) {
    ev.preventDefault();
    this.machineOwnershipCards.push({
      photoSrc: "",
      title: "Дополнительное фото",
      description: "",
      required: false,
    });
  }

  onPhotoActionSheetDismiss(ev: CustomEvent) {
    ev.preventDefault();
    this.addNewPhotoButtons = [];
  }

  @HostListener("window:message", ["$event"])
  async onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd === "getPictureResult") {
      const card = this.machineOwnershipCards[this.cardIdx];
      if (card) {
        card.photoSrc = data.dataUrl;
        this.syncCheckRequirement();
      }
    }
  }

  syncCheckRequirement() {
    this.checkRequirement = true;
    for (const card of this.machineOwnershipCards) {
      if (card.required && !card.photoSrc) {
        this.checkRequirement = false;
      }
    }
  }

  async onVerificationButtonClick(ev: Event) {
    ev.preventDefault();
    if (!this.machine) {
      return;
    }
    const photoUrls: string[] = [];
    for (const card of this.machineOwnershipCards) {
      let photoUrl = "";
      if (card.photoSrc.startsWith("data:")) {
        const [dataType] = card.photoSrc.split(";");
        const [, mimeType] = dataType.split(":");
        const [, fileType] = mimeType.split("/");
        const res = await fetch(card.photoSrc);
        const blob = await res.blob();
        const file = new File([blob], `machinePhoto.${fileType}`, { type: mimeType });
        const fileRes = await this.api.saveMachinePhoto(file, this.machine.organizationUuid);
        photoUrl = fileRes.url;
      } else if (card.photoSrc.startsWith("http")) {
        photoUrl = card.photoSrc;
      }
      if (photoUrl) {
        photoUrls.push(photoUrl);
      }
    }
    const ownershipRequest: OwnershipRequestMachineDto = {
      machineModel: this.model.value ?? "",
      machineRegNumber: this.reg.value ?? "",
      machineYear: this.machine.year,
      machineDescription: this.machine.description,
      pricePerMinOrder: this.machine.pricePerMinOrder,
      pricePerWorkShift: this.machine.pricePerWorkShift,
      machinePhotoUrl: photoUrls[0],
      machineRegNumberPhotoUrl: photoUrls[1],
      machineDocuments: photoUrls.slice(2),
    };
    try {
      await this.api.requestMachineVerification(ownershipRequest, {
        machineUuid: this.machine.uuid,
        orgUuid: this.machine.organizationUuid,
      });
      const alert = await this.alertController.create({
        header: "Документы уже у нас",
        message: "Дайте нам три дня для проверки",
        buttons: [
          {
            text: "OK",
            handler: () => {
              this.location.back();
            },
          },
        ],
      });
      await alert.present();
    } catch (err) {
      const alert = await this.alertController.create({
        header: "Ошибка",
        message: "Произошла ошибка, попробуйте позже",
      });
      await alert.present();
    }
  }
}
