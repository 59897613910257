import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
} from "@angular/core";
import {
  IonActionSheet,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonToolbar,
  ToastController,
} from "@ionic/angular/standalone";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import {
  RegistrationProfileAuthCreateTokenMutationGql,
  RegistrationProfileAuthRegistrationMutationGql,
} from "./registration-profile.gql-gen";
import { firstValueFrom } from "rxjs";
import { RegistrationDataService } from "../registration-page/registration-data.service";
import { IffyStorageService } from "../iffy-storage.service";

@Component({
  selector: "app-registration-profile",
  standalone: true,
  imports: [
    IonIcon,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonInput,
    IonItem,
    IonList,
    ReactiveFormsModule,
    IonContent,
    IonCheckbox,
    IonText,
    IonButton,
    IonFooter,
    RouterModule,
    IonRadioGroup,
    IonRadio,
    IonActionSheet,
  ],
  templateUrl: "./registration-profile.component.html",
  styleUrl: "./registration-profile.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationProfileComponent {
  profileFormGroup = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
  });
  profileType = new FormControl("company");
  checkbox = new FormControl("false", [Validators.requiredTrue]);
  selectAvatarButtons = [
    {
      text: "C камеры",
      handler: () => {
        const msg = { from: "mobappV2", cmd: "getPictureFromCamera" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      },
    },
    {
      text: "Из галереи",
      handler: () => {
        const msg = { from: "mobappV2", cmd: "getPictureFromGallery" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      },
    },
    {
      text: "Удалить фото",
      role: "destructive",
      handler: () => {
        this.registrationData.avatar = "";
        this.cdRef.markForCheck();
      },
    },
  ];

  private iffyStorage = inject(IffyStorageService);
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  constructor(
    private cdRef: ChangeDetectorRef,
    private toastController: ToastController,
    private authRegistrationMutationGql: RegistrationProfileAuthRegistrationMutationGql,
    private authCreateTokenMutationGql: RegistrationProfileAuthCreateTokenMutationGql,
    protected registrationData: RegistrationDataService,
    private router: Router,
  ) {}

  async onRegisterPersonBtnClick(event: Event) {
    event.preventDefault();
    try {
      this.registrationData.firstname = this.profileFormGroup.get("firstName")?.value ?? "";
      this.registrationData.lastname = this.profileFormGroup.get("lastName")?.value ?? "";
      this.registrationData.email = this.profileFormGroup.get("email")?.value ?? "";
      const registrationRes = await firstValueFrom(
        this.authRegistrationMutationGql.mutate({
          registration: this.registrationData.asPersonOnly(),
        }),
      );
      if (registrationRes.errors?.length || !registrationRes.data?.authRegistration) {
        throw new Error("???");
      }
      if (registrationRes.data.authRegistration.result === "OK") {
        const tokenRes = await firstValueFrom(
          this.authCreateTokenMutationGql.mutate({
            phoneNumber: this.registrationData.phoneNumber,
            confirmationCode: this.registrationData.confirmationCode,
          }),
        );
        if (tokenRes.errors?.length || !tokenRes.data?.authCreateToken) {
          throw new Error("???");
        }
        const { result, token } = tokenRes.data.authCreateToken;
        if (result == "OK") {
          const oktoast = await this.toastController.create({
            message: "Добро пожаловать в клуб",
            position: "middle",
            duration: 3000,
          });
          await oktoast.present();
          const msg = {
            from: "mobappV2",
            cmd: "setAuthToken",
            authToken: token,
          };
          this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
          this.iffyStorage.setItem("authToken", token ?? "");
          await this.router.navigate(["/welcome2"], { replaceUrl: true });
        } else {
          throw new Error("???");
        }
      } else if (registrationRes.data.authRegistration.result === "USER_EMAIL_ALREADY_REGISTERED") {
        const emailErrorToast = await this.toastController.create({
          message: "Пользователь с таким email уже существует",
          position: "middle",
          duration: 3000,
        });
        await emailErrorToast.present();
      } else {
        throw new Error("???");
      }
    } catch (err) {
      const allErrorToast = await this.toastController.create({
        message: "Произошла непредвиденная ошибка, попробуйте позже",
        position: "middle",
        duration: 3000,
      });
      await allErrorToast.present();
    }
  }

  @HostListener("window:message", ["$event"])
  onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd === "getPictureResult") {
      this.registrationData.avatar = data.dataUrl;
    }
  }

  async onNextStepCompanyBtnClick(ev: Event) {
    ev.preventDefault();
    this.registrationData.firstname = this.profileFormGroup.get("firstName")?.value ?? "";
    this.registrationData.lastname = this.profileFormGroup.get("lastName")?.value ?? "";
    this.registrationData.email = this.profileFormGroup.get("email")?.value ?? "";
    await this.router.navigate(["/registration-company"]);
  }

  onDocumentLinkClick(event: Event) {
    event.stopPropagation();
  }
}
