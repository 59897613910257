import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RegistrationUnavailableRegionAuthRegionListPublicQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RegistrationUnavailableRegionAuthRegionListPublicQuery = { __typename?: 'Query', authRegionListPublic: Array<{ __typename?: 'RegionPublic', code: string, name: string, prepositionalCaseName: string }> };

export type RegistrationUnavailableRegionAuthSaveLeadMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
  confirmationCode: Types.Scalars['String']['input'];
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  regionCode: Types.Scalars['String']['input'];
  cityName: Types.Scalars['String']['input'];
}>;


export type RegistrationUnavailableRegionAuthSaveLeadMutation = { __typename?: 'Mutation', authSaveLead: { __typename?: 'OkResult', ok?: boolean | null } };

export const RegistrationUnavailableRegionAuthRegionListPublicDocument = gql`
    query RegistrationUnavailableRegionAuthRegionListPublic {
  authRegionListPublic {
    code
    name
    prepositionalCaseName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationUnavailableRegionAuthRegionListPublicQueryGql extends Apollo.Query<RegistrationUnavailableRegionAuthRegionListPublicQuery, RegistrationUnavailableRegionAuthRegionListPublicQueryVariables> {
    override document = RegistrationUnavailableRegionAuthRegionListPublicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegistrationUnavailableRegionAuthSaveLeadDocument = gql`
    mutation RegistrationUnavailableRegionAuthSaveLead($phoneNumber: String!, $confirmationCode: String!, $firstName: String!, $lastName: String!, $email: String!, $regionCode: String!, $cityName: String!) {
  authSaveLead(
    phoneNumber: $phoneNumber
    confirmationCode: $confirmationCode
    firstName: $firstName
    lastName: $lastName
    email: $email
    regionCode: $regionCode
    cityName: $cityName
  ) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationUnavailableRegionAuthSaveLeadMutationGql extends Apollo.Mutation<RegistrationUnavailableRegionAuthSaveLeadMutation, RegistrationUnavailableRegionAuthSaveLeadMutationVariables> {
    override document = RegistrationUnavailableRegionAuthSaveLeadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }