import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IonAvatar,
  IonBackButton,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonSegmentContent,
  IonSegmentView,
  IonText,
  IonTitle,
  IonToolbar,
  ToastController,
} from "@ionic/angular/standalone";
import { YaMapService } from "../ya-map.service";
import {
  assertIsDefined,
  callNumber,
  isDefined,
  paymentType,
  translateWorkAmount,
  translateWorkDateTime,
} from "../utils";
import {
  EntryMatchInternalDetailsEntryMarkViewedMutationGql,
  EntryMatchInternalDetailsSearchInternalEntryMatchByIdQueryGql,
} from "./entry-match-internal-details.component.gql-gen";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { delay, switchMap } from "rxjs/operators";
import dayjs from "dayjs";
import {
  InternalEntryMatchByIdAvailableActions,
  InternalEntryMatchByIdProposeAvailableActions,
  InternalEntryMatchByIdRequestConditionsDateType,
  InternalEntryMatchByIdStatus,
} from "../../base-types.gql-gen";
import { ProposeSupplierFormQueryParams } from "../propose-supplier-form/propose-supplier-form.component";
import { NgTemplateOutlet } from "@angular/common";
import { MaskitoPipe } from "@maskito/angular";
import { MaskitoOptions } from "@maskito/core";
import { maskitoNumberOptionsGenerator } from "@maskito/kit";

type SelectedView = "details" | "map" | "responses";

type EntryMatchInternalUIData = {
  entryId: string;
  machineTypeName: string;
  mainParams: string[];
  foundAgo: string;
  workDateTime: string;
  workAmountUnit: string;
  distanceToBaseKm: number;
  objects: {
    title: string;
    address: string;
    coords: { lat: number; lon: number };
  }[];
  payment: string;
  comment: string;
  buyerCompanyName: string;
  buyerCompanyVerified: boolean;
  buyerLogoUrl: string;
  contactName: string;
  contactPhone: string;
  availableActions: InternalEntryMatchByIdAvailableActions[];
  status?: InternalEntryMatchByIdStatus;
  hasMyPropose: boolean;
};

type ProposeUIData = {
  id: string;
  isMine: boolean;
  machineTypeName: string;
  machineMainParam: string;
  machineTitle: string;
  paymentType: string;
  price: number;
  companyVerified: boolean;
  machineConfirmed: boolean;
  comment: string;
  availableActions: InternalEntryMatchByIdProposeAvailableActions[];
};

const MOSCOW_COORDS = [55.76, 37.64];

export type EntryMatchInternalDetailsQueryParams = {
  entryId: string;
  segment?: SelectedView;
};

@Component({
  selector: "app-entry-match-internal-details",
  standalone: true,
  imports: [
    IonBackButton,
    IonChip,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonItem,
    IonIcon,
    IonText,
    IonAvatar,
    IonButton,
    IonFooter,
    IonSegmentView,
    IonSegmentContent,
    IonCard,
    IonCardContent,
    IonBadge,
    IonCardHeader,
    IonCardTitle,
    IonImg,
    NgTemplateOutlet,
    MaskitoPipe,
    IonContent,
  ],
  templateUrl: "./entry-match-internal-details.component.html",
  styleUrl: "./entry-match-internal-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryMatchInternalDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("yaMapContainer") yaMapContainer?: ElementRef<HTMLDivElement>;
  @ViewChild("htmlIOnFooter") htmlIOnFooter?: ElementRef<HTMLElement>;

  protected selectedSegment: SelectedView = "details";

  protected pending = false;
  protected entryMatch?: EntryMatchInternalUIData;
  protected proposes?: ProposeUIData[];

  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });

  private yaMap: any;

  private selectedSegmentSub?: Subscription;
  private searchInternalEntryMatchByIdSub?: Subscription;
  private markViewedSub?: Subscription;

  constructor(
    private yaMapSrv: YaMapService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private searchInternalEntryMatchByIdQueryGql: EntryMatchInternalDetailsSearchInternalEntryMatchByIdQueryGql,
    private entryMarkViewedMutationGql: EntryMatchInternalDetailsEntryMarkViewedMutationGql,
    private toastCtrl: ToastController,
  ) {}

  ngOnInit() {
    this.selectedSegmentSub = this.activatedRoute.queryParams
      .pipe(
        delay(1), // delay fixes segment navigation
      )
      .subscribe({
        next: async (queryParams) => {
          const { segment } = queryParams as EntryMatchInternalDetailsQueryParams;
          if (segment) {
            this.selectedSegment = segment;
          }
          this.cdRef.markForCheck();
        },
      });

    this.searchInternalEntryMatchByIdSub = this.activatedRoute.queryParams
      .pipe(
        switchMap((queryParams) => {
          const { entryId } = queryParams as EntryMatchInternalDetailsQueryParams;
          const entryMatchIds = [entryId];
          this.pending = true;
          this.entryMatch = undefined;
          this.proposes = undefined;
          return this.searchInternalEntryMatchByIdQueryGql.fetch({ entryMatchIds });
        }),
      )
      .subscribe({
        next: async (res) => {
          assertIsDefined(res.data.searchInternalEntryMatchById);
          assertIsDefined(res.data.authCurrentUser);
          const match = res.data.searchInternalEntryMatchById[0];
          assertIsDefined(match);
          const { id: entryId, contactName, availableActions, distanceToBaseKm } = match;
          const contactPhone = match.contactPhone ?? "";
          const { machineTypeName } = match.request;
          const buyerCompanyName = match.customer.company?.name ?? "";
          const buyerCompanyVerified = match.customer.company?.isVerified ?? false;
          const comment = match.comment ?? "";
          const payment = paymentType(match.request.paymentType);
          const foundAgo = dayjs(match.found).fromNow();

          let workDateTime = "";
          if (match.request.conditions.date) {
            const { date } = match.request.conditions;
            const res = this.translateWorkDateTime(date, false);
            workDateTime = res.workDateTime;
          }

          const objects: EntryMatchInternalUIData["objects"][number][] = [];
          for (const o of match.objects) {
            objects.push({
              title: o.title ?? "",
              address: o.fullAddress,
              coords: {
                lon: o.lng,
                lat: o.lat,
              },
            });
          }

          const mainParams: string[] = [];
          for (const p of match.request.machineParams) {
            if (p.isMain) {
              if (p.boolValue) {
                mainParams.push(p.nameRU);
              }
              for (const name of p.selectNames ?? []) {
                mainParams.push(name);
              }
              if (p.rangeValue) {
                const [min, max] = p.rangeValue;
                if (isDefined(min) && isDefined(max)) {
                  const txt = `от ${min} до ${max} ${p.unit}`;
                  mainParams.push(txt);
                } else {
                  const txt = `${min ?? max} ${p.unit}`;
                  mainParams.push(txt);
                }
              }
            }
          }

          let workAmountUnit = "";
          if (match.request.conditions.work) {
            const { type, value } = match.request.conditions.work;
            assertIsDefined(type);
            assertIsDefined(value);
            workAmountUnit = translateWorkAmount(type, value);
          }

          let buyerLogoUrl = "";
          if (buyerCompanyName) {
            buyerLogoUrl = match.customer.logo ?? "";
            if (buyerLogoUrl) {
              buyerLogoUrl += "?w=128";
            }
          }

          this.pending = false;
          this.entryMatch = {
            entryId,
            machineTypeName,
            mainParams,
            buyerCompanyName,
            buyerCompanyVerified,
            buyerLogoUrl,
            contactName,
            contactPhone,
            distanceToBaseKm,
            comment,
            payment,
            workAmountUnit,
            foundAgo,
            workDateTime,
            objects,
            availableActions,
            status: match.status ?? undefined,
            hasMyPropose: false,
          };

          const { proposes } = match;
          this.proposes = [];
          for (const propose of proposes) {
            const machineTitle = `${propose.machineModel}, ${propose.machineYear} г`;
            let machineMainParam = "";
            if (propose.machineMainParam) {
              const param = propose.machineMainParam;
              if (param.boolValue) {
                machineMainParam = param.name;
              }
              if (param.stringValue) {
                machineMainParam = param.stringValue;
              }
              if (param.numberValue && param.unit) {
                machineMainParam = `${param.numberValue} ${param.unit}`;
              }
            }
            if (propose.myPropose) {
              this.entryMatch.hasMyPropose = true;
            }

            this.proposes.push({
              id: propose.id,
              isMine: propose.myPropose,
              companyVerified: propose.companyVerified,
              machineConfirmed: propose.machineConfirmed,
              machineTitle,
              paymentType: paymentType(propose.paymentType),
              price: propose.price,
              machineTypeName,
              machineMainParam,
              comment: propose.comment ?? "",
              availableActions: propose.availableActions,
            });
          }
          this.proposes.sort((a, b) => {
            if (a.isMine && !b.isMine) {
              return -1;
            }
            if (b.isMine && !a.isMine) {
              return 1;
            }
            return 0;
          });

          if (this.yaMap) {
            this.updatePlacemarks();
          }
          this.cdRef.markForCheck();

          if (!this.markViewedSub) {
            this.markViewedSub = this.entryMarkViewedMutationGql
              .mutate({
                entryId: match.id,
                uuidUser: res.data.authCurrentUser.uuid,
              })
              .subscribe();
          }
        },
      });
  }

  async ngAfterViewInit() {
    await this.setupYaMap();
  }

  ngOnDestroy() {
    this.yaMap?.destroy();
    this.yaMap = undefined;
    this.selectedSegmentSub?.unsubscribe();
    this.searchInternalEntryMatchByIdSub?.unsubscribe();
    this.markViewedSub?.unsubscribe();
  }

  onSegmentChange(event: CustomEvent) {
    this.selectedSegment = event.detail.value;
  }

  private async setupYaMap() {
    const ymaps = await this.yaMapSrv.ymaps;
    const div = this.yaMapContainer?.nativeElement;
    assertIsDefined(div);
    this.yaMap = new ymaps.Map(div, {
      center: MOSCOW_COORDS,
      controls: ["zoomControl"],
      zoom: 10,
    });
    this.updatePlacemarks();
  }

  private updatePlacemarks() {
    if (!this.yaMap || !this.entryMatch) {
      return;
    }
    this.yaMap.geoObjects.removeAll();
    for (const obj of this.entryMatch.objects ?? []) {
      const { lon, lat } = obj.coords;
      const coords = [lat, lon];
      const placemark = new ymaps.Placemark(
        coords,
        {},
        {
          iconLayout: "default#image",
          iconImageHref: "./assets/icon/map-marker.svg",
        },
      );
      placemark.geometry.setCoordinates(coords);
      this.yaMap.geoObjects.add(placemark);
    }
    const firstObject = this.entryMatch.objects.at(0);
    if (firstObject) {
      const { lon, lat } = firstObject.coords;
      this.yaMap.setCenter([lat, lon], 15);
    }
  }

  private translateWorkDateTime(
    conditionDate: {
      type?: InternalEntryMatchByIdRequestConditionsDateType | null;
      value?: number | null;
    },
    translateToday = false,
  ) {
    const { type, value } = conditionDate;
    if (type === "none") {
      return { workDateTime: "Дата обсуждаема", isWDToday: false };
    }
    if (type && value) {
      return translateWorkDateTime({ type, value }, translateToday);
    }
    return { workDateTime: "", isWDToday: false };
  }

  async onObjectAddressItemClick(ev: Event) {
    ev.preventDefault();
    this.selectedSegment = "map";
  }

  async onEditProposeBtnClick(ev: Event) {
    ev.preventDefault();
    if (this.entryMatch) {
      const queryParams: ProposeSupplierFormQueryParams = {
        entryId: this.entryMatch.entryId,
      };
      await this.router.navigate(["/propose-supplier-form"], { queryParams });
    }
  }

  async onCreateProposeBtnClick(ev: Event) {
    ev.preventDefault();
    if (this.entryMatch) {
      const queryParams: ProposeSupplierFormQueryParams = {
        entryId: this.entryMatch.entryId,
      };
      await this.router.navigate(["/propose-supplier-form"], { queryParams });
    }
  }

  async onCallBtnClick(ev: Event) {
    if (this.entryMatch?.hasMyPropose) {
      const { contactPhone } = this.entryMatch;
      callNumber(`+${contactPhone}`);
    } else {
      const toast = await this.toastCtrl.create({
        message: "Возможность позвонить заказчику появится после отправки предложения",
        position: "bottom",
        positionAnchor: (ev.target as HTMLElement).closest("ion-footer") as HTMLElement,
        color: "light",
        duration: 5000,
      });
      await toast.present();
    }
  }
}
