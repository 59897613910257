import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { BACKEND_URLS, FRONTEND_URLS } from "../utils";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { IffyStorageService } from "../iffy-storage.service";

@Component({
  selector: "app-secret-settings",
  standalone: true,
  imports: [
    IonHeader,
    IonContent,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonButton,
    IonRadioGroup,
    IonRadio,
    IonList,
    IonItem,
    IonListHeader,
    IonLabel,
    ReactiveFormsModule,
  ],
  templateUrl: "./secret-settings.component.html",
  styleUrl: "./secret-settings.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecretSettingsComponent implements OnInit {
  backendURLs = [
    { label: `Prod (${BACKEND_URLS.production})`, value: BACKEND_URLS.production },
    { label: `Dev (${BACKEND_URLS.development})`, value: BACKEND_URLS.development },
  ];
  frontendURLs = [
    { label: `Prod (${FRONTEND_URLS.production})`, value: FRONTEND_URLS.production },
    { label: `Dev (${FRONTEND_URLS.development})`, value: FRONTEND_URLS.development },
    { label: `Local (${FRONTEND_URLS.local})`, value: FRONTEND_URLS.local },
    { label: `Local Lera PC (${FRONTEND_URLS.localLeraPC})`, value: FRONTEND_URLS.localLeraPC },
    { label: `Local Dima Mac (${FRONTEND_URLS.localDimaMac})`, value: FRONTEND_URLS.localDimaMac },
  ];

  protected urls = new FormGroup({
    backendUrl: new FormControl("", {
      validators: [Validators.required],
      nonNullable: true,
    }),
    frontendUrl: new FormControl("", {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  private iffyStorage = inject(IffyStorageService);
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  ngOnInit() {
    const backendUrl = this.iffyStorage.getItem("backendUrl") || BACKEND_URLS.production;
    const frontendUrl = this.iffyStorage.getItem("frontendUrl") || FRONTEND_URLS.production;
    this.urls.patchValue({ backendUrl, frontendUrl });
  }

  onSaveBtnClick(ev: Event) {
    ev.preventDefault();
    const { backendUrl, frontendUrl } = this.urls.value;
    if (backendUrl && frontendUrl) {
      this.iffyStorage.setItem("backendUrl", backendUrl);
      this.iffyStorage.setItem("frontendUrl", frontendUrl);
      const msg = {
        from: "mobappV2",
        cmd: "setUrls",
        backendUrl,
        frontendUrl,
      };
      this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
    }
  }
}
