import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IonItem, IonLabel, IonText } from "@ionic/angular/standalone";

import { MachineAvailabilityStatusShelfMachineQuery } from "./machine-availability-status-shelf.gql-gen";
import dayjs from "dayjs";

@Component({
  selector: "app-machine-availability-status-shelf",
  standalone: true,
  imports: [IonItem, IonLabel, IonText],
  templateUrl: "./machine-availability-status-shelf.component.html",
  styleUrl: "./machine-availability-status-shelf.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineAvailabilityStatusShelfComponent implements OnChanges {
  @Input({ required: true }) machine?: MachineAvailabilityStatusShelfMachineQuery["machine"];

  protected uiData = { status: "", starts: "", ends: "" };

  ngOnChanges(changes: SimpleChanges): void {
    if ("machine" in changes) {
      this.uiData = { status: "", starts: "", ends: "" };
      if (this.machine?.availabilityStatus) {
        const statusAvailableDate = this.machine.date?.available
          ? dayjs(this.machine.date?.available * 1000)
          : dayjs();
        const statusBusyDate = dayjs(this.machine.availabilityStatus.statusEnds);
        this.uiData = {
          status: this.machine.availabilityStatus.status ?? "unknown",
          starts: statusAvailableDate.fromNow(),
          ends: statusBusyDate.fromNow(true),
        };
      }
    }
  }
}
