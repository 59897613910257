import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type WelcomePage2AuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WelcomePage2AuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', firstname?: string | null } | null };

export const WelcomePage2AuthCurrentUserDocument = gql`
    query welcomePage2AuthCurrentUser {
  authCurrentUser {
    firstname
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WelcomePage2AuthCurrentUserQueryGql extends Apollo.Query<WelcomePage2AuthCurrentUserQuery, WelcomePage2AuthCurrentUserQueryVariables> {
    override document = WelcomePage2AuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }