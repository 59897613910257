import { ErrorHandler, inject } from "@angular/core";
import { APM } from "@elastic/apm-rum-angular";
import { isApolloError } from "@apollo/client/core";
import { ErrorInterceptorQueryParams } from "./error-handled/error-handled.component";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { INJECTED_TRACE_ID } from "./graphql-error.interceptor";
import { CustomAssertError } from "./utils";
import { IffyStorageService } from "./iffy-storage.service";

export class CustomErrorHandler implements ErrorHandler {
  private router = inject(Router);
  private apm = inject(APM);
  private iffyStorage = inject(IffyStorageService);

  async handleError(error: any) {
    if (isApolloError(error)) {
      let traceId = "";
      let unauthorized = false;
      for (const er of error.graphQLErrors as any[]) {
        traceId = er[INJECTED_TRACE_ID] ?? "";
        const msg = er.message.toLowerCase();
        unauthorized ||= msg.includes("unauthorized");
        const msg2 = er.extensions?.originalError?.message?.toLowerCase();
        unauthorized ||= msg2?.includes("unauthorized");
      }
      if (unauthorized) {
        // no session, or session is expired
        await this.handleUnauthorizedError(traceId);
        console.warn("WARN", error);
        return;
      }
      // other graphQLError returned by backend
      const queryParams: ErrorInterceptorQueryParams = { traceId };
      await this.router.navigate(["/error-handled"], { queryParams, replaceUrl: true });
      console.warn("WARN", error);
      return;
    }

    if (error instanceof CustomAssertError) {
      await this.router.navigate(["/error-handled"], { replaceUrl: true });
      this.apm.captureError(error);
      console.error("ERROR", error);
      return;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.status === 0) {
        // no internet
      }
      if (error.status === 401) {
        // no session, or session is expired
        await this.handleUnauthorizedError();
        console.warn("WARN", error);
        return;
      }
      if (error.status >= 500 && error.status < 600) {
        await this.router.navigate(["/error-handled"], { replaceUrl: true });
        console.warn("WARN", error);
        return;
      }
    }

    // https://github.com/elastic/apm-agent-rum-js/blob/65ebcabe9cd1472d6b0b8da7b0a5489fe7d126f3/packages/rum-angular/src/error-handler.ts#L37
    this.apm.captureError(error.originalError || error);
    // https://github.com/angular/angular/blob/be32d6365251bf9d03bcb2f97525d28daa83f610/packages/core/src/error_handler.ts#L50
    console.error("ERROR", error);
  }

  private async handleUnauthorizedError(traceId?: string) {
    const queryParams: ErrorInterceptorQueryParams = { traceId, kind: "unauthorized" };
    const signPrefixes = ["/sign-in", "/registration"];
    const errorPrefixes = ["/error"];
    const fn = (s: string) => this.router.url.startsWith(s);
    if (!signPrefixes.some(fn) && !errorPrefixes.some(fn)) {
      this.iffyStorage.setItem("signInRedirectUrl", this.router.url);
    }
    const replaceUrl = !signPrefixes.some(fn);
    await this.router.navigate(["/error-handled"], { queryParams, replaceUrl });
  }
}
