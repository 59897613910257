<ion-header>
  <ion-toolbar>
    <ion-back-button slot="start" [defaultHref]="'/home/matches'"></ion-back-button>
    <ion-title>Заявка</ion-title>
    @if (entryMatch?.status === "propose_sent") {
      <ion-chip slot="end" color="primary">Сделано предложение</ion-chip>
    } @else if (entryMatch?.status === "signed_by_customer") {
      <ion-chip slot="end" color="rcgreen">Подписана поставщиком</ion-chip>
    } @else if (entryMatch) {
      <ion-chip slot="end" color="medium">Просмотрена</ion-chip>
    }
  </ion-toolbar>
  <ion-toolbar>
    <ion-segment [value]="selectedSegment" (ionChange)="onSegmentChange($event)">
      <ion-segment-button value="details" content-id="entry-match-internal-details-details">
        <ion-label>Детали</ion-label>
      </ion-segment-button>
      <ion-segment-button value="map" content-id="entry-match-internal-details-map">
        <ion-label>Карта</ion-label>
      </ion-segment-button>
      <ion-segment-button value="responses" content-id="entry-match-internal-details-responses">
        @if (proposes && proposes.length > 0) {
          <ion-label>Отклики ({{ proposes.length }})</ion-label>
        } @else {
          <ion-label>Отклики</ion-label>
        }
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-segment-view>
    <ion-segment-content id="entry-match-internal-details-details">
      @if (entryMatch) {
        <div class="segment-content">
          <div class="ion-padding">
            <ion-text class="placed-ago">Размещена&nbsp;</ion-text>
            <ion-text class="placed-ago">{{ entryMatch.foundAgo }}</ion-text>
          </div>
          <div class="ion-padding">
            @if (entryMatch.mainParams.length) {
              <h1 class="ion-no-margin">
                <span>{{ entryMatch.machineTypeName }},&nbsp;</span>
                @for (p of entryMatch.mainParams; track p) {
                  @if ($first) {
                    <span>{{ p }}</span>
                  } @else {
                    <span>,&nbsp;{{ p }}</span>
                  }
                }
              </h1>
            } @else {
              <h1 class="ion-no-margin">
                {{ entryMatch.machineTypeName }}
              </h1>
            }

            <ion-list>
              <ion-item>
                <ion-icon name="calendar" slot="start"></ion-icon>
                <ion-text class="date">
                  <span>{{ entryMatch.workDateTime }},&nbsp;</span>
                  <span>{{ entryMatch.workAmountUnit }}</span>
                </ion-text>
              </ion-item>
              <ion-item (click)="onObjectAddressItemClick($event)">
                <ion-icon name="location" slot="start"></ion-icon>
                <ion-label>
                  @for (obj of entryMatch.objects; track $index) {
                    @if ($first) {
                      @if (obj.title) {
                        <ion-text class="address">{{ obj.title }},</ion-text>
                      }
                      <ion-text class="address">{{ obj.address }}</ion-text>
                      <br />
                      <ion-text class="distance">
                        {{ entryMatch.distanceToBaseKm }} км от базы
                      </ion-text>
                      <br />
                    }

                    @if (!$first) {
                      <br />
                      <ion-text class="address">→&nbsp;</ion-text>
                      @if (obj.title) {
                        <ion-text class="address">{{ obj.title }},</ion-text>
                      }
                      <ion-text class="address">{{ obj.address }}</ion-text>
                      <br />
                    }
                  }
                </ion-label>
              </ion-item>

              <ion-item>
                <ion-icon name="cash" slot="start"></ion-icon>
                <ion-text class="payment">{{ entryMatch.payment }}</ion-text>
              </ion-item>
              @if (entryMatch.comment) {
                <ion-item>
                  <ion-icon name="chatbox-ellipses" slot="start"></ion-icon>
                  <ion-text class="comment">{{ entryMatch.comment }}</ion-text>
                </ion-item>
              }
            </ion-list>
            <div class="ion-padding-top">
              <ion-item class="customer-block" lines="none" color="light">
                @if (entryMatch.buyerCompanyName) {
                  @if (entryMatch.buyerLogoUrl) {
                    <ion-avatar class="avatar-with-icon" slot="start">
                      <ion-img [src]="entryMatch.buyerLogoUrl"></ion-img>
                    </ion-avatar>
                  } @else {
                    <ion-avatar class="avatar-with-icon" slot="start">
                      <ion-icon name="business"></ion-icon>
                    </ion-avatar>
                  }
                  <div class="company-info">
                    <ion-text>{{ entryMatch.buyerCompanyName }}</ion-text>
                    <ion-text>{{ entryMatch.contactName }}</ion-text>
                  </div>
                  @if (entryMatch.buyerCompanyVerified) {
                    <ion-icon name="checkmark-circle-outline" color="rcgreen" slot="end"></ion-icon>
                  }
                } @else {
                  <ion-avatar class="avatar-with-icon" slot="start">
                    <ion-icon name="person"></ion-icon>
                  </ion-avatar>
                  <div class="company-info">
                    <ion-text>{{ entryMatch.contactName }}</ion-text>
                  </div>
                }
              </ion-item>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="footerWithButton"></ng-container>
      }
    </ion-segment-content>

    <ion-segment-content id="entry-match-internal-details-map">
      <ng-container *ngTemplateOutlet="footerWithButton"></ng-container>
      <div class="segment-content">
        <div #yaMapContainer class="yamap"></div>
      </div>
    </ion-segment-content>

    <ion-segment-content class="responses-tab" id="entry-match-internal-details-responses">
      @if (entryMatch) {
        <div class="segment-content">
          @for (propose of proposes; track propose.id) {
            <ion-card>
              <ion-card-content class="ion-no-padding">
                <ion-item lines="full" class="top-content" [class.top-content-my]="propose.isMine">
                  @if (propose.machineMainParam) {
                    <ion-label class="top-label">
                      {{ propose.machineTypeName }} , {{ propose.machineMainParam }}
                    </ion-label>
                  } @else {
                    <ion-label class="top-label">
                      {{ propose.machineTypeName }}
                    </ion-label>
                  }
                  @if (propose.isMine) {
                    <ion-badge class="white-badge" slot="end">Мой</ion-badge>
                  }
                </ion-item>
              </ion-card-content>
              <ion-card-header>
                <ion-card-title>{{ propose.machineTitle }}</ion-card-title>
              </ion-card-header>
              <ion-card-content class="ion-no-padding ion-padding-bottom medium-content">
                <ion-list>
                  @if (propose.comment) {
                    <ion-item>
                      <ion-icon name="chatbox-ellipses" slot="start"></ion-icon>
                      <ion-text class="comment">
                        {{ propose.comment }}
                      </ion-text>
                    </ion-item>
                  }

                  <ion-item lines="none">
                    <ion-icon name="cash" slot="start"></ion-icon>
                    <ion-label>
                      <ion-text class="price">
                        {{ propose.price | maskito: priceMask }} ₽/смена
                      </ion-text>
                      <br />
                      <ion-text class="price-details">{{ propose.paymentType }}</ion-text>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          } @empty {
            <ion-text class="empty-responses">
              Пока еще никто не откликнулся,
              <br />
              будьте первыми.
            </ion-text>
          }
        </div>
        <ng-container *ngTemplateOutlet="footerWithButton"></ng-container>
      }
    </ion-segment-content>
  </ion-segment-view>
</ion-content>
<ng-template #footerWithButton>
  <ion-footer class="ion-no-border">
    <div class="button-container ion-padding">
      @if (entryMatch?.availableActions?.includes("send_propose")) {
        <ion-button (click)="onCreateProposeBtnClick($event)" color="rcyellow">
          Откликнуться
        </ion-button>
      } @else if (entryMatch?.hasMyPropose) {
        <ion-button (click)="onEditProposeBtnClick($event)" fill="outline" size="default">
          изменить&nbsp;отклик
        </ion-button>
      }
      <ion-button
        (click)="onCallBtnClick($event)"
        [class.inactive-call-button]="!entryMatch?.hasMyPropose"
        fill="outline"
      >
        Позвонить
      </ion-button>
    </div>
  </ion-footer>
</ng-template>
