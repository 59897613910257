import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { APM } from "@elastic/apm-rum-angular";
import { inject } from "@angular/core";
import { GRAPHQL_URI } from "./utils";

// https://github.com/elastic/apm-agent-rum-js/blob/65ebcabe9cd1472d6b0b8da7b0a5489fe7d126f3/packages/rum-core/src/common/constants.js#L74
const HTTP_REQUEST_TYPE = "http-request";

export const INJECTED_TRACE_ID = "injectedTraceId";

export function graphqlErrorInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  if (!req.url.endsWith(GRAPHQL_URI)) {
    return next(req);
  }

  const apm = inject(APM);
  // https://github.com/elastic/apm-agent-rum-js/blob/65ebcabe9cd1472d6b0b8da7b0a5489fe7d126f3/packages/rum-core/src/performance-monitoring/performance-monitoring.js#L234
  let trans = apm.getCurrentTransaction();
  if (!trans) {
    const spanName = req.method + " " + req.url;
    trans = apm.startTransaction(spanName, HTTP_REQUEST_TYPE, { managed: true });
  }
  let traceId = (trans as any).traceId ?? "";
  return next(req).pipe(
    tap((ev) => {
      if (ev.type === HttpEventType.Response) {
        const body = ev.body as any;
        if (body?.errors instanceof Array && body.errors.length > 0) {
          for (const er of body.errors) {
            er[INJECTED_TRACE_ID] = traceId;
          }
        }
      }
    }),
  );
}
