import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  ToastController,
} from "@ionic/angular/standalone";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import {
  RegistrationCompanyAuthCreateTokenMutationGql,
  RegistrationCompanyAuthGetCompanyDataByInnQueryGql,
  RegistrationCompanyAuthRegistrationMutationGql,
} from "./registration-company.gql-gen";
import { firstValueFrom } from "rxjs";
import { isDefined } from "../utils";
import dayjs from "dayjs";
import { RegistrationDataService } from "../registration-page/registration-data.service";
import { IffyStorageService } from "../iffy-storage.service";

type Company = {
  inn?: string | null;
  kpp?: string | null;
  ogrn?: string | null;
  founded?: string | null;
  address?: string | null;
  fullName?: string | null;
};

@Component({
  selector: "app-registration-company",
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonInput,
    IonItem,
    IonFooter,
    IonButton,
    IonText,
    ReactiveFormsModule,
    IonCheckbox,
    RouterModule,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonModal,
  ],
  templateUrl: "./registration-company.component.html",
  styleUrl: "./registration-company.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationCompanyComponent {
  companies: Company[] = [];
  selectedCompany?: Company;

  innNumber = new FormControl("", [Validators.required, Validators.pattern(/^\d{10,}$/)]);
  checkboxAgreement = new FormControl("false", [Validators.requiredTrue]);
  checkboxPolicy = new FormControl("false", [Validators.requiredTrue]);

  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin
  private iffyStorage = inject(IffyStorageService);

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private authGetCompanyDataByInnQueryGql: RegistrationCompanyAuthGetCompanyDataByInnQueryGql,
    private authRegistrationMutationGql: RegistrationCompanyAuthRegistrationMutationGql,
    private authCreateTokenMutationGql: RegistrationCompanyAuthCreateTokenMutationGql,
    private toastController: ToastController,
    protected registrationData: RegistrationDataService,
  ) {}

  async onSearchCompanyBtnClick() {
    const { phoneNumber, confirmationCode } = this.registrationData;
    const inn = this.innNumber.value ?? "";
    const res = await firstValueFrom(
      this.authGetCompanyDataByInnQueryGql.fetch({ phoneNumber, confirmationCode, inn }),
    );
    this.companies = res.data.authGetCompanyDataByInn.companies.filter(isDefined);
    if (this.companies.length === 1) {
      this.selectedCompany = this.companies[0];
    }
    this.cdRef.markForCheck();
  }

  onCardClick(company: Company) {
    this.selectedCompany = company;
  }

  formatDate(date: string | null | undefined) {
    return dayjs(date ?? "").format("D MMMM YYYY");
  }

  onDocumentLinkClick(event: Event) {
    event.stopPropagation();
  }

  async onRegisterCompanyBtnClick() {
    try {
      this.registrationData.inn = this.selectedCompany?.inn ?? "";
      this.registrationData.ogrn = this.selectedCompany?.ogrn ?? "";
      const registrationRes = await firstValueFrom(
        this.authRegistrationMutationGql.mutate({
          registration: this.registrationData.asPersonWithCompany(),
        }),
      );
      if (registrationRes.errors?.length || !registrationRes.data?.authRegistration) {
        throw new Error("???");
      }
      if (registrationRes.data.authRegistration.result === "OK") {
        const tokenRes = await firstValueFrom(
          this.authCreateTokenMutationGql.mutate({
            phoneNumber: this.registrationData.phoneNumber,
            confirmationCode: this.registrationData.confirmationCode,
          }),
        );
        if (tokenRes.errors?.length || !tokenRes.data?.authCreateToken) {
          throw new Error("???");
        }
        const { result, token } = tokenRes.data.authCreateToken;
        if (result == "OK") {
          const oktoast = await this.toastController.create({
            message: "Добро пожаловать в клуб",
            position: "middle",
            duration: 3000,
          });
          await oktoast.present();
          const msg = {
            from: "mobappV2",
            cmd: "setAuthToken",
            authToken: token,
          };
          this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
          this.iffyStorage.setItem("authToken", token ?? "");
          await this.router.navigate(["/welcome2"], { replaceUrl: true });
        } else {
          throw new Error("???");
        }
      } else if (registrationRes.data.authRegistration.result === "USER_EMAIL_ALREADY_REGISTERED") {
        const emailErrorToast = await this.toastController.create({
          message: "Пользователь с таким email уже существует",
          position: "middle",
          duration: 3000,
        });
        await emailErrorToast.present();
      } else {
        throw new Error("???");
      }
    } catch (err) {
      const allErrorToast = await this.toastController.create({
        message: "Произошла непредвиденная ошибка, попробуйте позже",
        position: "middle",
        duration: 3000,
      });
      await allErrorToast.present();
    }
  }
}
