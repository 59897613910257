import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  EntryCancelEntryMutationGql,
  EntrySendProposalMutationGql,
  GetEntryMachinesQueryGql,
  GetEntryQueryGql,
  GetMachineTypesQueryGql,
} from "./queries/entry.gql-gen";
import { GetMachinesQueryGql } from "./queries/machine.gql-gen";
import {
  Entry,
  EntryObjectsInput,
  EntryProposes,
  Machine,
  Organization,
  ProposeContact,
} from "../../base-types.gql-gen";
import { GetOrgQueryGql } from "./queries/organization.gql-gen";
import { EntryCancelProposeMutationGql } from "./queries/propose.gql-gen";
import { firstValueFrom } from "rxjs";
import { EntryInitDepartureAgreementMutationGql } from "./queries/out-request.gql-gen";
import {
  ChaportConfigResDto,
  CreateMachineDto,
  MachineBaseData,
  OwnershipRequestMachineDto,
  UpdateMachineDto,
  UpdatePersonProfileDto,
} from "./api.interfaces";
import { BACKEND_URLS } from "../utils";
import { IffyStorageService } from "../iffy-storage.service";

@Injectable()
export class Api {
  private iffyStorage = inject(IffyStorageService);

  constructor(
    private httpClient: HttpClient,
    private getEntry: GetEntryQueryGql,
    private getEntryMachines: GetEntryMachinesQueryGql,
    private getMachinesGql: GetMachinesQueryGql,
    private getMachineTypes: GetMachineTypesQueryGql,
    private getOrg: GetOrgQueryGql,
    private cancelProposeGql: EntryCancelProposeMutationGql,
    private cancelEntryGql: EntryCancelEntryMutationGql,
    private entrySendProposalGql: EntrySendProposalMutationGql,
    private initDepartureAgreement: EntryInitDepartureAgreementMutationGql,
  ) {}

  watchEntryById(id: string) {
    return this.getEntry.watch({
      uuidEntry: id,
    });
  }

  getPartnersForEntry(id: string, page?: number, size?: number) {
    return this.getEntryMachines.watch({
      uuidEntry: id,
      page,
      size,
    });
  }

  getOrganization(id: string = ""): Promise<Organization> {
    return this.getOrg
      .watch({ uuid: id })
      .result()
      .then(({ data }: any) => data.organization);
  }

  getMachines(orgId: string): Promise<Machine[]> {
    return this.getMachinesGql
      .watch({
        uuidOrganization: orgId,
      })
      .result()
      .then(({ data }: any) => data.machines);
  }

  cancelPropose(ref: string): Promise<any> {
    return firstValueFrom(this.cancelProposeGql.mutate({ proposeRef: ref }));
  }

  cancelEntry(ref: string): Promise<any> {
    return firstValueFrom(this.cancelEntryGql.mutate({ entryRef: ref }));
  }

  entrySendProposal(
    entry: Entry,
    propose: EntryProposes,
    contacts: ProposeContact[],
    objects: EntryObjectsInput[],
    role: "supplier" | "customer",
    autoSign: boolean = true,
  ): Promise<any> {
    const request = propose.requests?.[0];
    const response = propose.responses?.[0];
    const supplier = propose.supplier;
    const price = request?.payment?.value || response?.data?.budgetValue || 0;
    return firstValueFrom(
      this.entrySendProposalGql.mutate({
        entryUuid: entry.uuid,
        contacts: contacts,
        from: role,
        comment: request?.data?.comment || "",
        customerOrganizationUuid: entry.customer?.organization?.uuid || "",
        customerCompanyUuid: entry.customer?.company?.uuid,
        supplierOrganizationUuid:
          response?.data?.uuidOrganization || supplier?.organization?.uuid || "",
        supplierMachineUuid: response?.machine?.uuid,
        supplierCompanyUuid: response?.data?.uuidCompany || supplier?.company?.uuid,
        budgetValue: price.toString(),
        paymentType: request?.payment?.type,
        distance: response?.data?.distance?.toString(),
        params: request?.data?.params || [],
        addressObject: objects[0],
        dateTimeValue: request?.conditions?.date?.value,
        dateTimeType: request?.conditions?.date?.type,
        workType: request?.conditions?.work?.type,
        workValue: request?.conditions?.work?.value,
        autoSign,
      }),
    );
  }

  changeEntry(
    entry: any,
    orgId: string,
    action: "update" | "delete" | "sign" | "cancel" | "addPropose" | "lookup" | "reject",
  ): Promise<any> {
    const data = action === "cancel" ? {} : entry;
    return this.clientSetup(
      "post",
      `/v2/user/org/${orgId}/entry/${entry.uuid}/action/${action}`,
      data,
    );
  }

  getMachineCategories() {
    return this.getMachineTypes
      .watch()
      .result()
      .then(({ data }: any) => data.machineTypes);
  }

  sendRequestOut(ref: string = "", date: string = "", comment?: string) {
    return firstValueFrom(this.initDepartureAgreement.mutate({ proposeRef: ref, date, comment }));
  }

  private clientSetup(method: string, url: string, body?: any): Promise<any> {
    const baseUrl = this.iffyStorage.getItem("backendUrl") || BACKEND_URLS.production;
    const fullUrl = baseUrl + url;
    return firstValueFrom(this.httpClient.request<any>(method, fullUrl, { body }));
  }

  savePersonProfile(data: UpdatePersonProfileDto) {
    return this.clientSetup("PUT", `/v2/user/`, data);
  }

  async savePersonAvatar(file: File, orgId: string) {
    const formData: FormData = new FormData();
    formData.append("file", file);
    const path = "/v2/user/org/:uuidOrg/file/upload/:fileType"
      .replace(":uuidOrg", orgId)
      .replace(":fileType", "avatar");
    return this.clientSetup("POST", path, formData);
  }

  async setMachineBase(machineBase: MachineBaseData, orgUuid: string) {
    return this.clientSetup("POST", `/v2/user/org/${orgUuid}/base/address`, machineBase);
  }

  async saveMachinePhoto(file: File, orgId: string) {
    const formData: FormData = new FormData();
    formData.append("file", file);
    const path = "/v2/user/org/:uuidOrg/file/upload/:fileType"
      .replace(":uuidOrg", orgId)
      .replace(":fileType", "machine");
    return this.clientSetup("POST", path, formData);
  }

  async createMachine(data: CreateMachineDto, uuids: { orgUuid: string }) {
    return this.clientSetup("POST", `/v2/user/org/${uuids.orgUuid}/machine`, data);
  }

  async updateMachine(data: UpdateMachineDto, uuids: { orgUuid: string; machineUuid: string }) {
    return this.clientSetup(
      "PUT",
      `/v2/user/org/${uuids.orgUuid}/machine/${uuids.machineUuid}`,
      data,
    );
  }

  async requestMachineVerification(
    data: OwnershipRequestMachineDto,
    uuids: { orgUuid: string; machineUuid?: string },
  ) {
    const path = `/v2/user/org/${uuids.orgUuid}/machine/${uuids.machineUuid}/ownership/confirm`;
    return this.clientSetup("POST", path, data);
  }

  fetchChaportConfig(): Promise<ChaportConfigResDto> {
    return this.clientSetup("get", `/v2/chaport/config`);
  }

  setChaportVisitorId(visitorId: string): Promise<void> {
    return this.clientSetup("post", `/v2/chaport/visitorId`, { visitorId });
  }

  sendInvite(phone: string) {
    return this.clientSetup("post", "/v2/user/invite", {
      phone,
    });
  }
}
