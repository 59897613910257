import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AuthSendConfirmationCodeMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
}>;


export type AuthSendConfirmationCodeMutation = { __typename?: 'Mutation', authSendConfirmationCode: { __typename?: 'OkResult', ok?: boolean | null } };

export type AuthCreateTokenMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
  confirmationCode: Types.Scalars['String']['input'];
}>;


export type AuthCreateTokenMutation = { __typename?: 'Mutation', authCreateToken: { __typename?: 'TokenResult', token?: string | null, result: Types.EnumTokenResult } };

export const AuthSendConfirmationCodeDocument = gql`
    mutation authSendConfirmationCode($phoneNumber: String!) {
  authSendConfirmationCode(phone: $phoneNumber) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthSendConfirmationCodeMutationGql extends Apollo.Mutation<AuthSendConfirmationCodeMutation, AuthSendConfirmationCodeMutationVariables> {
    override document = AuthSendConfirmationCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthCreateTokenDocument = gql`
    mutation authCreateToken($phoneNumber: String!, $confirmationCode: String!) {
  authCreateToken(phoneNumber: $phoneNumber, confirmationCode: $confirmationCode) {
    token
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthCreateTokenMutationGql extends Apollo.Mutation<AuthCreateTokenMutation, AuthCreateTokenMutationVariables> {
    override document = AuthCreateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }