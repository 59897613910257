import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import {
  MachineTypeSelectionModalComponent,
  MachineTypeX,
  RcSelectEvent,
} from "../machine-type-selection-modal/machine-type-selection-modal.component";
import { CreateEntryInput, EntryWorkType } from "../../base-types.gql-gen";
import {
  MachineParamsAppSelectEvent,
  MachineParamsSelectionModalComponent,
  MachineParamValueDto,
} from "../machine-params-selection-modal/machine-params-selection-modal.component";
import {
  EntryRequestFormDispatcherAuthCreateContactMutationGql,
  EntryRequestFormDispatcherAuthCreateObjectMutationGql,
  EntryRequestFormDispatcherAuthOrganizationsQueryGql,
  EntryRequestFormDispatcherOrganizationsByNameQueryGql,
  EntryRequestFormDispatcherSearchCreateRequestMutationGql,
  EntryRequestFormDispatcherSearchProvidersCountQueryGql,
  EntryRequestFormDispatcherSearchProvidersCountQueryVariables,
} from "./entry-request-form-dispatcher.gql-gen";
import { firstValueFrom } from "rxjs";
import { InputCustomEvent, SelectCustomEvent, TextareaCustomEvent } from "@ionic/angular";
import { HideOnKeyboardDirective } from "../hide-on-keyboard.directive";
import {
  DatetimeWheelsModalComponent,
  RcDateTimeChangeEvent,
} from "../datetime-wheels-modal/datetime-wheels-modal.component";
import dayjs from "dayjs";
import {
  assertIsDefined,
  declension,
  isDefined,
  PHONE_NUMBER_RU_MASK,
  superSimpleSearch,
} from "../utils";
import {
  SearchAddressSelectEvent,
  SearchAddressWithMapModalComponent,
} from "../search-address-with-map-modal/search-address-with-map-modal.component";
import { PreviewAddressWithMapModalComponent } from "../preview-adress-with-map-modal/preview-address-with-map-modal.component";
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from "@maskito/kit";
import { MaskitoDirective } from "@maskito/angular";
import { MaskitoOptions } from "@maskito/core";
import {
  AddressObjectGroupUIData,
  AddressObjectUIData,
  createAddressObjectGroups,
  PAYMENT_TYPES,
  PaymentTypeUIData,
  WorkUnitUIData,
} from "../entry-request-form/entry-request-form.utils";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";

type OrganizationContactUIData = {
  ref?: string;
  name: string;
  phoneNumber: string;
};

type OrganizationUIData = {
  id: string;
  ref: string;
  name: string;
};

type AddressUIData = {
  objectRef?: string;
  fullAddress: string;
  coords: number[];
  placeholder: string;
};

const CONTACT_TYPE_PHONE = "phone";

@Component({
  selector: "app-entry-request-form-dispatcher",
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonText,
    IonContent,
    IonModal,
    IonIcon,
    IonInput,
    MachineTypeSelectionModalComponent,
    IonButton,
    IonFooter,
    IonTextarea,
    MachineParamsSelectionModalComponent,
    IonList,
    IonItem,
    IonItemDivider,
    IonLabel,
    HideOnKeyboardDirective,
    DatetimeWheelsModalComponent,
    IonSelect,
    IonSelectOption,
    SearchAddressWithMapModalComponent,
    IonPopover,
    PreviewAddressWithMapModalComponent,
    IonNote,
    MaskitoDirective,
    ReactiveFormsModule,
    IonSpinner,
  ],
  templateUrl: "./entry-request-form-dispatcher.component.html",
  styleUrl: "./entry-request-form-dispatcher.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryRequestFormDispatcherComponent implements OnInit {
  protected selectedMachineType?: MachineTypeX;
  protected isMachineTypeModalOpen = true;

  protected selectedMachineParams: MachineParamValueDto[] = [];
  protected selectedMachineParamsText = "";
  protected isMachineParamsModalOpen = false;

  protected organizations: OrganizationUIData[] = [];
  protected selectedOrganization?: OrganizationUIData;
  protected selectedOrganizationInputVal = "";

  protected organizationContactsAll: OrganizationContactUIData[] = [];
  protected organizationContactsSuggests: OrganizationContactUIData[] = [];
  protected selectedContact?: OrganizationContactUIData;
  protected selectedContactInputVal = "";
  protected isContactListOpen = false;

  protected providersCountTxt = "";

  protected maskitoPhoneNumberMask: MaskitoOptions = {
    mask: PHONE_NUMBER_RU_MASK,
  };
  protected isNewContactModalOpen = false;
  protected newContactFormGroup = this.fb.nonNullable.group({
    firstName: [""],
    lastName: [""],
    phoneNumber: [""],
  });

  protected addressObjects: AddressUIData[] = [];
  protected selectedAddressInput?: AddressUIData;
  protected focusedAddressObjectInputIdx: number = -1;
  protected addressObjectsAll: AddressObjectUIData[] = [];
  protected addressObjectsSuggests: AddressObjectGroupUIData[] = [];
  protected isAddressObjectGroupsEmpty = true;
  protected isAddressModalOpen = false;
  protected previewAddressCoords: number[] = [];

  protected selectedDate = "";
  protected selectedDateFormatted = "Дата обсуждаема";
  protected isDateFlexible = true;
  protected isTimeFlexible = true;
  protected isDateTimeModalOpen = false;

  protected workUnits: WorkUnitUIData[] = [];
  protected selectedMachineUnit?: EntryWorkType;
  protected selectedWorkValue = 1;

  protected price = "";
  protected comment = "";
  protected internalComment = "";

  protected paymentTypes: PaymentTypeUIData[] = PAYMENT_TYPES;
  protected selectedPaymentType: PaymentTypeUIData = this.paymentTypes[0];

  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });
  protected readonly maskPredicate = async (el: unknown) =>
    (el as HTMLIonInputElement).getInputElement();

  protected searchCreateRequestPending = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    private fb: FormBuilder,
    private organizationsByNameQueryGql: EntryRequestFormDispatcherOrganizationsByNameQueryGql,
    private authOrganizationsQueryGql: EntryRequestFormDispatcherAuthOrganizationsQueryGql,
    private authCreateContactMutationGql: EntryRequestFormDispatcherAuthCreateContactMutationGql,
    private authCreateObjectMutationGql: EntryRequestFormDispatcherAuthCreateObjectMutationGql,
    private searchCreateRequestMutationGql: EntryRequestFormDispatcherSearchCreateRequestMutationGql,
    private searchProvidersCountQueryGql: EntryRequestFormDispatcherSearchProvidersCountQueryGql,
  ) {}

  ngOnInit() {
    this.isMachineTypeModalOpen = true;
    this.addOneMoreObject();
  }

  get isFormAddressValid() {
    let addrValid = this.addressObjects.length > 0;
    for (const addr of this.addressObjects) {
      addrValid &&= addr.coords.length > 0;
    }
    return addrValid;
  }

  get isFormValid(): boolean {
    const machineTypeValid = isDefined(this.selectedMachineType);
    return Boolean(
      machineTypeValid &&
        this.isFormAddressValid &&
        this.selectedOrganization &&
        this.selectedContact &&
        this.selectedMachineUnit,
    );
  }

  onMachineTypeInputClick(ev: Event) {
    ev.preventDefault();
    this.isMachineTypeModalOpen = true;
  }

  onMachineTypeModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isMachineTypeModalOpen = false;
    this.getProvidersCount();
  }

  onMachineTypeModalRcSelect(ev: RcSelectEvent) {
    ev.cause.preventDefault();
    this.selectMachineType(ev.data);
    this.selectedMachineType = ev.data;
    this.isMachineTypeModalOpen = false;
  }

  onMachineTypeModalRcClose() {
    this.isMachineTypeModalOpen = false;
    if (!this.selectedMachineType) {
      this.location.back();
    }
  }

  onMachineParamsInputClick(ev: Event) {
    ev.preventDefault();
    this.isMachineParamsModalOpen = true;
  }

  onMachineParamsModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isMachineParamsModalOpen = false;
    this.getProvidersCount();
  }

  onMachineParamsModalRcClose() {
    this.isMachineParamsModalOpen = false;
  }

  onMachineParamsRcSelect(ev: MachineParamsAppSelectEvent) {
    this.isMachineParamsModalOpen = false;
    this.selectedMachineParams = ev.data;
    this.selectedMachineParamsText = ev.txtShort;
  }

  async onOrganizationInputChange(ev: InputCustomEvent) {
    ev.preventDefault();
    this.selectedOrganization = undefined;
    this.selectedContact = undefined;
    this.organizations = [];
    this.selectedOrganizationInputVal = ev.detail.value ?? "";
    if (ev.detail.value) {
      const res = await firstValueFrom(
        this.organizationsByNameQueryGql.fetch({
          input: ev.detail.value,
        }),
      );
      if (this.selectedOrganizationInputVal === ev.detail.value) {
        this.organizations = [];
        for (const org of res.data.organizationsByName) {
          const { id, ref, name } = org;
          if (id && ref && name) {
            this.organizations.push({ id, ref, name });
          }
        }
      }
    }
    this.cdRef.detectChanges();
  }

  async onOrganizationSuggestClick(ev: Event, idx: number) {
    ev.preventDefault();
    const org = this.organizations.at(idx);
    if (org) {
      this.selectedOrganization = org;
      this.selectedOrganizationInputVal = org.name;
      this.organizationContactsAll = [];
      const res = await firstValueFrom(
        this.authOrganizationsQueryGql.fetch({ organizationId: org.id }),
      );
      for (const org of res.data.authOrganizations) {
        for (const cont of org.contacts) {
          const { ref, name, contact } = cont;
          if (contact?.type === CONTACT_TYPE_PHONE) {
            this.organizationContactsAll.push({
              ref,
              name,
              phoneNumber: contact.value,
            });
          }
        }
        for (const addrObj of org?.objects ?? []) {
          if (addrObj?.address?.fullAddress && addrObj?.address?.coordinates) {
            this.addressObjectsAll.push({
              coords: addrObj.address.coordinates.filter(isDefined),
              title: addrObj.title?.trim() ?? "",
              fullAddress: addrObj.address.fullAddress,
              ownerOrganization: {
                id: addrObj.organizationRef ?? "",
                name: addrObj.ownerName ?? "",
              },
            });
          }
        }
      }
      this.selectedContactInputVal = "";
      this.organizations = [];
      this.cdRef.markForCheck();
    }
  }

  onContactInputFocus(ev: Event) {
    ev.preventDefault();
    this.isContactListOpen = true;
    this.organizationContactsSuggests = this.organizationContactsAll;
  }

  onContactInputBlur(ev: Event) {
    ev.preventDefault();
    // add async to be able to handle click by suggest
    setTimeout(() => {
      this.isContactListOpen = false;
      this.cdRef.markForCheck();
    }, 0);
  }

  onContactInputInput(ev: InputCustomEvent) {
    ev.preventDefault();
    this.organizationContactsSuggests = [];
    this.selectedContact = undefined;
    const val = ev.detail.value?.toLowerCase() ?? "";
    for (const cont of this.organizationContactsAll) {
      if (val) {
        if (superSimpleSearch(val, `${cont.name} ${cont.phoneNumber}`)) {
          this.organizationContactsSuggests.push(cont);
        }
      } else {
        this.organizationContactsSuggests.push(cont);
      }
    }
  }

  onNewContactItemClick(ev: Event) {
    ev.preventDefault();
    this.isNewContactModalOpen = true;
  }

  onContactSuggestClick(ev: Event, idx: number) {
    ev.preventDefault();
    const cont = this.organizationContactsSuggests[idx];
    this.selectedContactInputVal = `${cont.name} (${cont.phoneNumber})`;
    this.selectedContact = cont;
    this.isContactListOpen = false;
  }

  onNewContactModalOkClick(ev: Event) {
    ev.preventDefault();
    const formData = this.newContactFormGroup.value;
    let { firstName = "", lastName = "", phoneNumber = "" } = formData;
    if (phoneNumber) {
      phoneNumber = "+" + maskitoParseNumber(phoneNumber);
    }
    const name = `${firstName} ${lastName}`.trim();
    this.selectedContact = { name, phoneNumber };
    this.selectedContactInputVal = `${name} (${phoneNumber})`;
    this.isNewContactModalOpen = false;
  }

  onNewContactModalCancelClick(ev: Event) {
    ev.preventDefault();
    this.isNewContactModalOpen = false;
  }

  onNewContactModalDismiss() {
    this.isNewContactModalOpen = false;
    this.newContactFormGroup.reset();
  }

  onAddressObjectInputFocus(ev: Event, idx: number) {
    ev.preventDefault();
    this.focusedAddressObjectInputIdx = idx;
    this.updateAddressObjectSuggests(this.addressObjectsAll);
  }

  onAddressObjectInputBlur(ev: Event) {
    ev.preventDefault();
    // add async to be able to handle click by suggest
    setTimeout(() => {
      this.focusedAddressObjectInputIdx = -1;
      this.cdRef.markForCheck();
    }, 0);
  }

  onAddressObjectInputInput(ev: InputCustomEvent) {
    ev.preventDefault();
    const { value } = ev.detail;
    const filteredSuggests: AddressObjectUIData[] = [];
    if (value) {
      for (const addrObs of this.addressObjectsAll) {
        if (superSimpleSearch(value, addrObs.fullAddress)) {
          filteredSuggests.push(addrObs);
        }
      }
    } else {
      for (const addrObs of this.addressObjectsAll) {
        filteredSuggests.push(addrObs);
      }
    }
    this.updateAddressObjectSuggests(filteredSuggests);
  }

  onAddAddressObjectBtnClick(ev: Event) {
    ev.preventDefault();
    this.addOneMoreObject();
  }

  onRemoveAddressFieldClick(ev: Event, addressInput: AddressUIData) {
    ev.preventDefault();
    const idx = this.addressObjects.indexOf(addressInput);
    if (idx > -1) {
      this.addressObjects.splice(idx, 1);
      this.updateAddressPlaceholders();
    }
  }

  onNewAddressObjectItemClick(ev: Event, addressInput: AddressUIData) {
    ev.preventDefault();
    this.selectedAddressInput = addressInput;
    this.isAddressModalOpen = true;
  }

  onAddressObjectSuggestClick(
    ev: Event,
    addrObj: AddressObjectUIData,
    newAddressInput: AddressUIData,
  ) {
    ev.preventDefault();
    newAddressInput.fullAddress = addrObj.fullAddress;
    newAddressInput.coords = addrObj.coords;
    this.focusedAddressObjectInputIdx = -1;
    this.getProvidersCount();
  }

  onSearchAddressModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isAddressModalOpen = false;
    this.getProvidersCount();
  }

  onSearchAddressModalClose() {
    setTimeout(() => {
      // run in new macrotask, because stupid bug with changeDetection
      this.isAddressModalOpen = false;
      this.cdRef.markForCheck();
    });
  }

  onSearchAddressModalSelect(ev: SearchAddressSelectEvent) {
    if (this.selectedAddressInput) {
      this.selectedAddressInput.fullAddress = ev.fullAddress;
      this.selectedAddressInput.coords = ev.coords;
    }
    this.isAddressModalOpen = false;
  }

  onPreviewAddressIconClick(ev: Event, coords: number[]) {
    ev.preventDefault();
    this.previewAddressCoords = coords;
  }

  onPreviewAddressModalDismiss(ev: Event) {
    ev.preventDefault();
    this.previewAddressCoords = [];
  }

  onPreviewAddressModalCancel() {
    this.previewAddressCoords = [];
  }

  onDateTimeInputClick(ev: Event) {
    ev.preventDefault();
    this.isDateTimeModalOpen = true;
  }

  onDateTimeModalDismiss(ev: Event) {
    ev.preventDefault();
    this.isDateTimeModalOpen = false;
  }

  onRcDateTimeChange(ev: RcDateTimeChangeEvent) {
    this.selectedDate = ev.datetime;
    if (ev.date && ev.time) {
      this.selectedDateFormatted = dayjs(ev.datetime).format("D MMMM YYYY, HH:mm");
      this.isDateFlexible = false;
      this.isTimeFlexible = false;
    } else if (ev.date) {
      this.selectedDateFormatted = dayjs(ev.datetime).format("D MMMM YYYY");
      this.isDateFlexible = false;
      this.isTimeFlexible = true;
    } else {
      this.selectedDateFormatted = "Дата обсуждаема";
      this.isDateFlexible = true;
      this.isTimeFlexible = true;
    }
    this.isDateTimeModalOpen = false;
  }

  onWorkUnitSelectChange(ev: CustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.selectedMachineUnit = ev.detail.value;
    }
  }

  onWorkValueInputChange(ev: InputCustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.selectedWorkValue = parseFloat(ev.detail.value);
    }
  }

  onPaymentSelectChange(ev: SelectCustomEvent) {
    ev.preventDefault();
    for (const pt of this.paymentTypes) {
      if (pt.value === ev.detail.value) {
        this.selectedPaymentType = pt;
        break;
      }
    }
  }

  onPriceInputChange(ev: InputCustomEvent) {
    ev.preventDefault();
    if (isDefined(ev.detail.value)) {
      this.price = ev.detail.value;
    }
  }

  onCommentInputChange(ev: TextareaCustomEvent) {
    ev.preventDefault();
    this.comment = ev.detail.value ?? "";
  }

  onInternalCommentInputChange(ev: TextareaCustomEvent) {
    ev.preventDefault();
    this.internalComment = ev.detail.value ?? "";
  }

  async onCreateEntryBtnClick(ev: Event) {
    ev.preventDefault();

    if (
      !this.selectedOrganization ||
      !this.selectedContact ||
      !this.selectedMachineType ||
      !this.selectedMachineUnit ||
      this.searchCreateRequestPending
    ) {
      return;
    }
    this.searchCreateRequestPending = true;
    this.cdRef.markForCheck();
    let contactRef = this.selectedContact.ref;
    if (!contactRef) {
      const res = await firstValueFrom(
        this.authCreateContactMutationGql.mutate({
          contact: {
            name: this.selectedContact.name,
            type: CONTACT_TYPE_PHONE,
            value: this.selectedContact.phoneNumber,
            organizationRef: this.selectedOrganization.ref,
          },
        }),
      );
      assertIsDefined(res.data?.authCreateContact);
      contactRef = res.data.authCreateContact.ref;
    }
    const searchRequestObjects: CreateEntryInput["objects"] = [];
    for (const a of this.addressObjects) {
      if (a.objectRef) {
        searchRequestObjects.push({ objectRef: a.objectRef });
      } else {
        const res = await firstValueFrom(
          this.authCreateObjectMutationGql.mutate({
            fullAddress: a.fullAddress,
            coordinates: a.coords,
            onDuplicate: "returnExisting",
            ownerOrganizationId: this.selectedOrganization.id,
          }),
        );
        if (res.data?.authCreateObject) {
          searchRequestObjects.push({ objectRef: res.data.authCreateObject.ref });
        }
      }
    }
    let dateFormat: CreateEntryInput["dateType"] = "datetime";
    if (this.isTimeFlexible) {
      dateFormat = "date";
    }
    if (this.isDateFlexible) {
      dateFormat = "none";
    }
    const searchRequestData: CreateEntryInput = {
      machineTypeRef: this.selectedMachineType.ref,
      objects: searchRequestObjects,
      dateType: dateFormat,
      workType: this.selectedMachineUnit,
      paymentType: this.selectedPaymentType.value,
      workValue: this.selectedWorkValue,
      date: this.selectedDate,
      comment: this.comment,
      machineParams: this.selectedMachineParams,
      price: maskitoParseNumber(this.price),
      dispatcherData: {
        internalComment: this.internalComment,
        customer: {
          organizationRef: this.selectedOrganization.ref,
          contacts: [contactRef],
        },
      },
    };
    const res = await firstValueFrom(
      this.searchCreateRequestMutationGql.mutate({
        data: searchRequestData,
      }),
    );
    // todo: handle errors
    if (res.data?.searchCreateRequest.id) {
      if (
        res.data?.searchCreateRequest.availableActions.includes("SEE_ENTRY_CATALOG_AND_RESPONSES")
      ) {
        const entryId = res.data?.searchCreateRequest.id;
        const queryParams = {
          entryId,
        };
        await this.router.navigate(["/entry-details-machine"], { queryParams, replaceUrl: true });
      } else {
        await this.router.navigate(["/home/entries"], { replaceUrl: true });
      }
    }
    this.searchCreateRequestPending = false;
    this.cdRef.markForCheck();
  }

  private selectMachineType(mt: RcSelectEvent["data"]) {
    this.selectedMachineType = mt;
    this.selectedMachineParams = [];
    this.selectedMachineParamsText = "";
    this.workUnits = [];
    for (const unit of this.selectedMachineType?.unit ?? []) {
      if (unit === "shift") {
        this.workUnits.push({ name: "shift", ruName: "Количество смен" });
      }
      if (unit === "trip") {
        this.workUnits.push({ name: "trip", ruName: "Количество рейсов" });
      }
      if (unit === "cbm") {
        this.workUnits.push({ name: "cbm", ruName: "Количество кубов" });
      }
    }
    this.selectedMachineUnit = this.workUnits[0].name;
  }

  private addOneMoreObject() {
    this.addressObjects.push({
      fullAddress: "",
      coords: [],
      placeholder: "",
    });
    this.updateAddressPlaceholders();
  }

  private updateAddressPlaceholders() {
    for (let i = 0; i < this.addressObjects.length; i += 1) {
      if (i === 0) {
        this.addressObjects[i].placeholder = "Введите адрес подачи";
      } else {
        this.addressObjects[i].placeholder = `Введите ${i + 1}-й адрес`;
      }
    }
  }

  private updateAddressObjectSuggests(suggests: AddressObjectUIData[]) {
    assertIsDefined(this.selectedOrganization);
    this.addressObjectsSuggests = createAddressObjectGroups(suggests, this.selectedOrganization.id);
    this.isAddressObjectGroupsEmpty = true;
    for (const g of this.addressObjectsSuggests) {
      if (g.address.length) {
        this.isAddressObjectGroupsEmpty = false;
        break;
      }
    }
  }

  private async getProvidersCount() {
    this.providersCountTxt = "";
    const coords = this.addressObjects[0]?.coords ?? [];
    if (!this.selectedMachineType || !coords.length) {
      return;
    }
    const machineTypeId: EntryRequestFormDispatcherSearchProvidersCountQueryVariables["machineTypeId"] =
      this.selectedMachineType.id;
    const machineParams: EntryRequestFormDispatcherSearchProvidersCountQueryVariables["machineParams"] =
      [];
    for (const param of this.selectedMachineParams) {
      const { paramName, boolValue, rangeValue, selectNames } = param;
      if (paramName) {
        machineParams.push({ paramName, boolValue, rangeValue, selectNames });
      }
    }
    const addressCoords: EntryRequestFormDispatcherSearchProvidersCountQueryVariables["addressCoords"] =
      coords;
    const res = await firstValueFrom(
      this.searchProvidersCountQueryGql.fetch({
        machineTypeId,
        addressCoords,
        machineParams,
      }),
    );
    const { searchProvidersCount } = res.data;
    if (isDefined(searchProvidersCount)) {
      this.providersCountTxt =
        searchProvidersCount +
        " " +
        declension(searchProvidersCount, "поставщик", "поставщика", "поставщиков");
    }
    this.cdRef.markForCheck();
  }
}
