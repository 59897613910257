import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfileMachineFormMachineTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileMachineFormMachineTypesQuery = { __typename?: 'Query', machineTypes?: Array<{ __typename?: 'MachineType', id: string, uuid: string, nameRU: string, unit?: Array<string | null> | null, params: Array<{ __typename?: 'MachineTypeParams', ids?: any | null, name: string, nameRU: string, isRequired?: boolean | null, isEquipment?: boolean | null, type: Types.EnumMachineTypeParamsType, settings?: any | null, unit?: string | null, uuid: string, paramPurpose?: { __typename?: 'MachineTypeParamsParamPurpose', entry?: boolean | null, main?: boolean | null, usual?: boolean | null } | null } | null> } | null> | null };

export type ProfileMachineFormAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileMachineFormAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', organizations: Array<{ __typename?: 'Organization', uuid: string } | null> } | null };

export type ProfileMachineFormMachineQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID']['input'];
}>;


export type ProfileMachineFormMachineQuery = { __typename?: 'Query', machine?: { __typename?: 'Machine', id: string, ref: string, uuid: string, model: string, type: string, year?: number | null, reg?: string | null, description?: string | null, pricePerMinOrder?: number | null, pricePerWorkShift?: number | null, params: Array<{ __typename?: 'MachineParams', value?: any | null, valueBoolean?: boolean | null, valueRange?: number | null, valueSelect?: string | null, refUuid: string, spec: { __typename?: 'MachineTypeParams', name: string, nameRU: string, type: Types.EnumMachineTypeParamsType, unit?: string | null, isEquipment?: boolean | null, ids?: any | null } }>, photos: Array<{ __typename?: 'MachinePhotos', src: string }> } | null };

export type ProfileMachineFormSearchDeleteMachineMutationVariables = Types.Exact<{
  machineRef: Types.Scalars['String']['input'];
}>;


export type ProfileMachineFormSearchDeleteMachineMutation = { __typename?: 'Mutation', searchDeleteMachine: { __typename?: 'OkResult', ok?: boolean | null } };

export const ProfileMachineFormMachineTypesDocument = gql`
    query ProfileMachineFormMachineTypes {
  machineTypes(enabled: true) {
    id
    uuid
    nameRU
    unit
    params {
      ids
      name
      nameRU
      isRequired
      isEquipment
      type
      paramPurpose {
        entry
        main
        usual
      }
      settings
      unit
      uuid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineFormMachineTypesQueryGql extends Apollo.Query<ProfileMachineFormMachineTypesQuery, ProfileMachineFormMachineTypesQueryVariables> {
    override document = ProfileMachineFormMachineTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileMachineFormAuthCurrentUserDocument = gql`
    query ProfileMachineFormAuthCurrentUser {
  authCurrentUser {
    organizations {
      uuid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineFormAuthCurrentUserQueryGql extends Apollo.Query<ProfileMachineFormAuthCurrentUserQuery, ProfileMachineFormAuthCurrentUserQueryVariables> {
    override document = ProfileMachineFormAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileMachineFormMachineDocument = gql`
    query ProfileMachineFormMachine($machineId: ID!) {
  machine(id: $machineId) {
    id
    ref
    uuid
    model
    type
    year
    reg
    description
    params {
      spec {
        name
        nameRU
        type
        unit
        isEquipment
        ids
      }
      value
      valueBoolean
      valueRange
      valueSelect
      refUuid
    }
    photos {
      src
    }
    pricePerMinOrder
    pricePerWorkShift
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineFormMachineQueryGql extends Apollo.Query<ProfileMachineFormMachineQuery, ProfileMachineFormMachineQueryVariables> {
    override document = ProfileMachineFormMachineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileMachineFormSearchDeleteMachineDocument = gql`
    mutation ProfileMachineFormSearchDeleteMachine($machineRef: String!) {
  searchDeleteMachine(machineRef: $machineRef) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineFormSearchDeleteMachineMutationGql extends Apollo.Mutation<ProfileMachineFormSearchDeleteMachineMutation, ProfileMachineFormSearchDeleteMachineMutationVariables> {
    override document = ProfileMachineFormSearchDeleteMachineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }