import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfileMachineOwnershipMachineQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID']['input'];
}>;


export type ProfileMachineOwnershipMachineQuery = { __typename?: 'Query', machine?: { __typename?: 'Machine', id: string, uuid: string, model: string, year?: number | null, reg?: string | null, description?: string | null, pricePerMinOrder?: number | null, pricePerWorkShift?: number | null, verificationStatus?: Types.EnumMachineVerificationStatus | null, photos: Array<{ __typename?: 'MachinePhotos', src: string }>, organization?: { __typename?: 'Organization', uuid: string } | null } | null };

export const ProfileMachineOwnershipMachineDocument = gql`
    query ProfileMachineOwnershipMachine($machineId: ID!) {
  machine(id: $machineId) {
    id
    uuid
    model
    year
    reg
    description
    photos {
      src
    }
    pricePerMinOrder
    pricePerWorkShift
    organization {
      uuid
    }
    verificationStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineOwnershipMachineQueryGql extends Apollo.Query<ProfileMachineOwnershipMachineQuery, ProfileMachineOwnershipMachineQueryVariables> {
    override document = ProfileMachineOwnershipMachineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }