import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { IonIcon, IonicSlides, IonImg, IonText } from "@ionic/angular/standalone";
import { MachinePhotoSliderMachineQuery } from "./machine-photo-slider.component.gql-gen";

@Component({
  selector: "app-machine-photo-slider",
  standalone: true,
  imports: [IonImg, IonText, IonIcon],
  templateUrl: "./machine-photo-slider.component.html",
  styleUrl: "./machine-photo-slider.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MachinePhotoSliderComponent implements OnChanges {
  @Input({ required: true }) machine?: MachinePhotoSliderMachineQuery["machine"];

  @ViewChild("photoSwiperContainer") photoSwiperContainer: ElementRef<HTMLElement> | undefined;

  protected photoUrls: string[] = [];
  protected swiperModules = [IonicSlides];

  ngOnChanges(changes: SimpleChanges) {
    if ("machine" in changes) {
      this.photoUrls = [];
      for (const photo of this.machine?.photos ?? []) {
        this.photoUrls.push(photo.src);
      }
    }
  }
}
