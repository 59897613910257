import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonText,
  IonToolbar,
} from "@ionic/angular/standalone";
import { ActivatedRoute, RouterLink } from "@angular/router";

export type ErrorInterceptorQueryParams = {
  traceId?: string;
  kind?: "unauthorized";
};

@Component({
  selector: "app-error-handled",
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonContent,
    IonIcon,
    IonText,
    IonButton,
    RouterLink,
    IonFooter,
  ],
  templateUrl: "./error-handled.component.html",
  styleUrl: "./error-handled.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorHandledComponent implements OnInit {
  protected traceId?: ErrorInterceptorQueryParams["traceId"];
  protected kind?: ErrorInterceptorQueryParams["kind"];

  private activatedRoute = inject(ActivatedRoute);

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams as ErrorInterceptorQueryParams;

    this.traceId = queryParams.traceId;
    this.kind = queryParams.kind;
  }
}
