<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" [formGroup]="profileFormGroup">
  <div class="profilePic" id="registration-profile-get-picture">
    <ion-icon name="camera-outline"></ion-icon>
    <img [src]="registrationData.avatar" alt="" />
  </div>
  <ion-action-sheet
    [buttons]="selectAvatarButtons"
    trigger="registration-profile-get-picture"
  ></ion-action-sheet>
  <ion-list>
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'firstName'" label="Имя" labelPlacement="floating"></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input
        [formControlName]="'lastName'"
        label="Фамилия"
        labelPlacement="floating"
      ></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'email'" label="Email" labelPlacement="floating"></ion-input>
    </ion-item>
    <ion-radio-group [formControl]="profileType">
      <ion-item lines="none" class="ion-margin-top ion-no-padding">
        <ion-radio value="company" labelPlacement="end" justify="start">
          Сотрудник компании/ИП
        </ion-radio>
      </ion-item>
      <ion-item lines="none" class="ion-no-padding">
        <ion-radio value="person" labelPlacement="end" justify="start">Частное лицо</ion-radio>
        <br />
      </ion-item>
    </ion-radio-group>
  </ion-list>

  @if (profileType.value === "person") {
    <ion-checkbox class="ion-margin-top" labelPlacement="end" [formControl]="checkbox">
      Я даю свое
      <a
        (click)="onDocumentLinkClick($event)"
        href="https://minio.rental-club.ru/public/rc/docs/consent_to_personal_data_proccessing.docx"
        target="_blank"
      >
        Согласие на обработку
        <br />
        персональных данных
      </a>
      <span>и согласен с</span>
      <br />
      <a
        (click)="onDocumentLinkClick($event)"
        href="https://minio.rental-club.ru/public/rc/docs/personal_data_policy.docx"
        target="_blank"
      >
        Политикой обработки персональных данных
      </a>
    </ion-checkbox>
    <ion-text class="checkboxText">
      Нажимая кнопку "Зарегистрироваться" я принимаю условия
      <a href="https://minio.rental-club.ru/public/rc/docs/user_agreement.docx" target="_blank">
        Пользовательского соглашения
      </a>
    </ion-text>
  }
</ion-content>
<ion-footer class="ion-no-border">
  @if (profileType.value === "company") {
    <ion-button
      [disabled]="profileFormGroup.invalid"
      (click)="onNextStepCompanyBtnClick($event)"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      <ion-text>Далее</ion-text>
    </ion-button>
  }
  @if (profileType.value === "person") {
    <ion-button
      (click)="onRegisterPersonBtnClick($event)"
      [disabled]="profileFormGroup.invalid || checkbox.invalid"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      <ion-text>Зарегистрироваться</ion-text>
    </ion-button>
  }
</ion-footer>
