<ion-app>
  <app-cancel-modal
    [entry]="entry"
    [proposeForSign]="proposeForSign"
    [hasPropose]="hasPropose"
    (closeAndRefresh)="closeAndRefresh()"
  ></app-cancel-modal>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button [defaultHref]="'/home/entries'"></ion-back-button>
      </ion-buttons>
      <ion-title>Заявка</ion-title>
      <ion-button class="close-entry" fill="clear" slot="end" (click)="closeEntry()">
        Завершить
      </ion-button>
      @if (loading) {
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      }
    </ion-toolbar>
  </ion-header>
  @if (entry) {
    <div class="summary" (click)="openEntryInfo()">
      <div class="summary-wrap">
        <div class="summary-info">
          {{ entryCategoryName }} · {{ startDateTxt || "Дата обсуждаема" }} · {{ entryAddress }}
        </div>
        <div class="time-left">
          {{ entryCreatedTimer }}
        </div>
      </div>
      <ion-icon name="caret-forward-sharp"></ion-icon>
    </div>
    <ion-segment [swipeGesture]="true" value="proposes" (ionChange)="onSegmentChange($event)">
      <ion-segment-button value="proposes">
        <ion-label>Отклики {{ entry.proposes?.length || "" }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="providers">
        <ion-label>
          Каталог {{ entry.searchResult?.paginationInfo?.totalResultsCount || "" }}
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  }
  <ion-content scroll-events="true">
    <ion-refresher (ionRefresh)="onProviderListRefresh($event)" slot="fixed" [pullMin]="120">
      <ion-refresher-content
        pullingIcon="chevron-down-circle-outline"
        refreshingSpinner="circles"
        refreshingText="Обновляем..."
      ></ion-refresher-content>
    </ion-refresher>
    @if (entry) {
      <div class="segment-content">
        @switch (currentPage) {
          @case ("proposes") {
            <div class="proposes-wrapper">
              <div class="proposes-content">
                @if (!entry.proposes?.length) {
                  <div class="text-wrapper">
                    @if (searchStarted) {
                      <ion-progress-bar [value]="progress"></ion-progress-bar>
                    }
                    @if (!searchStarted) {
                      <p>Мы сформировали каталог под вашу заявку с подходящей техникой.</p>
                      <p>Запустите поиск или выберете исполнителя в ручную в каталоге.</p>
                    }
                    @if (searchStarted) {
                      <p>
                        Ждем предложения... Обычно исполнители откликаются в течение 10-20 минут, но
                        бывает и дольше.
                      </p>
                    }
                  </div>
                }
                @for (propose of sortedProposes(); track trackByForProposes($index, propose)) {
                  <app-propose-card
                    [entry]="entry"
                    [propose]="propose"
                    (refresh)="refreshPage()"
                    (showCancelModal)="onShowCancelModal($event, true)"
                  ></app-propose-card>
                }
              </div>
            </div>
          }
          @case ("providers") {
            <div class="providers-wrapper">
              <app-providers-list
                [entry]="entry"
                (showCancelModal)="onShowCancelModal($event)"
              ></app-providers-list>
            </div>
          }
        }
      </div>
      @if (currentPage === "proposes" && !searchStarted && !entry.proposes?.length) {
        <ion-buttons slot="fixed" class="btn-search">
          <ion-button expand="block" (click)="onSearchStart()">Запустить поиск</ion-button>
        </ion-buttons>
      }
    }
  </ion-content>
</ion-app>
