import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  IonActionSheet,
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
  LoadingController,
  ToastController,
} from "@ionic/angular/standalone";
import { assertIsDefined, phoneFormatUniversal, phoneNumberValidator } from "../utils";
import {
  ProfilePersonEditAuthCurrentUserQueryGql,
  ProfilePersonEditAuthDeleteUserMutationGql,
} from "./profile-person-edit.gql-gen";
import { firstValueFrom } from "rxjs";
import { Api } from "../apollo/api";
import { Router } from "@angular/router";

@Component({
  selector: "app-profile-person-edit",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    ReactiveFormsModule,
    IonButton,
    IonFooter,
    IonAlert,
    IonActionSheet,
  ],
  templateUrl: "./profile-person-edit.component.html",
  styleUrl: "./profile-person-edit.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePersonEditComponent implements OnInit {
  profileEditFormGroup = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    phoneNumber: new FormControl("", [Validators.required, phoneNumberValidator()]),
    email: new FormControl("", [Validators.required, Validators.email]),
    avatarUrl: new FormControl("", []),
  });
  selectAvatarButtons = [
    {
      text: "C камеры",
      handler: () => {
        const msg = { from: "mobappV2", cmd: "getPictureFromCamera" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      },
    },
    {
      text: "Из галереи",
      handler: () => {
        const msg = { from: "mobappV2", cmd: "getPictureFromGallery" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      },
    },
    {
      text: "Удалить фото",
      role: "destructive",
      handler: () => {
        this.setPersonAvatar("");
      },
    },
  ];
  deleteProfileButtons = [
    {
      text: "Отменить",
      role: "cancel",
    },
    {
      text: "Подтвердить",
      role: "destructive",
      handler: async () => {
        const loading = await this.loadingCtrl.create({
          message: "Удаляем аккаунт...",
        });
        await loading.present();
        const showToast = async (msg: string) => {
          const toast = await this.toastController.create({
            message: msg,
            position: "middle",
            duration: 3000,
          });
          await toast.present();
        };
        this.authDeleteUserMutationGql.mutate({ userId: this.userId }).subscribe({
          next: async (res) => {
            await loading.dismiss();
            if (res.data?.authDeleteUser.ok) {
              await this.router.navigate(["/sign-in"]);
            } else {
              await showToast("Произошла ошибка, наши сервера сломались");
            }
          },
          error: async (err) => {
            await loading.dismiss();
            const cannotDeleteLastUserErr = err.graphQLErrors.find(
              (err: any) => err.message === "cannot_delete_last_user",
            );
            if (cannotDeleteLastUserErr) {
              await showToast("Запрещено удалять последнего пользователя из текущей организации");
            } else {
              throw err;
            }
          },
        });
      },
    },
  ];

  private organizationUuid = "";
  private userId = "";
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  constructor(
    private api: Api,
    private cdRef: ChangeDetectorRef,
    private authCurrentUserQueryGql: ProfilePersonEditAuthCurrentUserQueryGql,
    private authDeleteUserMutationGql: ProfilePersonEditAuthDeleteUserMutationGql,
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private router: Router,
    private location: Location,
  ) {}

  async ngOnInit(): Promise<void> {
    const phoneNumberCtrl = this.profileEditFormGroup.get("phoneNumber");
    if (phoneNumberCtrl) {
      phoneNumberCtrl.valueChanges.forEach((number) => {
        phoneNumberCtrl.setValue(phoneFormatUniversal(number ?? ""), { emitEvent: false });
      });
    }

    const res = await firstValueFrom(this.authCurrentUserQueryGql.fetch());
    const { authCurrentUser } = res.data;
    assertIsDefined(authCurrentUser);
    this.userId = authCurrentUser.id;
    this.organizationUuid = authCurrentUser.organizations[0]?.uuid ?? "";
    this.profileEditFormGroup.patchValue({
      firstName: authCurrentUser.firstname,
      lastName: authCurrentUser.lastname,
      phoneNumber: authCurrentUser.phone,
      email: authCurrentUser.email,
      avatarUrl: authCurrentUser.avatar,
    });
    this.profileEditFormGroup.controls.phoneNumber.disable();
    this.cdRef.markForCheck();
  }

  @HostListener("window:message", ["$event"])
  async onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd === "getPictureResult") {
      this.setPersonAvatar(data.dataUrl);
    }
  }

  async onSavePersonProfileClick(ev: Event) {
    ev.preventDefault();
    const { firstName, lastName, email } = this.profileEditFormGroup.value;
    if (!firstName || !lastName || !email) {
      return;
    }
    let { avatarUrl } = this.profileEditFormGroup.value;
    if (avatarUrl && avatarUrl.startsWith("data:")) {
      const [dataType] = avatarUrl.split(";");
      const [, mimeType] = dataType.split(":");
      const [, fileType] = mimeType.split("/");
      const res = await fetch(avatarUrl);
      const blob = await res.blob();
      const file = new File([blob], `avatar.${fileType}`, { type: mimeType });
      const fileRes = await this.api.savePersonAvatar(file, this.organizationUuid);
      this.profileEditFormGroup.patchValue({
        avatarUrl: fileRes.url,
      });
      avatarUrl = fileRes.url;
    }
    await this.api.savePersonProfile({
      firstname: firstName,
      lastname: lastName,
      email: email,
      avatar: avatarUrl ?? "",
    });
    const oktoast = await this.toastController.create({
      message: "Данные обновлены",
      position: "middle",
      duration: 3000,
    });
    await oktoast.present();
    this.location.back();
  }

  private setPersonAvatar(avatarUrl: string) {
    this.profileEditFormGroup.patchValue({ avatarUrl });
    this.profileEditFormGroup.markAsDirty();
    this.cdRef.markForCheck();
  }
}
