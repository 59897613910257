import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryDetailsMachineSearchEntryStartLookupMutationVariables = Types.Exact<{
  entryRef: Types.Scalars['String']['input'];
}>;


export type EntryDetailsMachineSearchEntryStartLookupMutation = { __typename?: 'Mutation', searchEntryStartLookup: { __typename?: 'OkResult', ok?: boolean | null } };

export const EntryDetailsMachineSearchEntryStartLookupDocument = gql`
    mutation EntryDetailsMachineSearchEntryStartLookup($entryRef: String!) {
  searchEntryStartLookup(entryRef: $entryRef) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryDetailsMachineSearchEntryStartLookupMutationGql extends Apollo.Mutation<EntryDetailsMachineSearchEntryStartLookupMutation, EntryDetailsMachineSearchEntryStartLookupMutationVariables> {
    override document = EntryDetailsMachineSearchEntryStartLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }