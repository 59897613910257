import { ChangeDetectionStrategy, Component, HostListener, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { addIcons } from "ionicons";
import {
  addOutline,
  addCircle,
  addCircleOutline,
  arrowBackOutline,
  briefcaseOutline,
  businessOutline,
  business,
  calendarOutline,
  calendar,
  call,
  camera,
  cameraOutline,
  caretDownSharp,
  caretForwardSharp,
  checkmarkCircle,
  chatboxEllipses,
  chevronBack,
  chevronBackOutline,
  chevronDownCircleOutline,
  chevronForwardOutline,
  close,
  closeCircle,
  ellipse,
  helpBuoyOutline,
  informationCircleOutline,
  location,
  locationSharp,
  pencil,
  peopleOutline,
  personOutline,
  personCircle,
  pin,
  searchOutline,
  square,
  time,
  timeOutline,
  triangle,
  trashOutline,
  warning,
  warningOutline,
  checkmarkCircleOutline,
  cash,
} from "ionicons/icons";
import { IonApp, IonRouterOutlet, ModalController } from "@ionic/angular/standalone";
import { Location } from "@angular/common";
import { EntryMatchInternalDetailsQueryParams } from "./entry-match-internal-details/entry-match-internal-details.component";
import { EntryMatchParsedDetailsQueryParams } from "./entry-match-parsed-details/entry-match-parsed-details.component";
import { ApmService } from "@elastic/apm-rum-angular";
import { environment } from "../environments/environment";
import { appInfo } from "../app-info";
import { IffyStorageService } from "./iffy-storage.service";

const pushType = {
  NewEntry: "1",
  NewPropose: "2",
  SignedCustomer: "3",
  CanceledPropose: "4",
  NewCall: "5",
  NewCallForProvider: "6",
  SupportGroup: "7",
  MachineApprove: "8",
  CompanyApprove: "9",
  Update: "10",
  Claim: "11",
  ClaimCompany: "12",
  NewSupportMessage: "13",
  NewMessage: "14",
  SignedProvider: "15",
  RejectedCustomer: "16",
  ProposeOutbid: "17",
  MachineAvailabilityExpired: "18",
  EntryCancelled: "19",
  EntrySignedWithOtherSupplier: "20",
};

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent implements OnInit {
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin
  private mobappV1State = {
    urlsIn: false,
    tokenIn: false,
    readyOut: false,
  };
  private iffyStorage = inject(IffyStorageService);

  constructor(
    private router: Router,
    private loc: Location,
    private modalCtrl: ModalController,
    service: ApmService,
  ) {
    addIcons({
      addOutline,
      addCircle,
      addCircleOutline,
      arrowBackOutline,
      businessOutline,
      business,
      briefcaseOutline,
      calendarOutline,
      calendar,
      call,
      camera,
      cash,
      cameraOutline,
      caretForwardSharp,
      caretDownSharp,
      chatboxEllipses,
      chevronBack,
      chevronBackOutline,
      chevronForwardOutline,
      chevronDownCircleOutline,
      checkmarkCircle,
      checkmarkCircleOutline,
      close,
      closeCircle,
      ellipse,
      helpBuoyOutline,
      informationCircleOutline,
      locationSharp,
      location,
      pencil,
      peopleOutline,
      personOutline,
      personCircle,
      pin,
      searchOutline,
      square,
      time,
      timeOutline,
      triangle,
      trashOutline,
      warning,
      warningOutline,
    });
    if (environment.elasticApmServerUrl) {
      service.init({
        serviceName: appInfo.name,
        serviceVersion: appInfo.version,
        environment: environment.elasticApmEnvironment,
        serverUrl: environment.elasticApmServerUrl,
        serverUrlPrefix: "/W8DaUx280ciI4032",
        apiVersion: 3,
        distributedTracingOrigins: ["https://api.rental-club.ru", "https://api.dev.rclb.ru"],
      });
    }
  }

  async ngOnInit() {
    const msg1 = { from: "mobappV2", cmd: "getUrls" };
    this.maybeMobappV1Window.postMessage(msg1, this.mobappV1Origin);
    const msg2 = { from: "mobappV2", cmd: "getAuthToken" };
    this.maybeMobappV1Window.postMessage(msg2, this.mobappV1Origin);
    this.iffyStorage.setItem("signInRedirectUrl", "");
  }

  @HostListener("window:message", ["$event"])
  async onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd === "eventBackButton") {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await modal.dismiss();
      } else {
        this.loc.back();
      }
    }
    if (data.cmd === "eventPushNotificationActionPerformed") {
      const { notification } = data.action;
      const payload = notification.data;
      if (payload.type === pushType.NewEntry) {
        // const entryUuid = payload.entryId; // yes, in entryId actually uuid, wtf?!
        const entryId = payload.entry_id;
        if (payload.entryType === "internal") {
          const queryParams: EntryMatchInternalDetailsQueryParams = {
            entryId,
          };
          await this.router.navigate(["/entry-match-internal-details"], { queryParams });
        }
        if (payload.entryType === "parser") {
          const queryParams: EntryMatchParsedDetailsQueryParams = {
            entryId,
          };
          await this.router.navigate(["/entry-match-parsed-details"], { queryParams });
        }
      }

      if (payload.type === pushType.EntrySignedWithOtherSupplier) {
        await this.router.navigate(["/home/matches"]);
      }

      if (payload.type === pushType.ProposeOutbid) {
        const entryId = payload.entry_id;
        const queryParams: EntryMatchInternalDetailsQueryParams = {
          entryId,
          segment: "responses",
        };
        await this.router.navigate(["/entry-match-internal-details"], { queryParams });
      }

      if (payload.type === pushType.EntryCancelled) {
        await this.router.navigate(["/home/entries"]);
      }

      if (payload.type === pushType.NewPropose) {
        const entryUuid = payload.entryId; // yes, in entryId actually uuid, wtf?!
        const queryParams = {
          entryId: entryUuid,
        };
        await this.router.navigate(["/entry-details-machine"], { queryParams });
      }

      if (payload.type === pushType.SignedCustomer) {
        const entryUuid = payload.entryId; // yes, in entryId actually uuid, wtf?!
        const queryParams = {
          entryId: entryUuid,
          viewAs: "supplier",
        };
        await this.router.navigate(["/entry-sign"], { queryParams });
      }

      if (payload.type === pushType.CanceledPropose) {
        const entryId = payload.entry_id;
        const queryParams: EntryMatchInternalDetailsQueryParams = {
          entryId,
        };
        await this.router.navigate(["/entry-match-internal-details"], { queryParams });
      }

      if (payload.type === pushType.SignedProvider) {
        const entryUuid = payload.entryId; // yes, in entryId actually uuid, wtf?!
        const queryParams = {
          entryId: entryUuid,
          viewAs: "customer",
        };
        await this.router.navigate(["/entry-sign"], { queryParams });
      }

      if (payload.type === pushType.SupportGroup) {
        // const alert = await this.alertCtrl.create({
        //   title: notifi.title,
        //   message: notifi.message,
        //   buttons: [
        //     {
        //       text: "Позже",
        //       handler: () => {},
        //     },
        //     {
        //       text: "Добавиться",
        //       handler: () => {
        //         Browser.open({ url: "https://t.me/joinchat/AAAAAAsQQN_YKZyQoGsO2w" });
        //       },
        //     },
        //   ],
        // });
        // await alert.present();
      }

      if (payload.type === pushType.MachineApprove) {
        await this.router.navigate(["/profile-machine-viewing"]);
        // todo: add machineUuid to QP
      }

      if (payload.type === pushType.CompanyApprove) {
        await this.router.navigate(["/home/profile"]);
      }

      if (payload.type === pushType.Update) {
        //todo: handle this push to versionInfo
        await this.router.navigate(["???"]);
      }

      if (payload.type === pushType.NewSupportMessage) {
        await this.router.navigate(["/home/support"]);
      }

      if (payload.type === pushType.MachineAvailabilityExpired) {
        await this.router.navigate(["/profile-machine-viewing"]);
        // todo: add machineUuid to QP
      }
    }

    if (data.cmd === "getUrlsResult") {
      const { backendUrl, frontendUrl } = data;
      this.iffyStorage.setItem("backendUrl", backendUrl ?? "");
      this.iffyStorage.setItem("frontendUrl", frontendUrl ?? "");
      this.mobappV1State.urlsIn = true;
    }
    if (data.cmd === "getAuthTokenResult") {
      this.iffyStorage.setItem("authToken", data.authToken ?? "");
      this.mobappV1State.tokenIn = true;
    }

    {
      const { urlsIn, tokenIn, readyOut } = this.mobappV1State;
      if (!readyOut && urlsIn && tokenIn) {
        const msg = { from: "mobappV2", cmd: "eventReady" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
        this.mobappV1State.readyOut = true;
      }
    }
  }
}
