import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  ToastController,
} from "@ionic/angular/standalone";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  AuthCreateTokenMutationGql,
  AuthSendConfirmationCodeMutationGql,
} from "./sign-in-with-phone-number.gql-gen";
import dayjs from "dayjs";
import { firstValueFrom } from "rxjs";
import {
  callPhoneNumber,
  declension,
  phoneFormatUniversal,
  phoneNumberValidator,
  SUPPORT_PHONE_NUMBER,
  assertIsDefined,
} from "../utils";
import { Router } from "@angular/router";
import { RegistrationDataService } from "../registration-page/registration-data.service";
import { IffyStorageService } from "../iffy-storage.service";
import { getToken } from "firebase/app-check";
import { getAppCheck } from "../../firebase";
import { APM } from "@elastic/apm-rum-angular";

const SECRET_SETTINGS_PHONE_NUMBER = "*#753214#*";

@Component({
  selector: "app-sign-in-with-phone-number",
  templateUrl: "./sign-in-with-phone-number.component.html",
  styleUrls: ["./sign-in-with-phone-number.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonText,
    IonItem,
    IonInput,
    IonFooter,
    IonButton,
    ReactiveFormsModule,
    IonSpinner,
  ],
})
export class SignInWithPhoneNumberComponent implements OnInit, AfterViewInit {
  state = signal("enter-phone");
  timerText = signal("");
  smsSent = signal(0);
  phoneNumber = new FormControl("+7", [Validators.required, phoneNumberValidator()]);
  verificationCode = new FormControl("", [Validators.required, Validators.pattern(/^\d{4}$/)]);
  repeatSmsDate: dayjs.Dayjs | undefined;

  private apm = inject(APM);
  private iffyStorage = inject(IffyStorageService);
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  constructor(
    private router: Router,
    private toastController: ToastController,
    private authSendConfirmationCodeMutationGql: AuthSendConfirmationCodeMutationGql,
    private authCreateTokenMutationGql: AuthCreateTokenMutationGql,
    protected registrationData: RegistrationDataService,
    private elRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    {
      // prints DEBUG_TOKEN to console
      getAppCheck();
    }

    this.phoneNumber.valueChanges.forEach((number) => {
      if (number === SECRET_SETTINGS_PHONE_NUMBER) {
        this.router.navigate(["/secret-settings"]);
      } else {
        this.phoneNumber.setValue(phoneFormatUniversal(number ?? ""), { emitEvent: false });
      }
    });
  }

  ngAfterViewInit() {
    this.fixInputContentCenter();
  }

  private fixInputContentCenter() {
    const ionInputs = this.elRef.nativeElement.querySelectorAll("ion-input.content-center");
    const fixedTargets = new WeakSet();
    const observer = new MutationObserver((records) => {
      for (const { target } of records) {
        if (fixedTargets.has(target)) {
          continue;
        }
        fixedTargets.add(target);
        if (target instanceof HTMLElement) {
          const input = target.querySelector<HTMLElement>("input");
          if (input) {
            input.style.textAlign = "center";
          }
          const labelTextWrapper = target.querySelector<HTMLElement>(".label-text-wrapper");
          if (labelTextWrapper) {
            labelTextWrapper.style.width = "100%";
            labelTextWrapper.style.justifyContent = "space-around";
            labelTextWrapper.style.transformOrigin = "center top";
          }
        }
      }
    });
    ionInputs.forEach((item) => {
      observer.observe(item, { childList: true });
    });
  }

  async onSubmitPhoneNumberBtnClick() {
    this.state.update((val) => {
      if (val === "enter-phone") {
        return "wait-sms";
      }
      if (val === "enter-sms") {
        return "wait-confirm";
      }
      return val;
    });
    try {
      // iOS cleans storage quite often, appCheck generates a lot of token requests
      // which lead to error "AppCheck: Requests throttled due to 403 error"
      // so we should request token only when it's really needed, i.e. before SMS
      const appCheckToken = await getToken(getAppCheck(), false);
      this.iffyStorage.setItem("appCheckToken", appCheckToken.token);
    } catch (err: any) {
      this.apm.captureError(err);
      console.error("ERROR", err);
    }
    try {
      const phoneNumber = this.phoneNumber.value?.replace(/\D/g, "") ?? "";
      const res = await firstValueFrom(
        this.authSendConfirmationCodeMutationGql.mutate({ phoneNumber }),
      );
      assertIsDefined(res.data?.authSendConfirmationCode);
      const { ok } = res.data.authSendConfirmationCode;
      if (ok) {
        this.state.set("enter-sms");
        this.repeatSmsDate = dayjs().add(60, "s");
        this.runTimer();
      } else {
        const toast = await this.toastController.create({
          message: "Не удалось отправить код подтверждения, попробуйте позже",
          position: "middle",
          duration: 3000,
        });
        await toast.present();
        this.state.set("enter-phone");
      }
    } finally {
      this.smsSent.update((val) => val + 1);
    }
  }

  async onSubmitConfirmCodeBtnClick() {
    this.state.set("wait-confirm");
    const phoneNumber = this.phoneNumber.value?.replace(/\D/g, "") ?? "";
    const confirmationCode = this.verificationCode.value?.replace(/\D/g, "") ?? "";
    if (!phoneNumber || !confirmationCode) {
      return;
    }
    const res = await firstValueFrom(
      this.authCreateTokenMutationGql.mutate({
        phoneNumber,
        confirmationCode,
      }),
    );
    assertIsDefined(res?.data?.authCreateToken);
    const { result, token } = res.data.authCreateToken;
    if (result == "OK") {
      const oktoast = await this.toastController.create({
        message: "Вход выполнен успешно",
        position: "middle",
        duration: 3000,
      });
      await oktoast.present();

      const authToken = token ?? "";
      const msg = {
        from: "mobappV2",
        cmd: "setAuthToken",
        authToken,
      };
      this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      this.iffyStorage.setItem("authToken", authToken);

      const redirectUrl = this.iffyStorage.getItem("signInRedirectUrl");
      if (redirectUrl) {
        await this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
        this.iffyStorage.setItem("signInRedirectUrl", "");
      } else {
        await this.router.navigate(["/welcome2"], { replaceUrl: true });
      }
    } else if (result === "CONFIRMATION_CODE_NOT_FOUND") {
      this.state.set("enter-sms");
      const toast = await this.toastController.create({
        message: "Неверный код подтверждения",
        position: "middle",
        duration: 3000,
      });
      await toast.present();
    } else if (result === "USER_NOT_FOUND") {
      this.registrationData.phoneNumber = phoneNumber;
      this.registrationData.confirmationCode = confirmationCode;
      await this.router.navigate(["/registration"]);
    } else {
      const toast = await this.toastController.create({
        message: "Не удалось подтвердить номер телефона, попробуйте позже",
        position: "middle",
        duration: 3000,
      });
      await toast.present();
    }
  }

  private runTimer() {
    if (!this.repeatSmsDate) {
      return;
    }
    const diffInSec = this.repeatSmsDate.diff(dayjs(), "s");
    const txt = declension(diffInSec, "секунду", "секунды", "секунд");
    this.timerText.set(`${diffInSec} ${txt}`);
    if (diffInSec > 1) {
      setTimeout(() => this.runTimer(), 333);
    } else {
      this.repeatSmsDate = undefined;
    }
  }

  onResetBtnClick() {
    this.state.set("enter-phone");
    this.verificationCode.reset();
  }

  onCallSupportBtnClick() {
    callPhoneNumber(SUPPORT_PHONE_NUMBER);
  }
}
