<ion-header>
  <ion-toolbar>
    <ion-title color="dark">Помощь</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onSupportBtnClick($event)">Звонок</ion-button>
      <ion-button [routerLink]="'/support-version-info'">О версии</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-text-center ion-padding spinner-wrapper">
    <ion-spinner name="lines-sharp"></ion-spinner>
    <ion-text class="ion-padding-start">Инициация чата может занять до 1 минуты</ion-text>
  </div>
  <div id="support-tab-chat-container"></div>
</ion-content>
