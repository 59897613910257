import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { environment } from "./environments/environment";
import { FirebaseApp } from "@firebase/app";
import { AppCheck } from "@firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyC-05Y5YDC8dr1OffDGnpfIzSykoZCVQx8",
  authDomain: "rentalclub-45573.firebaseapp.com",
  databaseURL: "https://rentalclub-45573.firebaseio.com",
  projectId: "rentalclub-45573",
  storageBucket: "rentalclub-45573.appspot.com",
  messagingSenderId: "1077211678670",
  appId: "1:1077211678670:web:9181ace804d50603a2cd9f",
  measurementId: "G-MWEKLN0S4L",
};

if (environment.firebaseAppcheckDebugToken) {
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
// Lazy initialize Firebase
let app2: FirebaseApp | undefined;
let appCheck2: AppCheck | undefined;

export const getApp = (): FirebaseApp => {
  if (!app2) {
    app2 = initializeApp(firebaseConfig);
  }
  return app2;
};

export const getAppCheck = (): AppCheck => {
  if (!appCheck2) {
    appCheck2 = initializeAppCheck(getApp(), {
      provider: new ReCaptchaEnterpriseProvider("6LdJ97UpAAAAAGND4DU63spV8s5k-yDIbRmns67j"),
      isTokenAutoRefreshEnabled: false, // Set to true to allow auto-refresh.
    });
  }
  return appCheck2;
};
