import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import {
  ProfileMachineViewingMachineQuery,
  ProfileMachineViewingMachineQueryGql,
  ProfileMachineViewingMachineSetAvailabilityStatusMutationGql,
} from "./profile-machine-viewing.gql-gen";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { MachinePhotoSliderComponent } from "../machine-photo-slider/machine-photo-slider.component";
import { MachineViewingParamsComponent } from "../machine-viewing-params/machine-viewing-params.component";
import { switchMap } from "rxjs/operators";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { ApolloQueryResult } from "@apollo/client";
import { AlertButton, AlertInput } from "@ionic/angular";
import { MachineVerificationShelfComponent } from "../machine-verification-shelf/machine-verification-shelf.component";
import { MachineAvailabilityStatusShelfComponent } from "../machine-availability-status-shelf/machine-availability-status-shelf.component";

type MachineUIItem = ProfileMachineViewingMachineQuery["machine"] & {};

@Component({
  selector: "app-profile-machine-viewing",
  standalone: true,
  imports: [
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonLabel,
    IonList,
    MachinePhotoSliderComponent,
    MachineViewingParamsComponent,
    RouterLink,
    IonAlert,
    MachineVerificationShelfComponent,
    IonBackButton,
    MachineAvailabilityStatusShelfComponent,
  ],
  templateUrl: "./profile-machine-viewing.component.html",
  styleUrl: "./profile-machine-viewing.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMachineViewingComponent implements OnInit, OnDestroy {
  @ViewChild("photoSwiperContainer") photoSwiperContainer: ElementRef<HTMLElement> | undefined;

  protected machine?: MachineUIItem;

  protected setStatusBusyAlertInputs: AlertInput[] = [
    { label: "1 день", type: "radio", value: "1", checked: true },
    { label: "7 дней", type: "radio", value: "7" },
    { label: "30 дней", type: "radio", value: "30" },
  ];
  protected setStatusBusyAlertButtons: AlertButton[] = [
    { text: "Отмена" },
    {
      text: "OK",
      handler: (data: string) => this.machineSetBusyStatus(parseFloat(data)),
    },
  ];
  protected setStatusFreeAlertButtons: AlertButton[] = [
    { text: "Отмена" },
    {
      text: "OK",
      handler: () => this.machineSetAvailableStatus(),
    },
  ];

  private sub?: Subscription;
  private refetchMachineQuery = new BehaviorSubject(undefined);

  constructor(
    private machineQueryGql: ProfileMachineViewingMachineQueryGql,
    private setAvailabilityStatusMutationGql: ProfileMachineViewingMachineSetAvailabilityStatusMutationGql,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.sub = combineLatest([this.refetchMachineQuery, this.activatedRoute.queryParams])
      .pipe(
        switchMap(([_, params]) => {
          const machineUuid = params["machineUuid"];
          return this.machineQueryGql.fetch({ machineUuid });
        }),
      )
      .subscribe((res) => {
        this.handleMachineChange(res);
      });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  private handleMachineChange(res: ApolloQueryResult<ProfileMachineViewingMachineQuery>) {
    if (res.loading) {
      return;
    }
    const { machine } = res.data;
    if (!machine) {
      return; // fail fast
    }
    this.machine = { ...machine };
    this.cdRef.markForCheck();
  }

  private machineSetBusyStatus(busyDurationDays: number) {
    if (this.machine) {
      this.setAvailabilityStatusMutationGql
        .mutate({
          machineUuid: this.machine.uuid,
          status: "busy",
          busyDurationDays: busyDurationDays,
        })
        .subscribe(() => this.refetchMachineQuery.next(undefined));
    }
  }

  private machineSetAvailableStatus() {
    if (this.machine) {
      this.setAvailabilityStatusMutationGql
        .mutate({
          machineUuid: this.machine.uuid,
          status: "available",
        })
        .subscribe(() => this.refetchMachineQuery.next(undefined));
    }
  }
}
