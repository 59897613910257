<ion-header>
  <ion-toolbar>
    <ion-title>Подтверждение техники</ion-title>
    <ion-buttons slot="start">
      <ion-button>
        <ion-back-button></ion-back-button>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
@if (firstLayer) {
  <ion-content class="ion-padding">
    <h1 class="ion-no-margin">Подтверждение владения техникой</h1>
    <div class="ion-padding-vertical textIconContainer">
      <ion-icon class="greenIcon" name="checkmark-circle"></ion-icon>
      <ion-text>Подтверждённая техника показывается в каталоге выше неподтверждённой</ion-text>
    </div>
    <div class="textIconContainer">
      <ion-icon class="greenIcon" name="checkmark-circle"></ion-icon>
      <ion-text>Подтверждённая техника повышает доверие заказчика</ion-text>
    </div>
    <div class="ion-padding-vertical ion-margin-vertical">
      <ion-text>
        Подготовьте документы, подтверждающие право собственности и фото техники с читаемым гос.
        номером или VIN. Вы всегда можете подтвердить владение в карточке техники
      </ion-text>
    </div>
  </ion-content>
  <ion-footer class="ion-no-border ion-padding">
    <ion-button (click)="onBackButtonClick($event)" color="light" size="large">Позже</ion-button>
    <ion-button
      class="ion-margin-start rcbutton"
      color="rcyellow"
      size="large"
      (click)="onChangeLayerClick($event)"
    >
      Продолжить
    </ion-button>
  </ion-footer>
} @else {
  <ion-content class="ion-padding">
    <h2>Загрузите</h2>
    @for (card of machineOwnershipCards; track $index) {
      <ion-card class="ion-no-margin ion-margin-vertical">
        <ion-card-content>
          <ion-list>
            <ion-item lines="none">
              <ion-thumbnail (click)="onImgCardClick($event, $index)" slot="start">
                @if (card.photoSrc) {
                  <ion-img [src]="card.photoSrc" />
                } @else {
                  <ion-img src="https://ionicframework.com/docs/img/demos/thumbnail.svg" />
                }
              </ion-thumbnail>
              <label>
                <ion-text>{{ card.title }}</ion-text>
                @if (card.required) {
                  <ion-text color="rcred">&nbsp;*</ion-text>
                }
                <br />
                <ion-note>{{ card.description }}</ion-note>
                <br />
              </label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    }

    <ion-action-sheet
      [buttons]="addNewPhotoButtons"
      [isOpen]="addNewPhotoButtons.length > 0"
      (didDismiss)="onPhotoActionSheetDismiss($event)"
    ></ion-action-sheet>
    <ion-button (click)="onNewDocumentClick($event)" expand="block" color="light" size="small">
      Добавить еще документ
    </ion-button>
    <ion-note>Ваши документы не видны другим пользователям</ion-note>
    <h2>Введите</h2>
    <ion-list>
      <ion-item class="ion-no-padding rcitem">
        <ion-input
          [formControl]="model"
          labelPlacement="fixed"
          color="rcyellow"
          class="rcinput-fixed-wide"
        >
          <div slot="label">
            <ion-text>Марка</ion-text>
            <ion-text color="rcred">&nbsp;*</ion-text>
          </div>
        </ion-input>
      </ion-item>
      <ion-item class="ion-no-padding rcitem">
        <ion-input
          [formControl]="reg"
          labelPlacement="fixed"
          color="rcyellow"
          class="rcinput-fixed-wide"
        >
          <div slot="label">
            <ion-text>Гос. номер или VIN</ion-text>
            <ion-text color="rcred">&nbsp;*</ion-text>
          </div>
        </ion-input>
      </ion-item>
    </ion-list>
    <ion-note>Гос.номер/VIN и марка должны совпадать с данными в загруженных документах</ion-note>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-button
      class="ion-padding rcbutton"
      [disabled]="reg.invalid || model.invalid || !checkRequirement"
      (click)="onVerificationButtonClick($event)"
      style="width: 100%"
      color="rcyellow"
      expand="block"
      size="large"
    >
      Подтвердить технику
    </ion-button>
  </ion-footer>
}
