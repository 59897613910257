import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/angular/standalone";
import {
  ProfileNotificationSettingsAuthCurrentUserQueryGql,
  ProfileNotificationSettingsAuthToggleUserNotificationsMutationGql,
} from "./profile-notification-settings.gql-gen";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-profile-notification-settings",
  standalone: true,
  imports: [
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonContent,
    IonList,
    IonItem,
    IonToggle,
    IonBackButton,
  ],
  templateUrl: "./profile-notification-settings.component.html",
  styleUrl: "./profile-notification-settings.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileNotificationSettingsComponent implements OnInit {
  protected disabledNewParsedPush = true;

  constructor(
    private authCurrentUserQueryGql: ProfileNotificationSettingsAuthCurrentUserQueryGql,
    private authToggleUserNotificationsMutationGql: ProfileNotificationSettingsAuthToggleUserNotificationsMutationGql,
    private cdRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    const res = await firstValueFrom(this.authCurrentUserQueryGql.fetch());
    const { authCurrentUser } = res.data;
    const toggleInfo = authCurrentUser?.disabledNotifications?.find(
      (n) => n === "entryNewParsed_push",
    );
    this.disabledNewParsedPush = !toggleInfo;
    this.cdRef.markForCheck();
  }

  async onNewParsedPushToggleChange(ev: CustomEvent) {
    await firstValueFrom(
      this.authToggleUserNotificationsMutationGql.mutate({
        notification: {
          type: "entryNewParsed_push",
          enabled: ev.detail.checked,
        },
      }),
    );
  }
}
