import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { AddressPickComponent } from "../entry-details-machine/address-pick/address-pick";
import { ProfileMachineBaseSetAuthCurrentUserQueryGql } from "./profile-machine-base-set.gql-gen";
import { firstValueFrom } from "rxjs";
import { Api } from "../apollo/api";
import { Location } from "@angular/common";

type AddressDto = {
  full: string;
  lat: number;
  lng: number;
};

@Component({
  selector: "app-profile-machine-base-set",
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonFooter,
    AddressPickComponent,
    IonBackButton,
  ],
  templateUrl: "./profile-machine-base-set.component.html",
  styleUrl: "./profile-machine-base-set.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMachineBaseSetComponent implements OnInit {
  protected currentAddress?: AddressDto;
  private newAddress?: AddressDto;
  private organizationUuid = "";

  constructor(
    private cdRef: ChangeDetectorRef,
    private authCurrentUserQueryGql: ProfileMachineBaseSetAuthCurrentUserQueryGql,
    private api: Api,
    private location: Location,
  ) {}

  async ngOnInit(): Promise<void> {
    const { data } = await firstValueFrom(this.authCurrentUserQueryGql.fetch());
    const organization = data.authCurrentUser?.organizations.at(0);
    const base = organization?.bases.at(0);
    if (organization) {
      this.organizationUuid = organization.uuid;
    }
    if (base?.address) {
      this.currentAddress = {
        full: base.address.fullAddress ?? "",
        lng: base.address.coordinates?.at(0) ?? 0,
        lat: base.address.coordinates?.at(1) ?? 0,
      };
    }
    this.cdRef.markForCheck();
  }

  async onSaveMachineBaseClick(ev: Event) {
    ev.preventDefault();
    if (this.newAddress && this.organizationUuid) {
      const address = {
        fullAddress: this.newAddress.full,
        coordinates: [this.newAddress.lng, this.newAddress.lat],
      };
      await this.api.setMachineBase(address, this.organizationUuid);
    }
    this.location.back();
  }

  onAddressChange(addressDto: AddressDto) {
    this.newAddress = addressDto;
  }
}
