import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RegistrationCompanyAuthGetCompanyDataByInnQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
  confirmationCode: Types.Scalars['String']['input'];
  inn: Types.Scalars['String']['input'];
}>;


export type RegistrationCompanyAuthGetCompanyDataByInnQuery = { __typename?: 'Query', authGetCompanyDataByInn: { __typename?: 'CompanyDataResult', result: Types.EnumCompanyDataResult, companies: Array<{ __typename?: 'CompanyData', inn?: string | null, kpp?: string | null, ogrn?: string | null, founded?: any | null, address?: string | null, fullName?: string | null } | null> } };

export type RegistrationCompanyAuthRegistrationMutationVariables = Types.Exact<{
  registration: Types.RegistrationInput;
}>;


export type RegistrationCompanyAuthRegistrationMutation = { __typename?: 'Mutation', authRegistration: { __typename?: 'RegistrationResult', result: Types.EnumRegistrationResult } };

export type RegistrationCompanyAuthCreateTokenMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
  confirmationCode: Types.Scalars['String']['input'];
}>;


export type RegistrationCompanyAuthCreateTokenMutation = { __typename?: 'Mutation', authCreateToken: { __typename?: 'TokenResult', token?: string | null, result: Types.EnumTokenResult } };

export const RegistrationCompanyAuthGetCompanyDataByInnDocument = gql`
    query RegistrationCompanyAuthGetCompanyDataByInn($phoneNumber: String!, $confirmationCode: String!, $inn: String!) {
  authGetCompanyDataByInn(
    phoneNumber: $phoneNumber
    confirmationCode: $confirmationCode
    inn: $inn
  ) {
    result
    companies {
      inn
      kpp
      ogrn
      founded
      address
      fullName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationCompanyAuthGetCompanyDataByInnQueryGql extends Apollo.Query<RegistrationCompanyAuthGetCompanyDataByInnQuery, RegistrationCompanyAuthGetCompanyDataByInnQueryVariables> {
    override document = RegistrationCompanyAuthGetCompanyDataByInnDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegistrationCompanyAuthRegistrationDocument = gql`
    mutation RegistrationCompanyAuthRegistration($registration: RegistrationInput!) {
  authRegistration(registration: $registration) {
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationCompanyAuthRegistrationMutationGql extends Apollo.Mutation<RegistrationCompanyAuthRegistrationMutation, RegistrationCompanyAuthRegistrationMutationVariables> {
    override document = RegistrationCompanyAuthRegistrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegistrationCompanyAuthCreateTokenDocument = gql`
    mutation RegistrationCompanyAuthCreateToken($phoneNumber: String!, $confirmationCode: String!) {
  authCreateToken(phoneNumber: $phoneNumber, confirmationCode: $confirmationCode) {
    token
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationCompanyAuthCreateTokenMutationGql extends Apollo.Mutation<RegistrationCompanyAuthCreateTokenMutation, RegistrationCompanyAuthCreateTokenMutationVariables> {
    override document = RegistrationCompanyAuthCreateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }