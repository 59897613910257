import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MachineViewingParamsMachineQuery } from "./machine-viewing-params.gql-gen";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonThumbnail,
} from "@ionic/angular/standalone";
import { MaskitoPipe } from "@maskito/angular";
import { MaskitoOptions } from "@maskito/core";
import { maskitoNumberOptionsGenerator } from "@maskito/kit";

type MachineParamsUIData = {
  model: string;
  year: string;
  params: {
    ruName: string;
    unit: string;
    uuid: string;
    type: string;
    valueBoolean?: boolean;
    valueRange?: number;
    valueSelect?: string;
  }[];
  equips: { url?: string; uuid: string; ruName: string }[];
  pricePerMinOrder?: number;
  pricePerWorkShift?: number;
  description: string;
  reg: string;
};

@Component({
  selector: "app-machine-viewing-params",
  standalone: true,
  imports: [IonItem, IonLabel, IonList, IonText, IonIcon, IonThumbnail, MaskitoPipe],
  templateUrl: "./machine-viewing-params.component.html",
  styleUrl: "./machine-viewing-params.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineViewingParamsComponent implements OnChanges {
  @Input({ required: true }) machine?: MachineViewingParamsMachineQuery["machine"];

  protected machineParams?: MachineParamsUIData;
  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });

  ngOnChanges(_changes: SimpleChanges) {
    const { machine } = this;
    if (!machine) {
      this.machineParams = undefined;
      return;
    }
    const paramsUiData: MachineParamsUIData["params"] = [];
    const equipsUiData: MachineParamsUIData["equips"] = [];
    for (const p of machine.params) {
      if (!p.spec.isEquipment) {
        paramsUiData.push({
          ruName: p.spec.nameRU,
          unit: p.spec.unit ?? "",
          type: p.spec.type,
          valueBoolean: p.valueBoolean ?? undefined,
          valueRange: p.valueRange ?? undefined,
          valueSelect: p.valueSelect ?? undefined,
          uuid: p.refUuid,
        });
      }
      if (p.spec.isEquipment && p.valueBoolean) {
        if (p.spec.ids?.idRentalParam) {
          equipsUiData.push({
            url: `assets/equipment/${p.spec.ids?.idRentalParam}.jpg`,
            uuid: p.refUuid,
            ruName: p.spec.nameRU,
          });
        } else {
          equipsUiData.push({
            uuid: p.refUuid,
            ruName: p.spec.nameRU,
          });
        }
      }
    }
    this.machineParams = {
      model: machine.model,
      year: `${machine.year}`,
      params: paramsUiData,
      reg: machine.reg ?? "",
      description: machine.description ?? "",
      equips: equipsUiData,
      pricePerWorkShift: machine.pricePerWorkShift ?? undefined,
      pricePerMinOrder: machine.pricePerMinOrder ?? undefined,
    };
  }
}
