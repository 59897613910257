import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import {
  Entry,
  EntryProposes,
  EntryRequests,
  MachineType,
  MachineTypeParams,
} from "../../base-types.gql-gen";
import dayjs from "dayjs";
import { Api } from "../apollo/api";
import { FieldsEditComponent, InfoForUpdate } from "../fields-edit/fields-edit.component";

@Component({
  selector: "app-propose-edit",
  templateUrl: "propose-edit-modal.component.html",
  styleUrls: ["./propose-edit-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonFooter,
    FieldsEditComponent,
  ],
})
export class ProposeEditModalComponent implements OnInit {
  @Input() entry!: Entry;
  @Input() myPropose!: EntryProposes;

  @ViewChild("fieldsEdit") fieldsEditComponent?: FieldsEditComponent;

  isKeyboardOpen = false;
  updatedData?: InfoForUpdate;

  constructor(
    private api: Api,
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
  ) {}

  async ngOnInit() {
    const category = this.entry.requests[0]?.data?.machineTypeData;
    const categoryParams = await this.getParamsForCategory(category?.uuid);
    const customer = this.entry.customer;
    const organization = await this.api.getOrganization(customer?.organization?.uuid || "");
    const previousAddresses = [
      ...(organization.objects?.map((o: any) => {
        return {
          uuid: o.uuid,
          title: o.title,
          full: o.address?.fullAddress,
          lat: o.address?.coordinates?.[0],
          lng: o.address?.coordinates?.[1],
          object: o,
        };
      }) || []),
    ];
    this.setInitialData(categoryParams, previousAddresses);
  }

  @HostListener("window:message", ["$event"])
  onWindowMessage(ev: MessageEvent) {
    this.cd.markForCheck();
    if (ev.data?.cmd === "keyboard-open") {
      this.isKeyboardOpen = true;
    }
    if (ev.data?.cmd === "keyboard-closed") {
      this.isKeyboardOpen = false;
    }
  }

  setInitialData(categoryParams: MachineTypeParams[] | null, previousAddresses: any[]) {
    const initialData = this.myPropose || this.entry;
    const conditions = initialData?.requests?.[0]?.conditions;
    const data = initialData?.requests?.[0]?.data;
    const payment = initialData?.requests?.[0]?.payment;
    const date = initialData?.requests?.[0]?.conditions?.date?.value;
    const objects = initialData?.objects;
    let calendarDate = dayjs().toISOString();
    if (initialData?.requests?.[0]?.conditions?.date?.type === "datetime" && date) {
      calendarDate = dayjs.unix(date)?.utc(true).toISOString();
    }
    this.updatedData = {
      calendarDate,
      categoryParams,
      previousAddresses,
      unit: conditions?.work?.type || "shift",
      workDays: conditions?.work?.value || 1,
      paymentType: payment?.type || "none",
      price: (conditions?.budget?.value || 0) / 100,
      comment: data?.comment?.trim() || this.entry?.requests?.[0]?.data?.comment || "",
      objects: [...(objects || [])],
      entryParams: data?.params || [],
      date: date ? dayjs.unix(date) : undefined,
      address: {
        full: objects?.[0]?.address?.fullAddress,
        lat: objects?.[0]?.address?.coordinates?.[0],
        lng: objects?.[0]?.address?.coordinates?.[1],
      },
    };
    this.cd.markForCheck();
  }

  async getParamsForCategory(categoryId: string = "") {
    const categories = (await this.api.getMachineCategories()) || [];
    const category = categories?.find(({ uuid }: MachineType) => uuid === categoryId);
    return (
      category?.params
        ?.filter((p: MachineTypeParams) => p?.paramPurpose?.entry)
        ?.filter((p: MachineTypeParams) => p?.ids?.idRentalParam !== 88) || []
    );
  }
  close() {
    return this.modalCtrl.dismiss();
  }
  updatePropose() {
    const newData = this.fieldsEditComponent?.getUpdatedData();
    if (!newData) return;
    this.modalCtrl.dismiss({
      ...this.myPropose,
      objects: newData?.objects,
      requests: [
        {
          ...(this.myPropose.requests?.[0] as EntryRequests),
          conditions: {
            budget: {
              type: newData.paymentType,
              value: newData.price * 100,
            },
            date: {
              type: newData.date ? "datetime" : "none",
              value: newData.date
                ? dayjs(newData.date.toISOString().replace("Z", "")).unix()
                : undefined,
            },
            work: {
              type: newData.unit,
              value: newData.workDays,
            },
          },
          payment: {
            type: newData.paymentType,
            value: newData.price * 100,
          },
          data: {
            ...this.myPropose.requests?.[0]?.data,
            comment: newData.comment,
            params: newData.entryParams,
          },
        },
      ],
    });
  }
}
