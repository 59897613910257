import { Injectable } from "@angular/core";
import { Api } from "../../apollo/api";
import { ChaportConfigResDto } from "../../apollo/api.interfaces";
import { assertIsDefined } from "../../utils";

// https://docs.chaport.com/api/js/v1/#overview
declare global {
  interface Window {
    chaport: {
      _q: any[];
      _l: { [key: string]: any[] };
      q: (commandName: string, ...args: any[]) => void;
      on: (eventName: string, fn: (...args: any[]) => any) => void;
    };
    chaportConfig: {
      appId: string;
      appearance?: {
        windowColor?: string;
        teamName?: string;
        onlineWelcome?: string;
        offlineWelcome?: string;
      };
      language?: {
        use?: string;
      };
      launcher?: {
        show?: boolean;
      };
      closeButton: boolean;
      session?: {
        autoStart?: boolean;
        persist?: boolean;
        user?: {
          visitorId?: string;
          token?: string;
        };
      };
      visitor?: {
        name?: string;
        email?: string;
      };
      visitorIntegrityHash?: string;
    };
  }
}

@Injectable()
export class ChaportLoaderService {
  private scriptId = "chaport-loader";
  private initialized = false;
  private config?: ChaportConfigResDto = undefined;
  private visitorData?: [Window["chaportConfig"]["visitor"], string];
  private sessionData?: { id: string; token: string } = undefined;

  constructor(private api: Api) {}

  async init() {
    await this.ensureInitializated();
  }

  async openIn(opts: { selector: string }) {
    await this.ensureInitializated();
    return window.chaport.q("openIn", opts);
  }

  private startSession(opts?: any) {
    window.chaport.q("startSession", opts);
  }

  private setVisitorData(visitorData: any) {
    window.chaport.q("setVisitorData", visitorData);
  }

  private setup() {
    window.chaport.on("ready", (ev: { visitorId: string }) => {
      if (!ev.visitorId) {
        throw "Chaport ready but no visitor id";
      }
      this.api.setChaportVisitorId(ev.visitorId);
    });
    window.chaport.on("widget.beforeStateChange", (states) => {
      if (states.toState === "unreadMessage") {
        // this.events.publish("newSupportMessageReceived");
        return false; // block popup on new message
      }
      return undefined;
    });
    this.setVisitorData(this.visitorData);
    this.startSession(this.sessionData);
  }

  private async ensureInitializated() {
    if (!this.initialized) {
      try {
        this.initialized = true;
        this.injectController();
        this.config = await this.api.fetchChaportConfig();
        this.injectConfig();
        this.setup();
        await this.injectLoader();
      } catch (err) {
        this.initialized = false;
        this.visitorData = undefined;
        this.sessionData = undefined;
        throw err;
      }
    }
  }

  private injectController() {
    if (window.chaport) {
      return;
    }
    const chaport: Window["chaport"] = {
      _q: [],
      _l: {},
      q: (...args) => chaport._q.push(args),
      on: (e, fn) => {
        if (!chaport._l[e]) {
          chaport._l[e] = [];
        }
        chaport._l[e].push(fn);
      },
    };
    window.chaport = chaport;
  }

  private injectConfig() {
    assertIsDefined(this.config);
    const { appId, session, visitor, visitorIntegrityHash } = this.config;
    const user = session?.user;

    this.visitorData = [visitor, visitorIntegrityHash];
    this.sessionData = user ? { id: user.visitorId, token: user.token } : undefined;

    window.chaportConfig = {
      appId,
      visitor,
      session: {
        user,
        persist: false,
        autoStart: false,
      },
      launcher: {
        show: false,
      },
      language: {
        use: "ru",
      },
      closeButton: false,
      visitorIntegrityHash,
    };
  }

  private injectLoader() {
    if (document.querySelector(`#${this.scriptId}`)) {
      return;
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.id = this.scriptId;
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://app.chaport.com/javascripts/insert.js";
      script.onerror = (err) => {
        document.removeChild(script);
        reject(err);
      };
      script.onload = resolve;
      document.head.appendChild(script);
    });
  }
}
