import { ChangeDetectionStrategy, Component, HostListener, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";

@Component({
  selector: "app-support-version-info",
  standalone: true,
  imports: [CommonModule, IonButtons, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent],
  templateUrl: "./support-version-info.component.html",
  styleUrl: "./support-version-info.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportVersionInfoComponent implements OnInit {
  protected currentVersion = "";
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  ngOnInit(): void {
    const msg = {
      from: "mobappV2",
      cmd: "getVersionInfo",
    };
    this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
  }

  @HostListener("window:message", ["$event"])
  async onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd === "getVersionInfoResult") {
      this.currentVersion = data.version;
    }
  }
}
