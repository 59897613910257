<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-text color="dark">
    <h1 [class.invisible]="!selectedRegion">
      Rental Club пока не работает
      <br />
      в {{ selectedRegion }}
    </h1>
    <p>
      <span>
        По всем вопросам сотрудничества в вашем городе можно обращаться по номеру телефона
      </span>
      <a (click)="onSupportPhoneClick()">{{ supportPhoneNumber }}</a>
    </p>
    <p>Или оставьте ваши контакты и мы свяжемся с вами</p>
  </ion-text>
  <ion-list [formGroup]="contactFormGroup">
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'firstName'" label="Имя" labelPlacement="floating"></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input
        [formControlName]="'lastName'"
        label="Фамилия"
        labelPlacement="floating"
      ></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'email'" label="Email" labelPlacement="floating"></ion-input>
    </ion-item>
    <ion-item class="ion-no-padding rcitem">
      <ion-input [formControlName]="'city'" label="Город" labelPlacement="floating"></ion-input>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    (click)="onContactFormSubmit()"
    [disabled]="contactFormGroup.invalid"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    <ion-text>Отправить контакты</ion-text>
  </ion-button>
</ion-footer>
