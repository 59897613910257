import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RegistrationProfileAuthRegistrationMutationVariables = Types.Exact<{
  registration: Types.RegistrationInput;
}>;


export type RegistrationProfileAuthRegistrationMutation = { __typename?: 'Mutation', authRegistration: { __typename?: 'RegistrationResult', result: Types.EnumRegistrationResult } };

export type RegistrationProfileAuthCreateTokenMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
  confirmationCode: Types.Scalars['String']['input'];
}>;


export type RegistrationProfileAuthCreateTokenMutation = { __typename?: 'Mutation', authCreateToken: { __typename?: 'TokenResult', token?: string | null, result: Types.EnumTokenResult } };

export const RegistrationProfileAuthRegistrationDocument = gql`
    mutation RegistrationProfileAuthRegistration($registration: RegistrationInput!) {
  authRegistration(registration: $registration) {
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationProfileAuthRegistrationMutationGql extends Apollo.Mutation<RegistrationProfileAuthRegistrationMutation, RegistrationProfileAuthRegistrationMutationVariables> {
    override document = RegistrationProfileAuthRegistrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegistrationProfileAuthCreateTokenDocument = gql`
    mutation RegistrationProfileAuthCreateToken($phoneNumber: String!, $confirmationCode: String!) {
  authCreateToken(phoneNumber: $phoneNumber, confirmationCode: $confirmationCode) {
    token
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistrationProfileAuthCreateTokenMutationGql extends Apollo.Mutation<RegistrationProfileAuthCreateTokenMutation, RegistrationProfileAuthCreateTokenMutationVariables> {
    override document = RegistrationProfileAuthCreateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }