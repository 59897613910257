import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  Entry,
  EntryProposes,
  EnumEntryParticipantsType,
  Machine,
  MachineParams,
  MachineTypeParams,
  Maybe,
} from "../../../base-types.gql-gen";
import {
  IonIcon,
  ModalController,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/angular/standalone";
import * as translations from "../../../interface.json";
import { callPhoneNumber, phoneFormatUniversal } from "../../utils";
import { Api } from "../../apollo/api";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-entry-sign-machine",
  templateUrl: "./entry-sign-machine.component.html",
  styleUrls: ["./entry-sign-machine.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    IonIcon,
    IonSelect,
    IonInput,
    IonItem,
    IonSelectOption,
    IonLabel,
    IonList,
    IonListHeader,
  ],
})
export class EntrySignMachineComponent implements OnInit {
  @Input() myPropose?: Maybe<EntryProposes>;
  @Input() entry?: Entry;
  @Input() viewAs: EnumEntryParticipantsType = "customer";
  @Output() selectMachine = new EventEmitter();
  @Output() enterDriverInfo = new EventEmitter();
  driverName = "";
  driverPhoneNumber = "";
  selectedMachine?: Maybe<Machine>;
  userMachines: Machine[] = [];

  editFormGroup = new FormGroup({
    name: new FormControl(""),
    machine: new FormControl("", [Validators.required]),
    phoneNumber: new FormControl("", [this.phoneValidator()]),
  });

  constructor(
    private cd: ChangeDetectorRef,
    private api: Api,
    private modalCtrl: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.myPropose?.supplier?.organization?.uuid) {
      this.api.getMachines(this.myPropose?.supplier?.organization?.uuid).then((res) => {
        const data = this.entry?.requests?.[0]?.data?.machineType;
        this.userMachines = res?.filter((m) => m.type === data) || [];
      });
    }
    const phoneNumberCtrl = this.editFormGroup.get("phoneNumber");
    if (phoneNumberCtrl) {
      phoneNumberCtrl.valueChanges.forEach((number) => {
        phoneNumberCtrl.setValue(phoneFormatUniversal(number ?? ""), { emitEvent: false });
      });
    }
  }

  get machine() {
    return this.preselectedMachine || this.selectedMachine;
  }
  get preselectedMachine() {
    return this.myPropose?.responses?.[0]?.machine;
  }

  get distance() {
    return this.myPropose?.responses?.[0]?.data?.distance;
  }

  get printMachineMainParamValue(): string {
    const mainParam =
      this.machine?.typeData?.params?.find((p) => p?.paramPurpose?.main) || undefined;
    const val = this.machine?.params?.find((p) => p?.refUuid === mainParam?.uuid);
    return val ? this.printMachineParamValue(val, mainParam) : "";
  }

  get category() {
    // @ts-ignore
    return translations[this.machine.typeData?.name];
  }

  get entryDriverContact() {
    const org = this.entry?.participants?.find((p) => p?.type === "supplier");
    if (org?.contacts) {
      return org.contacts.find((contact) => contact?.isDriver);
    }
    return null;
  }

  get driverPhone() {
    return phoneFormatUniversal(this.entryDriverContact?.contact?.value);
  }

  async showMachine() {
    if (this.machine) {
      await this.router.navigate(["catalog-machine-viewing"], {
        queryParams: { machineUuid: this.machine.uuid },
      });
    }
  }
  phoneValidator() {
    return (control: AbstractControl): null | { [key: string]: any } => {
      const value = control.value.replace(/\D/g, "");
      const isOk = /^7\d{10}$/.test(value);
      return isOk || !value ? null : { invalidPhoneNumber: { value: control.value } };
    };
  }
  onMachineChange(e: any) {
    if (e.detail.value) {
      this.selectedMachine = this.userMachines.find((m) => m.uuid === e.detail.value);
    } else {
      this.selectedMachine = null;
    }
    this.cd.detectChanges();
    this.selectMachine.emit(this.selectedMachine);
  }
  onEnterDriverInfo(e: any, type: "name" | "phone") {
    this.cd.detectChanges();
    this.enterDriverInfo.emit({
      name: this.editFormGroup.get("name")?.value,
      phone: this.editFormGroup.get("phoneNumber")?.value,
    });
  }
  callTo(phone?: string) {
    let phoneString = `${phone ? phone : ""}`;
    if (phone?.length === 7) {
      phoneString = phone;
    }
    callPhoneNumber(phoneString);
  }

  printMachineParamValue(param: MachineParams, paramDescription?: MachineTypeParams): string {
    if (paramDescription && param.value !== undefined) {
      switch (paramDescription.type) {
        case "range":
          return `${param.value}${paramDescription.unit || ""}`;
        case "boolean":
          if (param.value) {
            return paramDescription.name;
          }
          break;
        case "select":
          if (paramDescription.settings) {
            return paramDescription.settings.select.find((option: any) => {
              return param.value === option.value;
            });
          }
          break;
      }
    }
    return param.value;
  }
}
