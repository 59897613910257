<ion-header>
  <ion-toolbar>
    <ion-back-button slot="start" [defaultHref]="'/home/matches'"></ion-back-button>
    <ion-title>Предложение техники</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div>
    <ion-list lines="none" class="form">
      <ion-item>
        <ion-select
          label-placement="floating"
          fill="solid"
          okText="ОК"
          cancelText="Отменить"
          [formControl]="proposalForm.controls.machineId"
        >
          <div slot="label"><ion-text class="label-slot">Техника</ion-text></div>
          @for (machine of machineState.machines; track machine.id) {
            <ion-select-option [value]="machine.id">
              {{ machine.title }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-select
          label-placement="floating"
          fill="solid"
          okText="ОК"
          cancelText="Отменить"
          [formControl]="proposalForm.controls.paymentType"
        >
          <div slot="label"><ion-text class="label-slot">Вид рассчета</ion-text></div>
          @for (pt of paymentTypes; track pt.value) {
            <ion-select-option [value]="pt.value">{{ pt.label }}</ion-select-option>
          }
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input
          label-placement="floating"
          fill="solid"
          inputmode="numeric"
          [maskito]="priceMask"
          [maskitoElement]="maskPredicate"
          [formControl]="proposalForm.controls.price"
        >
          <div slot="label"><ion-text class="label-slot">Ваша цена за смену, ₽</ion-text></div>
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-textarea
          class="custom-comment"
          label-placement="floating"
          fill="solid"
          [autoGrow]="true"
          [formControl]="proposalForm.controls.comment"
        >
          <div slot="label"><ion-text class="label-slot">Комментарий</ion-text></div>
        </ion-textarea>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
<ion-footer class="ion-no-border">
  <div class="ion-no-padding ion-padding-top ion-padding-horizontal">
    <ion-text class="disclaimer-text">
      Заказчик получит ваше предложение и свяжется с вами в случае интереса. Также после отправки
      предложения вы можете сами позвонить заказчику, чтобы уточнить детали заявки.
    </ion-text>
  </div>

  <ion-button
    (click)="onSendProposeBtnClick($event)"
    [disabled]="proposalForm.invalid || sendProposePending"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Отправить предложение
  </ion-button>
</ion-footer>
