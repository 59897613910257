@if (machineParams) {
  <ion-list class="ion-padding">
    <ion-item class="ion-no-padding">
      <ion-label>Марка и модель</ion-label>
      <ion-text>{{ machineParams.model }}</ion-text>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label>Год выпуска</ion-label>
      <ion-text>{{ machineParams.year }}</ion-text>
    </ion-item>
    @for (param of machineParams.params; track param.uuid) {
      @if (param.type === "boolean" && param.valueBoolean === true) {
        <ion-item class="ion-no-padding rcitem">
          <ion-label>{{ param.ruName }}</ion-label>
          <ion-icon name="checkmark-circle"></ion-icon>
        </ion-item>
      }
      @if (param.type === "range") {
        <ion-item class="ion-no-padding rcitem">
          <ion-label>{{ param.ruName }}</ion-label>
          <ion-text>{{ param.valueRange }}&nbsp;{{ param.unit }}</ion-text>
        </ion-item>
      }
      @if (param.type === "select" && param.valueSelect) {
        <ion-item class="ion-no-padding rcitem">
          <ion-label>{{ param.ruName }}</ion-label>
          <ion-text>{{ param.valueSelect }}</ion-text>
        </ion-item>
      }
    }
    <ion-item class="ion-no-padding">
      <ion-label>Цена за мин.заказ</ion-label>
      @if (machineParams.pricePerMinOrder) {
        <ion-text>{{ machineParams.pricePerMinOrder | maskito: priceMask }}&nbsp;₽</ion-text>
      }
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label>Цена за смену, 8 ч</ion-label>
      @if (machineParams.pricePerWorkShift) {
        <ion-text>{{ machineParams.pricePerWorkShift | maskito: priceMask }}&nbsp;₽</ion-text>
      }
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label>Гос.номер или VIN</ion-label>
      <ion-text>{{ machineParams.reg }}</ion-text>
    </ion-item>
    @if (machineParams.description) {
      <ion-item class="ion-no-padding">
        <ion-label>Описание:</ion-label>
        <ion-text class="ion-padding-start">{{ machineParams.description }}</ion-text>
      </ion-item>
    }
    @if (machineParams.equips.length > 0) {
      <ion-item class="ion-no-padding" lines="none">
        <ion-label>Дополнительное оборудование:</ion-label>
      </ion-item>
      @for (e of machineParams.equips; track e.uuid) {
        <ion-item lines="none" class="ion-no-padding">
          @if (e.url) {
            <ion-thumbnail><img [src]="e.url" alt="" /></ion-thumbnail>
          }
          <ion-text class="ion-padding-start">{{ e.ruName }}</ion-text>
        </ion-item>
      }
    }
  </ion-list>
}
