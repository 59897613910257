<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <h1 class="mainHeader">Приятно видеть нового члена клуба</h1>

  <ion-item class="ion-no-padding rcitem">
    <ion-input
      [formControl]="innNumber"
      label="ИНН компании или ИП"
      labelPlacement="floating"
    ></ion-input>
  </ion-item>

  @if (selectedCompany) {
    <div class="ion-margin-top">
      <span class="companyDetails">
        {{ selectedCompany.fullName }}
        <br />
      </span>
      <span class="companyDetails">ИНН:</span>
      <span>
        {{ selectedCompany.inn }}
        <br />
      </span>
      <span class="companyDetails">КПП:</span>
      <span>
        {{ selectedCompany.kpp }}
        <br />
      </span>
      <span class="companyDetails">ОГРН:</span>
      <span>
        {{ selectedCompany.ogrn }}
        <br />
      </span>
      <span class="companyDetails">Юр.адрес:</span>
      <span>
        {{ selectedCompany.address }}
        <br />
      </span>
      <span class="companyDetails">Дата основания:</span>
      <span>
        {{ formatDate(selectedCompany.founded) }}
        <br />
      </span>
    </div>
    <ion-checkbox [formControl]="checkboxAgreement" labelPlacement="end" class="ion-margin-top">
      Я подтверждаю, что являюсь представителем
      <br />
      указанной компании
    </ion-checkbox>
    <ion-checkbox class="ion-margin-top" labelPlacement="end" [formControl]="checkboxPolicy">
      Я даю свое
      <a
        (click)="onDocumentLinkClick($event)"
        href="https://minio.rental-club.ru/public/rc/docs/consent_to_personal_data_proccessing.docx"
        target="_blank"
      >
        Согласие на обработку
        <br />
        персональных данных
      </a>
      <span>и согласен с</span>
      <br />
      <a
        (click)="onDocumentLinkClick($event)"
        href="https://minio.rental-club.ru/public/rc/docs/personal_data_policy.docx"
        target="_blank"
      >
        Политикой обработки персональных данных
      </a>
    </ion-checkbox>
    <ion-text class="checkboxText">
      Нажимая кнопку "Зарегистрироваться" я принимаю условия
      <a href="https://minio.rental-club.ru/public/rc/docs/user_agreement.docx" target="_blank">
        Пользовательского соглашения
      </a>
    </ion-text>
  }
</ion-content>
<ion-footer class="ion-no-border">
  @if (!selectedCompany) {
    <ion-button
      (click)="onSearchCompanyBtnClick()"
      [disabled]="innNumber.invalid"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      <ion-text>Найти компанию</ion-text>
    </ion-button>
  } @else {
    <ion-button
      (click)="onRegisterCompanyBtnClick()"
      [disabled]="checkboxAgreement.invalid || checkboxPolicy.invalid || !selectedCompany"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      <ion-text>Зарегистрироваться</ion-text>
    </ion-button>
  }
</ion-footer>

<ion-modal [isOpen]="companies.length > 0 && !selectedCompany">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Выберите филиал компании</ion-title>
        <ion-buttons slot="end"></ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      @for (company of companies; track $index) {
        <ion-card [button]="true" (click)="onCardClick(company)">
          <ion-card-header>
            <ion-card-title>
              <span>{{ company.fullName }}</span>
            </ion-card-title>
            <ion-card-subtitle>
              <p>ИНН: {{ company.inn }} / КПП: {{ company.kpp }}</p>
              <p>Юр.адрес: {{ company.address }}</p>
            </ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      }
    </ion-content>
  </ng-template>
</ion-modal>
