import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfileNotificationSettingsAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileNotificationSettingsAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', disabledNotifications?: Array<Types.EnumUserDisabledNotifications> | null } | null };

export type ProfileNotificationSettingsAuthToggleUserNotificationsMutationVariables = Types.Exact<{
  notification: Types.UserNotificationsInput;
}>;


export type ProfileNotificationSettingsAuthToggleUserNotificationsMutation = { __typename?: 'Mutation', authToggleUserNotifications: { __typename?: 'User', id: string } };

export const ProfileNotificationSettingsAuthCurrentUserDocument = gql`
    query ProfileNotificationSettingsAuthCurrentUser {
  authCurrentUser {
    disabledNotifications
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileNotificationSettingsAuthCurrentUserQueryGql extends Apollo.Query<ProfileNotificationSettingsAuthCurrentUserQuery, ProfileNotificationSettingsAuthCurrentUserQueryVariables> {
    override document = ProfileNotificationSettingsAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileNotificationSettingsAuthToggleUserNotificationsDocument = gql`
    mutation ProfileNotificationSettingsAuthToggleUserNotifications($notification: UserNotificationsInput!) {
  authToggleUserNotifications(notification: $notification) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileNotificationSettingsAuthToggleUserNotificationsMutationGql extends Apollo.Mutation<ProfileNotificationSettingsAuthToggleUserNotificationsMutation, ProfileNotificationSettingsAuthToggleUserNotificationsMutationVariables> {
    override document = ProfileNotificationSettingsAuthToggleUserNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }