<ion-header>
  <ion-toolbar>
    <ion-title>Уведомления</ion-title>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/home/profile?segment=info'"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list>
    <ion-item class="ion-no-padding">
      <ion-toggle
        (ionChange)="onNewParsedPushToggleChange($event)"
        [checked]="disabledNewParsedPush"
        mode="ios"
        color="rcyellow"
      >
        <span>Включить уведомления о заявках из внешних источников</span>
      </ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>
