import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryRequestFormSimpleAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EntryRequestFormSimpleAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', organizations: Array<{ __typename?: 'Organization', id: string, ref: string, objects: Array<{ __typename?: 'Object', ref: string, organizationRef?: string | null, title?: string | null, ownerName?: string | null, address?: { __typename?: 'ObjectAddress', fullAddress?: string | null, coordinates?: Array<number | null> | null } | null }> } | null> } | null };

export type EntryRequestFormSimpleSearchProvidersCountQueryVariables = Types.Exact<{
  machineTypeId: Types.Scalars['String']['input'];
  addressCoords: Array<Types.Scalars['Float']['input']> | Types.Scalars['Float']['input'];
  machineParams: Array<Types.SearchProvidersMachineParam> | Types.SearchProvidersMachineParam;
}>;


export type EntryRequestFormSimpleSearchProvidersCountQuery = { __typename?: 'Query', searchProvidersCount: number };

export type EntryRequestFormSimpleSearchCreateRequestMutationVariables = Types.Exact<{
  data: Types.CreateEntryInput;
}>;


export type EntryRequestFormSimpleSearchCreateRequestMutation = { __typename?: 'Mutation', searchCreateRequest: { __typename?: 'Entry', id: string, uuid: string, availableActions: Array<Types.EnumEntryAvailableActionsType> } };

export type EntryRequestFormSimpleAuthCreateObjectMutationVariables = Types.Exact<{
  coordinates: Array<Types.Scalars['Float']['input']> | Types.Scalars['Float']['input'];
  fullAddress: Types.Scalars['String']['input'];
  ownerOrganizationId: Types.Scalars['ID']['input'];
  onDuplicate: Types.OnDuplicate;
}>;


export type EntryRequestFormSimpleAuthCreateObjectMutation = { __typename?: 'Mutation', authCreateObject: { __typename?: 'Object', id: string, ref: string } };

export const EntryRequestFormSimpleAuthCurrentUserDocument = gql`
    query EntryRequestFormSimpleAuthCurrentUser {
  authCurrentUser {
    organizations {
      id
      ref
      objects {
        ref
        organizationRef
        address {
          fullAddress
          coordinates
        }
        title
        ownerName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormSimpleAuthCurrentUserQueryGql extends Apollo.Query<EntryRequestFormSimpleAuthCurrentUserQuery, EntryRequestFormSimpleAuthCurrentUserQueryVariables> {
    override document = EntryRequestFormSimpleAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormSimpleSearchProvidersCountDocument = gql`
    query EntryRequestFormSimpleSearchProvidersCount($machineTypeId: String!, $addressCoords: [Float!]!, $machineParams: [SearchProvidersMachineParam!]!) {
  searchProvidersCount(
    machineTypeId: $machineTypeId
    machineParams: $machineParams
    addressCoords: $addressCoords
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormSimpleSearchProvidersCountQueryGql extends Apollo.Query<EntryRequestFormSimpleSearchProvidersCountQuery, EntryRequestFormSimpleSearchProvidersCountQueryVariables> {
    override document = EntryRequestFormSimpleSearchProvidersCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormSimpleSearchCreateRequestDocument = gql`
    mutation EntryRequestFormSimpleSearchCreateRequest($data: CreateEntryInput!) {
  searchCreateRequest(data: $data) {
    id
    uuid
    availableActions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormSimpleSearchCreateRequestMutationGql extends Apollo.Mutation<EntryRequestFormSimpleSearchCreateRequestMutation, EntryRequestFormSimpleSearchCreateRequestMutationVariables> {
    override document = EntryRequestFormSimpleSearchCreateRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntryRequestFormSimpleAuthCreateObjectDocument = gql`
    mutation EntryRequestFormSimpleAuthCreateObject($coordinates: [Float!]!, $fullAddress: String!, $ownerOrganizationId: ID!, $onDuplicate: OnDuplicate!) {
  authCreateObject(
    coordinates: $coordinates
    fullAddress: $fullAddress
    ownerOrganizationId: $ownerOrganizationId
    onDuplicate: $onDuplicate
  ) {
    id
    ref
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormSimpleAuthCreateObjectMutationGql extends Apollo.Mutation<EntryRequestFormSimpleAuthCreateObjectMutation, EntryRequestFormSimpleAuthCreateObjectMutationVariables> {
    override document = EntryRequestFormSimpleAuthCreateObjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }