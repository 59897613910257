import { register } from "swiper/element/bundle";
import dayjs from "dayjs";
import "dayjs/locale/ru.js";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import dayjsRelativeTimePlugin from "dayjs/plugin/relativeTime";
import dayjsDurationPlugin from "dayjs/plugin/duration";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
  withPreloading,
} from "@angular/router";
import { appRoutes } from "./app/app.routes";
import { IonicRouteStrategy, provideIonicAngular } from "@ionic/angular/standalone";
import { provideApollo } from "apollo-angular";
import { Api } from "./app/apollo/api";
import { ErrorHandler, importProvidersFrom, inject } from "@angular/core";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { YaCoreModule } from "./app/yamapng/src/lib/core.module";
import { tokenInterceptor } from "./app/token.interceptor";
import { InMemoryCache } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { ApmModule } from "@elastic/apm-rum-angular";
import { graphqlErrorInterceptor } from "./app/graphql-error.interceptor";
import { BACKEND_URLS, GRAPHQL_URI } from "./app/utils";
import { CustomErrorHandler } from "./app/custom-error-handler";
import { IffyStorageService } from "./app/iffy-storage.service";

register();

dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsRelativeTimePlugin);
dayjs.extend(dayjsDurationPlugin);
dayjs.locale("ru");

const main = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      provideHttpClient(withInterceptors([tokenInterceptor, graphqlErrorInterceptor])),
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      provideRouter(appRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
      provideIonicAngular({
        mode: "md",
        backButtonIcon: "chevron-back",
        innerHTMLTemplatesEnabled: true,
      }),
      provideApollo(
        () => {
          const httpLink = inject(HttpLink);
          const iffyStorage = inject(IffyStorageService);
          const httpLinkOptions = new Proxy(
            {},
            {
              get(target, property, _receiver) {
                if (property === "uri") {
                  const url = iffyStorage.getItem("backendUrl") || BACKEND_URLS.production;
                  return url + GRAPHQL_URI;
                }
                return (target as any)[property];
              },
            },
          );
          return {
            link: httpLink.create(httpLinkOptions),
            cache: new InMemoryCache(),
            defaultOptions: {
              query: {
                fetchPolicy: "no-cache",
              },
              watchQuery: {
                fetchPolicy: "no-cache",
              },
              mutate: {
                fetchPolicy: "no-cache",
              },
            },
          };
        },
        { useInitialLoading: true },
      ),
      { provide: ErrorHandler, useClass: CustomErrorHandler },
      importProvidersFrom(
        ApmModule,
        YaCoreModule.forRoot({
          apiKey: "95275d77-20a8-4d12-a9df-0ccff7271600",
        }),
      ),
      IffyStorageService,
      Api,
    ],
  });
main();
