import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { appInfo } from "../app-info";
import { inject } from "@angular/core";
import { IffyStorageService } from "./iffy-storage.service";

export function tokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const iffyStorage = inject(IffyStorageService);
  const appCheck = iffyStorage.getItem("appCheckToken") ?? "";
  const auth = iffyStorage.getItem("authToken") ?? "";
  const tokenReq = req.clone({
    headers: req.headers
      .set("X-App", `${appInfo.name}@${appInfo.version}`)
      .set("X-Firebase-AppCheck", appCheck)
      .set("Authorization", `Bearer ${auth}`),
  });
  return next(tokenReq);
}
