<swiper-container #photoSwiperContainer [modules]="swiperModules" [pagination]="true">
  @for (url of photoUrls; track url) {
    <swiper-slide>
      <ion-img [src]="url" class="photo-background"></ion-img>
      <ion-img [src]="url"></ion-img>
    </swiper-slide>
  } @empty {
    <div class="no-photo">
      <ion-icon name="camera"></ion-icon>
      <ion-text>Нет фото</ion-text>
    </div>
  }
</swiper-container>
