import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonText,
  IonToolbar,
  ToastController,
} from "@ionic/angular/standalone";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  RegistrationUnavailableRegionAuthRegionListPublicQueryGql,
  RegistrationUnavailableRegionAuthSaveLeadMutationGql,
} from "./registration-unavailable-region.gql-gen";
import { firstValueFrom } from "rxjs";
import { RegistrationDataService } from "../registration-page/registration-data.service";
import { callPhoneNumber, SUPPORT_PHONE_NUMBER } from "../utils";

@Component({
  selector: "app-registration-unavailable-region",
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonContent,
    IonText,
    IonList,
    IonItem,
    IonInput,
    ReactiveFormsModule,
    IonButton,
    IonFooter,
  ],
  templateUrl: "./registration-unavailable-region.component.html",
  styleUrl: "./registration-unavailable-region.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationUnavailableRegionComponent implements OnInit {
  contactFormGroup = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    city: new FormControl("", [Validators.required]),
  });

  selectedRegion = "";
  supportPhoneNumber = SUPPORT_PHONE_NUMBER;

  constructor(
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private authRegionListPublicQueryGql: RegistrationUnavailableRegionAuthRegionListPublicQueryGql,
    private authSaveLeadMutationGql: RegistrationUnavailableRegionAuthSaveLeadMutationGql,
    private toastController: ToastController,
    private router: Router,
    protected registrationData: RegistrationDataService,
  ) {}

  async ngOnInit(): Promise<void> {
    const { queryParams } = this.activatedRoute.snapshot;
    const regionCode = queryParams["regionCode"];
    const res = await firstValueFrom(this.authRegionListPublicQueryGql.fetch());
    const region = res.data.authRegionListPublic.find((r) => r?.code === regionCode);
    if (region) {
      this.selectedRegion = region.prepositionalCaseName;
      this.contactFormGroup.patchValue({ city: region.name });
    } else {
      this.selectedRegion = "вашем регионе";
    }
    this.cdRef.markForCheck();
  }

  onSupportPhoneClick() {
    callPhoneNumber(this.supportPhoneNumber);
  }

  async onContactFormSubmit() {
    const { queryParams } = this.activatedRoute.snapshot;
    const phoneNumber = this.registrationData.phoneNumber;
    const confirmationCode = this.registrationData.confirmationCode;
    const regionCode = queryParams["regionCode"] ?? "";
    const firstName = this.contactFormGroup.value.firstName ?? "";
    const lastName = this.contactFormGroup.value.lastName ?? "";
    const email = this.contactFormGroup.value.email ?? "";
    const cityName = this.contactFormGroup.value.city ?? "";

    await firstValueFrom(
      this.authSaveLeadMutationGql.mutate({
        phoneNumber,
        confirmationCode,
        regionCode,
        firstName,
        lastName,
        email,
        cityName,
      }),
    );
    const toast = await this.toastController.create({
      message: "Ваша заявка принята, спасибо за проявленный интерес!",
      position: "middle",
      duration: 5000,
    });
    await toast.present();
    await this.router.navigate(["/sign-in"]);
  }
}
