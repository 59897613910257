import { Injectable } from "@angular/core";

const STORAGE_KEYS = {
  backendUrl: "MOBAPP_V2_BACKEND_URL",
  frontendUrl: "MOBAPP_V2_FRONTEND_URL",
  authToken: "MOBAPP_V2_AUTH_TOKEN",
  welcomePageShown: "MOBAPP_V2_WELCOME_PAGE_SHOWN",
  signInRedirectUrl: "MOBAPP_V2_SIGN_IN_REDIRECT_URL",
  appCheckToken: "MOBAPP_V2_APP_CHECK_TOKEN",
} as const;

/**
 * iOS can clear localStorage DURING work, wtf?!
 * keep copy of data in RAM
 */
@Injectable()
export class IffyStorageService {
  private data: Record<string, string | null> = {};

  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  constructor() {
    // populate storage with localStorage and Preferences data
    window.addEventListener("message", (msg: MessageEvent) => this.onWindowMessage(msg));
    for (const kn of Object.values(STORAGE_KEYS)) {
      this.data[kn] = localStorage.getItem(kn);
      const msg = { from: "mobappV2", cmd: "getPreferences", key: kn };
      this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
    }
  }

  getItem(keyName: keyof typeof STORAGE_KEYS) {
    const kn = STORAGE_KEYS[keyName];
    return this.data[kn] ?? null;
  }

  setItem(keyName: keyof typeof STORAGE_KEYS, keyValue: string) {
    const kn = STORAGE_KEYS[keyName];
    this.data[kn] = keyValue;
    localStorage.setItem(kn, keyValue);
    const msg = { from: "mobappV2", cmd: "setPreferences", key: kn, value: keyValue };
    this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
  }

  private onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd !== "getPreferencesResult") {
      return;
    }
    if (Object.values(STORAGE_KEYS).includes(data.key)) {
      if (typeof data.value === "string") {
        localStorage.setItem(data.key, data.value);
        this.data[data.key] = data.value;
      }
    }
  }
}
