import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  AlertController,
  IonActionSheet,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonicSlides,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/angular/standalone";

import { firstValueFrom, Subscription } from "rxjs";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { Api } from "../apollo/api";
import { CreateMachineDto } from "../apollo/api.interfaces";
import { assertIsDefined, isDefined } from "../utils";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionSheetButton } from "@ionic/angular";
import { ApolloQueryResult } from "@apollo/client";
import { MaskitoOptions, maskitoTransform } from "@maskito/core";
import { MaskitoDirective } from "@maskito/angular";
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from "@maskito/kit";
import { RC_MACHINE_DELETED, RC_MACHINE_UPDATED } from "../custom-events";
import {
  ProfileMachineFormAuthCurrentUserQueryGql,
  ProfileMachineFormMachineQuery,
  ProfileMachineFormMachineQueryGql,
  ProfileMachineFormMachineTypesQuery,
  ProfileMachineFormMachineTypesQueryGql,
  ProfileMachineFormSearchDeleteMachineMutationGql,
} from "./profile-machine-form.gql-gen";

type MachineTypeUIData = {
  uuid: string;
  ruName: string;
  params: {
    uuid: string;
    name: string;
    ruName: string;
    type: string;
    unit: string;
    placeholder: string;
    settings: {
      min?: number;
      max?: number;
      select: {
        name: string;
        value: string;
      }[];
    };
    required: boolean;
  }[];
  equips: {
    uuid: string;
    name: string;
    ruName: string;
    imgUrl?: string;
  }[];
};

type PhotoItemUI = {
  url: string;
};

@Component({
  selector: "app-profile-machine-form",
  standalone: true,
  imports: [
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonToggle,
    IonSelect,
    IonSelectOption,
    IonFooter,
    IonText,
    ReactiveFormsModule,
    IonActionSheet,
    IonImg,
    IonTextarea,
    IonThumbnail,
    MaskitoDirective,
    IonBackButton,
  ],
  templateUrl: "./profile-machine-form.component.html",
  styleUrl: "./profile-machine-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfileMachineFormComponent implements OnInit, OnDestroy {
  @ViewChild("photoSwiperContainer") photoSwiperContainer: ElementRef<HTMLElement> | undefined;
  @ViewChild("changePhotoActionSheet") changePhotoActionSheet: IonActionSheet | undefined;

  protected machineTypes: MachineTypeUIData[] = [];
  protected selectedMachineType?: MachineTypeUIData;
  protected machineId = "";
  protected machineUuid = "";
  protected machineMainGroup = this.fb.group({
    model: this.fb.control("", { validators: [Validators.required] }),
    year: this.fb.control("", {
      validators: [Validators.required, Validators.pattern(/^\d+$/), Validators.min(1971)],
    }),
  });
  protected machineParamRecord = this.fb.record({});
  protected machineAdditionalGroup = this.fb.group({
    pricePerMinOrder: this.fb.control(""),
    pricePerWorkShift: this.fb.control(""),
    reg: this.fb.control(""),
    description: this.fb.control(""),
  });
  protected machineSelectedEquips: MachineTypeUIData["equips"] = [];
  protected addEquipButtons: ActionSheetButton[] = [];
  protected machinePhotos: PhotoItemUI[] = [];
  private selectedMachinePhoto?: PhotoItemUI;
  private machineRef = "";
  private deleteMachineSub?: Subscription;
  protected addNewPhotoButtons = [
    {
      text: "C камеры",
      handler: () => {
        const msg = { from: "mobappV2", cmd: "getPictureFromCamera" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      },
    },
    {
      text: "Из галереи",
      handler: () => {
        const msg = { from: "mobappV2", cmd: "getPictureFromGallery" };
        this.maybeMobappV1Window.postMessage(msg, this.mobappV1Origin);
      },
    },
  ];
  protected changePhotoButtons = [
    {
      text: "Открыть фото",
      handler: () => {
        if (this.selectedMachinePhoto) {
          open(this.selectedMachinePhoto.url);
        }
      },
    },
    {
      text: "Удалить фото",
      role: "destructive",
      handler: () => {
        this.machinePhotos = this.machinePhotos.filter(
          (p) => p.url !== this.selectedMachinePhoto?.url,
        );
        this.cdRef.detectChanges();
        this.updateSwiperSlides();
      },
    },
  ];

  protected deleteMachineButtons = [
    {
      text: "Отмена",
      role: "cancel",
      handler: () => {},
    },
    {
      text: "Удалить",
      role: "destructive",
      handler: async () => {
        this.deleteMachineSub?.unsubscribe();
        this.deleteMachineSub = this.searchDeleteMachineMutationGql
          .mutate({ machineRef: this.machineRef })
          .subscribe({
            next: async (res) => {
              if (res.data?.searchDeleteMachine.ok) {
                const queryParams = {
                  segment: "machines",
                };
                const machineDeleteEvent = new CustomEvent<string>(RC_MACHINE_DELETED, {
                  detail: this.machineId,
                });
                window.dispatchEvent(machineDeleteEvent);
                await this.router.navigate(["/home/profile"], { queryParams, replaceUrl: true });
              }
            },
          });
      },
    },
  ];

  protected swiperModules = [IonicSlides];
  protected readonly priceMask: MaskitoOptions = maskitoNumberOptionsGenerator({ min: 1 });
  readonly maskPredicate = async (el: unknown) => (el as HTMLIonInputElement).getInputElement();

  private organizationUuid = "";
  private maybeMobappV1Window = window.parent;
  private mobappV1Origin = "*"; // todo: use proper origin

  constructor(
    private cdRef: ChangeDetectorRef,
    private machineTypesQueryGql: ProfileMachineFormMachineTypesQueryGql,
    private authCurrentUserQueryGql: ProfileMachineFormAuthCurrentUserQueryGql,
    private machineQueryGql: ProfileMachineFormMachineQueryGql,
    private searchDeleteMachineMutationGql: ProfileMachineFormSearchDeleteMachineMutationGql,
    private api: Api,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertController: AlertController,
  ) {}

  async ngOnInit(): Promise<void> {
    this.machineId = this.activatedRoute.snapshot.queryParams["machineId"] ?? "";

    const machineTypeReq = firstValueFrom(this.machineTypesQueryGql.fetch());
    const currentUserReq = firstValueFrom(this.authCurrentUserQueryGql.fetch());
    // send req even if add flow, BE will find nothing, it's ok
    const machineReq = firstValueFrom(this.machineQueryGql.fetch({ machineId: this.machineId }));

    const [machineTypeRes, currentUserRes, machineRes] = await Promise.all([
      machineTypeReq,
      currentUserReq,
      machineReq,
    ]);

    const { machineTypes } = machineTypeRes.data;
    assertIsDefined(machineTypes); // if machine types are not found by id - it is error
    this.initMachineTypes(machineTypes);

    this.organizationUuid = currentUserRes.data.authCurrentUser?.organizations[0]?.uuid ?? "";

    const { machine } = machineRes.data;
    if (this.machineId) {
      assertIsDefined(machine); // if machine is not found by id - it is error
      this.machineUuid = machine.uuid;
      this.machineRef = machine.ref;
      for (const machineType of this.machineTypes) {
        if (machineType.uuid === machine.type) {
          this.selectMachineType(machineType);
          this.prefillMachineForm(machine);
          this.syncAddEquipButtons();
          break;
        }
      }
    }
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.deleteMachineSub?.unsubscribe();
  }

  @HostListener("window:message", ["$event"])
  async onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== "mobappV1") {
      return;
    }
    if (data.cmd === "getPictureResult") {
      this.machinePhotos.push({ url: data.dataUrl });
      this.updateSwiperSlides();
    }
  }

  onMachineTypeClick(ev: Event, item: MachineTypeUIData) {
    ev.preventDefault();
    this.selectMachineType(item);
    this.syncAddEquipButtons();
  }

  async onPhotoItemClick(ev: Event, photo: PhotoItemUI) {
    ev.preventDefault();
    if (!this.changePhotoActionSheet) {
      return;
    }
    this.selectedMachinePhoto = photo;
    await this.changePhotoActionSheet.present();
  }

  async onMachineSaveBtnClick(ev: Event) {
    if (!this.selectedMachineType) {
      throw new Error("machineType.not.selected");
    }
    ev.preventDefault();

    const photosDto: CreateMachineDto["photos"] = [];
    for (const photo of this.machinePhotos) {
      if (photo.url.startsWith("data:")) {
        const [dataType] = photo.url.split(";");
        const [, mimeType] = dataType.split(":");
        const [, fileType] = mimeType.split("/");
        const res = await fetch(photo.url);
        const blob = await res.blob();
        const file = new File([blob], `machinePhoto.${fileType}`, { type: mimeType });
        const fileRes = await this.api.saveMachinePhoto(file, this.organizationUuid);
        photosDto.push({ type: "photo", src: fileRes.url });
      } else if (photo.url.startsWith("http")) {
        photosDto.push({ type: "photo", src: photo.url });
      }
    }
    const machineMainValue = this.machineMainGroup.value;
    const machineParamValue = this.machineParamRecord.value;
    const machineAdditionalValue = this.machineAdditionalGroup.value;

    const paramsDto: CreateMachineDto["params"] = [];
    for (const mp of this.selectedMachineType.params ?? []) {
      let paramValue = machineParamValue[mp.name] as string | number | null | undefined;
      if (isDefined(paramValue)) {
        if (mp.type === "range" && typeof paramValue === "string") {
          paramValue = parseFloat(paramValue);
        }

        paramsDto.push({
          refUuid: mp.uuid,
          value: paramValue,
        });
      }
    }
    for (const mq of this.machineSelectedEquips) {
      paramsDto.push({ refUuid: mq.uuid, value: true });
    }
    const machineData: CreateMachineDto = {
      model: machineMainValue.model ?? "",
      reg: machineAdditionalValue.reg ?? "",
      year: parseFloat(machineMainValue.year ?? "0") || 0,
      description: machineAdditionalValue.description ?? "",
      params: paramsDto,
      photos: photosDto,
      type: this.selectedMachineType.uuid,
      prices: [],
    };
    if (machineAdditionalValue.pricePerMinOrder) {
      // form validator ensure the value has numbers only
      machineData.prices.push({
        type: "pricePerMinOrder",
        value: maskitoParseNumber(machineAdditionalValue.pricePerMinOrder),
      });
    }
    if (machineAdditionalValue.pricePerWorkShift) {
      // form validator ensure the value has numbers only
      machineData.prices.push({
        type: "pricePerWorkShift",
        value: maskitoParseNumber(machineAdditionalValue.pricePerWorkShift),
      });
    }
    if (this.machineUuid) {
      const res = await this.api.updateMachine(machineData, {
        orgUuid: this.organizationUuid,
        machineUuid: this.machineUuid,
      });
      this.machineUuid = res.uuid;
    } else {
      const res = await this.api.createMachine(machineData, {
        orgUuid: this.organizationUuid,
      });
      this.machineUuid = res.uuid;
    }
    const machineUpdateEvent = new CustomEvent(RC_MACHINE_UPDATED);
    window.dispatchEvent(machineUpdateEvent);
    const queryParams = {
      machineUuid: this.machineUuid,
    };
    await this.router.navigate(["/profile-machine-viewing"], { queryParams, replaceUrl: true });

    this.cdRef.markForCheck();
  }

  onSelectedEquipDeleteClick(ev: Event, equip: MachineTypeUIData["equips"][number]) {
    ev.preventDefault();
    this.machineSelectedEquips = this.machineSelectedEquips.filter((a) => a.uuid !== equip.uuid);
    this.syncAddEquipButtons();
    this.cdRef.markForCheck();
  }

  private updateSwiperSlides() {
    setTimeout(() => {
      if ((this.photoSwiperContainer?.nativeElement as any).swiper) {
        (this.photoSwiperContainer?.nativeElement as any).swiper.updateSlides();
      }
    });
  }

  private initMachineTypes(
    machineTypes: NonNullable<
      ApolloQueryResult<ProfileMachineFormMachineTypesQuery>["data"]["machineTypes"]
    >,
  ) {
    for (const item of machineTypes ?? []) {
      if (!item) {
        continue;
      }
      const uiMachineType: MachineTypeUIData = {
        uuid: item.uuid,
        ruName: item.nameRU,
        params: [],
        equips: [],
      };
      for (const param of item.params ?? []) {
        if (!param) {
          continue;
        }
        if (!param.paramPurpose?.main && !param.paramPurpose?.usual) {
          continue;
        }
        if (param.isEquipment) {
          uiMachineType.equips.push({
            uuid: param.uuid,
            name: param.name,
            ruName: param.nameRU,
            imgUrl: this.buildEquipmentUrl(param),
          });
        } else {
          const txt: string[] = [];
          if (isDefined(param.settings?.min)) {
            txt.push(`от ${param.settings.min}`);
          }
          if (isDefined(param.settings?.max)) {
            txt.push(`до ${param.settings.max}`);
          }
          uiMachineType.params.push({
            uuid: param.uuid,
            name: param.name,
            ruName: param.nameRU,
            unit: param.unit ?? "",
            type: param.type,
            placeholder: txt.join(" "),
            settings: {
              min: param.settings?.min,
              max: param.settings?.max,
              select: param.settings?.select ?? [],
            },
            required: param.isRequired ?? false,
          });
        }
      }
      this.machineTypes.push(uiMachineType);
    }
    this.machineTypes.sort((a, b) => {
      if (a.ruName > b.ruName) {
        return 1;
      }
      if (a.ruName < b.ruName) {
        return -1;
      }
      return 0;
    });
  }

  private selectMachineType(machineType: MachineTypeUIData) {
    this.selectedMachineType = machineType;
    this.machineParamRecord = this.fb.record({});
    for (const param of machineType.params) {
      let ctrl;
      if (param.type === "boolean") {
        ctrl = this.fb.control(false);
      } else {
        ctrl = this.fb.control("");
      }
      if (param.required) {
        ctrl.addValidators(Validators.required);
      }
      if (isDefined(param.settings.min)) {
        ctrl.addValidators(Validators.min(param.settings.min));
      }
      if (isDefined(param.settings.max)) {
        ctrl.addValidators(Validators.max(param.settings.max));
      }
      this.machineParamRecord.addControl(param.name, ctrl);
    }
    this.machineSelectedEquips = [];
  }

  private prefillMachineForm(machine: NonNullable<ProfileMachineFormMachineQuery["machine"]>) {
    if (!this.selectedMachineType) {
      throw new Error("machineType.not.selected");
    }
    this.machineMainGroup.patchValue({
      model: machine.model,
      year: machine.year + "",
    });
    for (const photo of machine.photos) {
      if (photo?.src) {
        this.machinePhotos.push({ url: photo.src });
      }
    }
    if (machine.pricePerMinOrder) {
      this.machineAdditionalGroup.patchValue({
        pricePerMinOrder: maskitoTransform(machine.pricePerMinOrder + "", this.priceMask),
      });
    }
    if (machine.pricePerWorkShift) {
      this.machineAdditionalGroup.patchValue({
        pricePerWorkShift: maskitoTransform(machine.pricePerWorkShift + "", this.priceMask),
      });
    }
    if (machine.reg) {
      this.machineAdditionalGroup.patchValue({
        reg: machine.reg,
      });
    }
    if (machine.description) {
      this.machineAdditionalGroup.patchValue({
        description: machine.description,
      });
    }
    for (const p of machine.params) {
      if (p.spec.isEquipment && p.valueBoolean) {
        for (const mtp of this.selectedMachineType.equips ?? []) {
          if (p.refUuid === mtp.uuid) {
            this.machineSelectedEquips.push(mtp);
          }
        }
      } else {
        if (p.spec.type === "boolean" && p.valueBoolean) {
          this.machineParamRecord.patchValue({
            [p.spec.name]: p.valueBoolean,
          });
        }
        if (p.spec.type === "range" && p.valueRange) {
          this.machineParamRecord.patchValue({
            [p.spec.name]: p.valueRange,
          });
        }
        if (p.spec.type === "select" && p.value) {
          this.machineParamRecord.patchValue({
            [p.spec.name]: p.value,
          });
        }
      }
    }
  }

  private buildEquipmentUrl(
    param: NonNullable<
      NonNullable<ProfileMachineFormMachineTypesQuery["machineTypes"]>[number]
    >["params"][number],
  ) {
    if (param?.ids?.idRentalParam) {
      return `assets/equipment/${param?.ids?.idRentalParam}.jpg`;
    }
    return;
  }

  private syncAddEquipButtons() {
    this.addEquipButtons = [];
    if (!this.selectedMachineType) {
      return;
    }
    for (const equip of this.selectedMachineType.equips) {
      const found = this.machineSelectedEquips.find((a) => a.uuid === equip.uuid);
      if (!found) {
        const btn = {
          text: equip.ruName,
          handler: () => {
            this.machineSelectedEquips.push(equip);
            this.addEquipButtons = this.addEquipButtons.filter((b) => b !== btn);
            this.cdRef.markForCheck();
          },
        };
        this.addEquipButtons.push(btn);
      }
    }
  }

  async onDeleteMachineIconClick(ev: Event) {
    ev.preventDefault();
    const alertDeleteMachine = await this.alertController.create({
      header: "Удалить эту технику?",
      message: "Действие нельзя будет отменить",
      buttons: this.deleteMachineButtons,
    });

    await alertDeleteMachine.present();
  }
}
