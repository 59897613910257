import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfileMachineViewingMachineQueryVariables = Types.Exact<{
  machineUuid: Types.Scalars['String']['input'];
}>;


export type ProfileMachineViewingMachineQuery = { __typename?: 'Query', machine?: { __typename?: 'Machine', id: string, uuid: string, model: string, year?: number | null, status?: Types.EnumMachineStatus | null, verificationStatus?: Types.EnumMachineVerificationStatus | null, pricePerWorkShift?: number | null, pricePerMinOrder?: number | null, reg?: string | null, description?: string | null, photos: Array<{ __typename?: 'MachinePhotos', src: string }>, typeData: { __typename?: 'MachineType', id: string, name: string, nameRU: string }, params: Array<{ __typename?: 'MachineParams', valueBoolean?: boolean | null, valueRange?: number | null, valueSelect?: string | null, refUuid: string, spec: { __typename?: 'MachineTypeParams', name: string, nameRU: string, type: Types.EnumMachineTypeParamsType, unit?: string | null, isEquipment?: boolean | null, ids?: any | null } }>, date?: { __typename?: 'MachineDate', available?: number | null } | null, availabilityStatus?: { __typename?: 'MachineAvailabilityStatus', status?: Types.EnumMachineAvailabilityStatusStatus | null, statusEnds?: any | null } | null } | null };

export type ProfileMachineViewingMachineSetAvailabilityStatusMutationVariables = Types.Exact<{
  machineUuid: Types.Scalars['String']['input'];
  status?: Types.InputMaybe<Types.Status>;
  busyDurationDays?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ProfileMachineViewingMachineSetAvailabilityStatusMutation = { __typename?: 'Mutation', machineSetAvailabilityStatus: { __typename?: 'Machine', id: string } };

export const ProfileMachineViewingMachineDocument = gql`
    query ProfileMachineViewingMachine($machineUuid: String!) {
  machine(uuid: $machineUuid) {
    id
    uuid
    model
    year
    photos {
      src
    }
    typeData {
      id
      name
      nameRU
    }
    params {
      spec {
        name
        nameRU
        type
        unit
        isEquipment
        ids
      }
      valueBoolean
      valueRange
      valueSelect
      refUuid
    }
    date {
      available
    }
    status
    verificationStatus
    availabilityStatus {
      status
      statusEnds
    }
    pricePerWorkShift
    pricePerMinOrder
    reg
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineViewingMachineQueryGql extends Apollo.Query<ProfileMachineViewingMachineQuery, ProfileMachineViewingMachineQueryVariables> {
    override document = ProfileMachineViewingMachineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileMachineViewingMachineSetAvailabilityStatusDocument = gql`
    mutation ProfileMachineViewingMachineSetAvailabilityStatus($machineUuid: String!, $status: status, $busyDurationDays: Int) {
  machineSetAvailabilityStatus(
    machineUuid: $machineUuid
    status: $status
    busyDurationDays: $busyDurationDays
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineViewingMachineSetAvailabilityStatusMutationGql extends Apollo.Mutation<ProfileMachineViewingMachineSetAvailabilityStatusMutation, ProfileMachineViewingMachineSetAvailabilityStatusMutationVariables> {
    override document = ProfileMachineViewingMachineSetAvailabilityStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }