import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import {
  Entry,
  EntryProposes,
  EntrySearchItem,
  Maybe,
  Organization,
  Organization2,
  OrganizationCompanies,
  OrganizationCompany,
  User,
  User2,
} from "../../../base-types.gql-gen";
import { callPhoneNumber, phoneFormatOnlyNumbers, phoneFormatUniversal } from "../../utils";
import { CompanyInfoComponent } from "../../entry-details-machine/company-info/company-info.component";
import { IonAvatar, IonIcon, ModalController } from "@ionic/angular/standalone";

@Component({
  selector: "app-entry-sign-signature",
  templateUrl: "./entry-sign-signature.component.html",
  styleUrls: ["./entry-sign-signature.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonIcon, IonAvatar],
})
export class EntrySignSignatureComponent implements OnInit {
  @Input() myPropose?: Maybe<EntryProposes>;
  @Input() entry?: Entry;
  @Input() currentUserId?: string;
  @Input() role: "customer" | "supplier" | "dispatcher" = "customer";
  @Input() supplier?: Maybe<EntrySearchItem>;

  company?: Maybe<OrganizationCompanies | OrganizationCompany>;
  organization?: Maybe<Organization | Organization2>;
  user?: Maybe<User2 | User>;
  editable = false;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    const data =
      this.role === "dispatcher"
        ? this.entry?.dispatcher
        : this.role === "supplier"
          ? this.supplierData
          : this.customerData;
    this.user = data?.user;
    if (!this.user && this.role === "supplier") this.user = this.suppUser;
    this.company = data?.company;
    this.organization = data?.organization;
  }

  get customerData() {
    const customer = this.myPropose?.customerSignature || this.entry?.customer;
    return customer || this.myPropose?.customer;
  }

  get supplierData() {
    const supplier = this.myPropose?.supplierSignature;
    return supplier || this.myPropose?.supplier;
  }

  get suppUser() {
    const users = this.myPropose?.responses?.[0]?.organization?.users;
    const firstUser = users?.[0]?.user;
    return users?.find((u) => u?.user?.uuid === this.currentUserId)?.user || firstUser;
  }

  async showCompany() {
    if (this.editable) return this.editCompany();
    else {
      const modal = await this.modalCtrl.create({
        component: CompanyInfoComponent,
        componentProps: {
          org: this.organization,
          companyId: this.company?.uuid,
        },
      });
      await modal.present();
    }
  }

  phoneFormat(phone: string): string {
    const phoneNumbers = phoneFormatOnlyNumbers(phone);
    if (phoneNumbers.length > 6) {
      return phoneFormatUniversal(phone);
    }
    return phone;
  }

  callTo(phone: string) {
    let phoneString = `${phone ? phone : ""}`;
    if (phone.length === 7) {
      phoneString = phone;
    }
    callPhoneNumber(phoneString);
  }

  editCompany() {}
}
