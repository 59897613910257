<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title class="ion-text-center">
      <h1>Вход в Rental Club</h1>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <p
    class="ion-padding-bottom"
    [class.invisible]="state() === 'enter-phone' || state() === 'wait-sms'"
  >
    Мы отправили код подтверждения
    <br />
    на номер {{ phoneNumber.value }}
  </p>
  <ion-item class="rcitem">
    <ion-input
      [formControl]="phoneNumber"
      [readonly]="state() === 'wait-sms'"
      [class.no-display]="!(state() === 'enter-phone' || state() === 'wait-sms')"
      inputmode="tel"
      label="Введите номер телефона"
      labelPlacement="floating"
      class="content-center"
    ></ion-input>
    <ion-input
      [formControl]="verificationCode"
      [readonly]="state() === 'wait-confirm'"
      [class.no-display]="!(state() === 'enter-sms' || state() === 'wait-confirm')"
      inputmode="tel"
      label="Введите код подтверждения"
      labelPlacement="floating"
      class="content-center"
    ></ion-input>
  </ion-item>
  @if (state() === "enter-sms" || state() === "wait-confirm") {
    <p class="ion-padding-top">
      @if (repeatSmsDate) {
        <ion-text>
          Запросить код повторно можно
          <br />
          через {{ timerText() }}
        </ion-text>
      } @else if (smsSent() < 4) {
        <ion-button (click)="onSubmitPhoneNumberBtnClick()" fill="clear">
          Отправить еще раз
        </ion-button>
      } @else {
        <ion-text>
          Не приходит код подтверждения?
          <br />
          <a (click)="onCallSupportBtnClick()">Узнать код по телефону</a>
        </ion-text>
      }
    </p>
    <p>
      <ion-button (click)="onResetBtnClick()" fill="clear">Вернуться</ion-button>
    </p>
  }
</ion-content>
<ion-footer class="ion-no-border">
  @if (state() === "enter-phone") {
    <ion-button
      (click)="onSubmitPhoneNumberBtnClick()"
      [disabled]="phoneNumber.invalid"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      <ion-text>Далее</ion-text>
    </ion-button>
  }
  @if (state() === "enter-sms") {
    <ion-button
      (click)="onSubmitConfirmCodeBtnClick()"
      [disabled]="verificationCode.invalid"
      class="ion-padding"
      expand="block"
      size="large"
      color="rcyellow"
    >
      <ion-text>Далее</ion-text>
    </ion-button>
  }
  @if (state() === "wait-sms" || state() === "wait-confirm") {
    <ion-button [disabled]="true" expand="block" class="ion-padding" size="large" color="rcyellow">
      <ion-spinner name="crescent"></ion-spinner>
    </ion-button>
  }
</ion-footer>
