import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonListHeader,
  IonTitle,
  IonToolbar,
  LoadingController,
  ModalController,
} from "@ionic/angular/standalone";
import { phoneFormatUniversal } from "../../utils";
import dayjs from "dayjs";
import { Api } from "../../apollo/api";
import {
  Machine,
  Organization,
  OrganizationCompanies,
  OrganizationUsers,
} from "../../../base-types.gql-gen";
import * as translations from "../../../interface.json";
import { MachineStatusBarComponent } from "../machine-status-bar/machine-status-bar.component";
import { ProviderMachineListCardComponent } from "../provider-machine-list-card/provider-machine-list-card.component";
import { CommunityUserCardComponent } from "../community/community-user-card.component";

export interface SortedMachine {
  uuid: string;
  name: string;
  machines: Machine[];
}

@Component({
  selector: "app-company-info",
  templateUrl: "company-info.component.html",
  styleUrls: ["./company-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonListHeader,
    MachineStatusBarComponent,
    ProviderMachineListCardComponent,
    CommunityUserCardComponent,
  ],
})
export class CompanyInfoComponent implements OnInit {
  @Input() org!: Organization;
  @Input() companyId = "";
  machines: SortedMachine[] = [];
  company?: OrganizationCompanies | null;
  users?: (OrganizationUsers | null)[] | null;

  constructor(
    private api: Api,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private cdRef: ChangeDetectorRef,
  ) {}

  get companyRegisteredTimer(): string {
    let timer;
    if (this.company) {
      timer = dayjs.unix(this.company.date?.created || 0).fromNow(true);
    } else {
      timer = dayjs.unix(this.users?.[0]?.user?.date?.created || 0).fromNow(true);
    }
    return timer;
  }

  getCategoryName(category: string) {
    // @ts-ignore
    return translations[category];
  }

  get companyIsConfirmed(): boolean {
    return !!this.company && !!this.company.verify;
  }

  get companyFoundation(): string {
    return dayjs(this.company?.date?.founded, "DD-MM-YYYY").format("DD.MM.YYYY");
  }

  get companyPhone() {
    const phone = this.org?.publicContact?.phone;
    return phone ? phoneFormatUniversal(phone) : "";
  }

  get getManagmentPost(): string {
    return this.company?.additional?.attr?.management?.post;
  }

  get getManagmentName(): string {
    return this.company?.additional?.attr?.management?.name;
  }

  async ngOnInit() {
    this.company = this.org.companies?.find(
      (c: OrganizationCompanies | null) => c?.uuid === this.companyId,
    );
    this.users = this.org.users;
    const loading = await this.loadingCtrl.create();
    await loading.present();
    await this.api
      .getMachines(this.org.uuid)
      .then((res) => {
        this.dividedCategories(res);
        this.cdRef.markForCheck();
        return loading.dismiss();
      })
      .catch((err) => {
        console.error(err);
        loading.dismiss();
      });
  }

  dividedCategories(machines: Machine[] = []) {
    if (!machines.length) {
      return;
    }
    this.machines = [];
    machines.forEach((machine) => {
      const category = this.machines?.find((v) => v.uuid === machine.typeData?.uuid);
      if (category) {
        category.machines.push(machine);
      } else {
        this.machines?.push({
          uuid: machine.typeData?.uuid || "",
          name: machine.typeData?.name || "",
          machines: [machine],
        });
      }
    });
    this.sortMachines();
  }

  sortMachines() {
    const machines = this.machines || [];
    this.machines = machines.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  back() {
    this.modalCtrl.dismiss();
  }
}
