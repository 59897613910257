<ion-header>
  <ion-toolbar>
    <ion-title>Адрес базы</ion-title>
    <ion-buttons slot="start">
      <ion-button
        class="ion-margin-vertical"
        shape="round"
        size="medium"
        slot="end"
        (click)="onToolbarCloseBtnClick($event)"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>

      <ion-alert
        [isOpen]="isConfirmationAlert"
        (didDismiss)="onConfirmationAlertDismiss($event)"
        header="Внимание"
        message="Введенные Вами данные не будут сохранены"
        [buttons]="alertButtons"
      ></ion-alert>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      placeholder="Введите адрес"
      [value]="addressInputValue"
      [debounce]="666"
      (input)="onAddressSearchbarInput($event)"
      (ionInput)="onAddressSearchbarIonInput($event)"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div #yaMapContainer class="yamap">
    <img class="center-pin" src="/assets/icon/map-marker.svg" />
  </div>
  @if (yaSuggests.length) {
    <div class="ya-suggest-list">
      <ion-list class="ion-padding">
        @for (sugg of yaSuggests; track sugg.uri) {
          <ion-item
            [button]="true"
            [detail]="false"
            (click)="onSuggestClick($event, sugg)"
            class="ion-no-padding"
          >
            <div class="suggest-text">
              <ion-text>
                {{ sugg.title.text }}
                <br />
              </ion-text>
              <ion-text color="medium">{{ sugg.subtitle?.text }}</ion-text>
            </div>
          </ion-item>
        }
      </ion-list>
    </div>
  }
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    (click)="onSaveAddressBtnClick($event)"
    [disabled]="!geoSynced"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Сохранить
  </ion-button>
</ion-footer>
