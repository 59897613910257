import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { callPhoneNumber, machineMap, paymentType } from "../../utils";
import {
  Entry,
  EntryProposes,
  EntryProposesResponses,
  Maybe,
  Organization,
} from "../../../base-types.gql-gen";
import { Api } from "../../apollo/api";
import { IonAvatar, IonButton, IonButtons, IonIcon } from "@ionic/angular/standalone";
import { Router } from "@angular/router";

@Component({
  selector: "app-propose-card",
  templateUrl: "propose-card.component.html",
  styleUrls: ["./propose-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonIcon, IonButtons, IonButton, IonAvatar],
})
export class ProposeCardComponent implements OnInit {
  @Input() propose?: Maybe<EntryProposes>;
  @Input() entry!: Entry;
  @Output() showCancelModal = new EventEmitter();
  @Output() refresh = new EventEmitter();
  company: any;
  user: any;
  machine: any;
  userPropose?: EntryProposesResponses | null;
  organization?: Organization | null;
  data: any;

  constructor(
    private api: Api,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userPropose = this.propose?.responses && this.propose?.responses[0];
    this.organization = this.userPropose?.organization;
    this.company = this.organization?.companies?.find((c: any) => c.isPrimary);
    this.user =
      this.organization?.users?.find((u: any) => u.uuid === this.propose?.ids?.uuidUserSupplier)
        ?.user ||
      (this.organization?.users && this.organization?.users[0]?.user);
    if (this.userPropose?.machine) {
      this.machine = machineMap(this.userPropose?.machine);
    }
    this.data = this.userPropose?.data;
  }

  get perUnit(): string {
    const unitLabel = this.getUnitLabel(this.data.unit);
    return `₽/${unitLabel}`;
  }

  get formattedPrice(): string {
    const fmt = new Intl.NumberFormat("ru-RU", { style: "decimal", maximumFractionDigits: 2 });
    return fmt.format(this.data.budgetValue / 100);
  }

  private getUnitLabel(unit?: number): string {
    if (unit === 2) {
      return "рейс";
    } else if (unit === 3) {
      return "куб";
    }
    return "смену";
  }

  get paymentType(): string {
    return paymentType(this.data.paymentType);
  }

  get distanceToMachineBase(): number {
    return this.data.distance && this.data.distance.toFixed(1);
  }

  get customerPropose() {
    return this.entry.proposes?.find((p) => p?.initiator === "customer");
  }

  get isSelected() {
    return this.customerPropose?.responses?.find(
      (r) => r?.organization?.uuid === this.organization?.uuid,
    );
  }

  showCompany() {
    const msg = {
      from: "mobappV2",
      cmd: "openInfo",
      user: this.organization,
      companyId: this.company?.uuid,
    };
    window.parent.postMessage(msg, "*");
  }

  async selectPropose() {
    const signedPropose = this.entry.proposes?.find((p) => p?.customerSignature);
    if (signedPropose && this.propose?.uuid !== signedPropose.uuid)
      this.showCancelModal.emit(this.propose);
    else {
      await this.router.navigate(["/entry-sign"], {
        queryParams: {
          entryId: this.entry.uuid,
          proposeId: this.propose?.uuid,
          viewAs: "customer",
        },
      });
    }
  }

  rejectPropose() {
    this.api.cancelPropose(this.propose?.ref || "").then(() => this.refresh.emit());
  }

  callTo() {
    const phoneNumber = `+${this.user.phone}`;
    callPhoneNumber(phoneNumber);
  }

  showMachine(machine: any) {
    this.router.navigate(["catalog-machine-viewing"], {
      queryParams: { machineUuid: machine.uuid },
    });
  }
}
