import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { assertIsDefined } from "../utils";
import { WelcomePage2AuthCurrentUserQueryGql } from "./welcome2-page.component.gql-gen";
import { IonButton, IonContent, IonFooter, IonHeader } from "@ionic/angular/standalone";
import { IffyStorageService } from "../iffy-storage.service";

@Component({
  selector: "app-welcome2-page",
  standalone: true,
  imports: [IonHeader, IonContent, IonFooter, IonButton],
  templateUrl: "./welcome2-page.component.html",
  styleUrl: "./welcome2-page.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Welcome2PageComponent implements OnDestroy {
  protected userFirstName?: string;

  private currentUserSub?: Subscription;
  private timeoutId: any;
  private iffyStorage = inject(IffyStorageService);

  constructor(
    private authCurrentUserQueryGql: WelcomePage2AuthCurrentUserQueryGql,
    private cdRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  ionViewDidEnter() {
    // if user taps push notification it will be handled by app.component
    // which may do redirect, so delay launch for a while
    this.timeoutId = setTimeout(async () => {
      // give some time to get data from native app
      await this.launch();
    }, 1000);
  }

  ionViewWillLeave() {
    clearTimeout(this.timeoutId);
  }

  ngOnDestroy() {
    this.currentUserSub?.unsubscribe();
  }

  private async launch() {
    const authToken = this.iffyStorage.getItem("authToken");
    const welcomeScreenShown = this.iffyStorage.getItem("welcomePageShown");
    if (!authToken) {
      await this.router.navigate(["/sign-in"], { replaceUrl: true });
    } else if (welcomeScreenShown !== "yes") {
      this.currentUserSub?.unsubscribe();
      this.currentUserSub = this.authCurrentUserQueryGql.fetch().subscribe((res) => {
        assertIsDefined(res.data.authCurrentUser);
        this.userFirstName = res.data.authCurrentUser.firstname ?? "";
        this.cdRef.markForCheck();
      });
    } else {
      await this.router.navigate(["/home/matches"], { replaceUrl: true });
    }
  }

  async onContinueButtonClick(ev: Event) {
    ev.preventDefault();
    this.iffyStorage.setItem("welcomePageShown", "yes");
    await this.router.navigate(["/home/matches"], { replaceUrl: true });
  }
}
