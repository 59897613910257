import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { IonList, IonSearchbar, IonSpinner } from "@ionic/angular/standalone";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { YaMapsAPILoader } from "../../yamapng/src/lib/services/ya-maps-loader";

interface SuggestedAddress {
  key?: string;
  title?: string;
  full: string;
  lat?: number;
  lng?: number;
  geocodeValue?: string;
}

@Component({
  selector: "app-address-pick-field",
  templateUrl: "address-pick-field.html",
  styleUrls: ["./address-pick-field.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressPickFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonSearchbar, IonSpinner, IonList, FormsModule],
})
export class AddressPickFieldComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input() placeholder = "";
  @Input() searchAddressText = "";
  @Output() entryAddressChanges = new EventEmitter();
  @Output() ionClear = new EventEmitter();
  @ViewChild("searchInput") searchInput!: any;
  @ViewChild("prevAddressesContainer") prevAddressesContainer!: TemplateRef<any>;
  @HostBinding("class.always-show-clear-icon")
  @Input()
  alwaysShowClearButton = false;

  private address = {
    title: "",
    key: "",
    uuid: "",
    full: "",
    lat: 55.75222,
    lng: 37.61556,
  };

  /** Previous addresses */
  @Input() previousAddresses: any[] = [];
  suggestedAddresses: SuggestedAddress[] = [];
  suggestedObjects: any[] = [];
  /** Set address from GPS */
  @Input() setGPS = true;

  public searchbarDisabled = false;

  private selectView!: EmbeddedViewRef<any>;
  private _selectOpened = false;
  _suggestionsLoading = false;
  _suggestionSelected = 0;
  private _prevSuggestSearch = "";
  private _selectCreated = false;

  private checkClickOutsidePicker!: any;
  private dropdownNode!: HTMLElement;
  private _searchClicked = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private mapsApi: YaMapsAPILoader,
  ) {
    this.suggestedObjects = this.previousAddresses;
  }

  ngAfterViewInit() {
    this.createSelect(this.prevAddressesContainer);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (typeof obj === "string") {
      this.searchAddressText = obj;
    } else if (!!obj) {
      if (
        this.address.lat === obj.lat &&
        this.address.lng === obj.lng &&
        this.address.full === obj.full
      ) {
        return;
      }
      this.address = Object.assign({ key: this.address.key }, obj);
      this.searchAddressText =
        (this.address.title ? this.address.title + ", " : "") + (this.address.full || "");
    } else {
      this.address = Object.assign({}, this.address, { full: null });
      this.searchAddressText = "";
    }
    this.updateSelectStatus();
    this.closeSelect();
  }

  updateSelectStatus() {
    if (!this.searchAddressText) {
      this.suggestedAddresses = [];
      this.suggestedObjects = this.previousAddresses;
    } else if (this.address && this.address.full) {
      this.requestSuggestions(this.address.full);
    }
    if (this._searchClicked && (this.suggestedAddresses.length || this.suggestedObjects.length)) {
      this.openSelect();
    } else {
      this.closeSelect();
    }
  }

  createSelect(dropdownTpl: any) {
    this.dropdownNode = dropdownTpl.nativeElement;
    this.checkClickOutsidePicker = (e: any) => {
      if (
        !this.dropdownNode.contains(e.target as HTMLElement) &&
        !this.searchInput.el.contains(e.target)
      ) {
        e.preventDefault();
        this._searchClicked = false;
        this.closeSelect();
      }
      this.changeDetector.markForCheck();
    };

    this.dropdownNode.style.display = "none";
    this._selectCreated = true;
  }

  openSelect() {
    if (this._selectCreated && !this._selectOpened) {
      this._selectOpened = true;
      document.body.addEventListener("touchstart", this.checkClickOutsidePicker);

      this.dropdownNode.style.display = "block";
      this.dropdownNode.style.width = `${
        this.searchInput.el.getElementsByTagName("input")[0].offsetWidth
      }px`;
    }
  }

  closeSelect() {
    if (this._selectCreated && this._selectOpened) {
      this._selectOpened = false;
      document.body.removeEventListener("click", this.checkClickOutsidePicker);
      this.dropdownNode.style.display = "none";
    }
  }

  ngOnDestroy() {
    this.closeSelect();
    this.dropdownNode.parentNode?.removeChild(this.dropdownNode);
    // google.maps.event.removeListener(this.autocompleteLsr);
    // google.maps.event.clearInstanceListeners(this.autocomplete);
  }

  public searchClick() {
    this._searchClicked = true;
    this.updateSelectStatus();
  }

  public searchFocusIn() {
    if (this.searchAddressText && this.searchAddressText.length > 0) {
      const position = this.searchAddressText ? this.searchAddressText.length : 0;
      const element = this.searchInput.el.getElementsByTagName("input")[0];
      element.scrollLeft = element.scrollWidth;
      setTimeout(() => {
        this.changeDetector.markForCheck();
        if (element.setSelectionRange) {
          element.setSelectionRange(position, position);
        } else {
          // @ts-ignore
          if (element.createTextRange) {
            // @ts-ignore
            const range = element.createTextRange();
            range.collapse(true);
            range.moveEnd("character", position);
            range.moveStart("character", position);
            range.select();
          }
        }
      }, 10);
    }
  }

  handleClear($event: any) {
    this.ionClear.emit($event);
    this.setAddress(null);
  }

  setAddress(_address: any) {
    // Object.assign(this.address, _address);
    this.writeValue(_address);
    this.onChange(this.address);
    this.entryAddressChanges.emit(this.address);
  }

  setAddressAndClose(_address: SuggestedAddress) {
    if (_address.lat) {
      this.setAddress(<any>_address);
      this.closeSelect();
    } else {
      this.mapsApi.load().then(() => {
        this._suggestionsLoading = true;
        this.changeDetector.markForCheck();
        ymaps
          .search(_address.geocodeValue, {
            results: 1,
            boundedBy: this.getRequestBounds(this.address),
          })
          .then((results: any) => {
            this._suggestionsLoading = false;
            if (results.responseMetaData.SearchResponse.found > 0) {
              const geoObject = results.geoObjects.get(0);
              this.setAddress({
                lat: geoObject.geometry.getCoordinates()[0],
                lng: geoObject.geometry.getCoordinates()[1],
                full: `${geoObject.properties.get("name")}, ${geoObject.properties.get(
                  "description",
                )}`,
              });
            } else {
              this.setAddress(null);
            }
            this.changeDetector.detectChanges();
          });
      });
    }
  }

  onInput() {
    this.updateSelectStatus();
    this.requestSuggestions(this.searchAddressText);
  }

  private requestSuggestions(searchString: string) {
    if (searchString.length && this._prevSuggestSearch !== searchString) {
      this.suggestedObjects = this.previousAddresses.filter((s) =>
        s.full.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    if (searchString.length && this._prevSuggestSearch !== searchString) {
      this._suggestionsLoading = true;
      this.mapsApi.load().then(() => {
        // @ts-ignore
        ymaps
          .suggest(searchString, {
            results: 10,
            provider: "yandex#search",
            boundedBy: this.getRequestBounds(this.address),
          })
          .then((results: any) => {
            const addresses: SuggestedAddress[] = [];
            const addedAddresses = {};
            results.forEach((suggestion: any) => {
              // @ts-ignore
              if (!addedAddresses[suggestion.value]) {
                // @ts-ignore
                addedAddresses[suggestion.value] = true;
                addresses.push({
                  geocodeValue: suggestion.value,
                  full: suggestion.displayName,
                });
              }
            });
            this._suggestionsLoading = false;
            this._suggestionSelected = 0;
            this._prevSuggestSearch = searchString;
            this.suggestedAddresses = addresses;
            this.changeDetector.detectChanges();
            this.selectView.detectChanges();

            if (this.searchAddressText.length && this.searchAddressText !== searchString) {
              this.requestSuggestions(this.searchAddressText);
            }
          });
      });
    }
  }

  private getRequestBounds(address: any, coordinateDistance: any = null) {
    if (!address || !address.lat) {
      return null;
    }
    if (!coordinateDistance) {
      coordinateDistance = [0.25, 0.5];
    } else if (coordinateDistance) {
      coordinateDistance = [coordinateDistance, coordinateDistance];
    }
    return [
      [
        address.lat - coordinateDistance[0] / 2, // lat
        address.lng - coordinateDistance[1] / 2, // long
      ],
      [
        address.lat + coordinateDistance[0] / 2, // lat
        address.lng + coordinateDistance[1] / 2, // long
      ],
    ];
  }
}
