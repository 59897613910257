import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { RouterLink } from "@angular/router";
import { AuthRegionListPublicQueryGql } from "./registration-page.gql-gen";
import { firstValueFrom } from "rxjs";
import { isDefined } from "../utils";

@Component({
  selector: "app-registration-page",
  standalone: true,
  imports: [
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    RouterLink,
    IonContent,
  ],
  templateUrl: "./registration-page.component.html",
  styleUrl: "./registration-page.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationPageComponent implements OnInit {
  regions: { code: string; name: string; status: string }[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private authRegionListPublicQueryGql: AuthRegionListPublicQueryGql,
  ) {}

  async ngOnInit() {
    const res = await firstValueFrom(this.authRegionListPublicQueryGql.fetch());
    this.regions = res.data.authRegionListPublic.filter(isDefined);
    this.cdRef.markForCheck();
  }
}
