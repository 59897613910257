import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  LoadingController,
  ModalController,
} from "@ionic/angular/standalone";
import { Entry, MachineType, MachineTypeParams } from "../../base-types.gql-gen";
import dayjs from "dayjs";
import { Api } from "../apollo/api";
import { FieldsEditComponent, InfoForUpdate } from "../fields-edit/fields-edit.component";

@Component({
  selector: "app-entry-edit",
  templateUrl: "entry-edit-modal.component.html",
  styleUrls: ["./entry-edit-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonFooter,
    FieldsEditComponent,
  ],
})
export class EntryEditModalComponent implements OnInit {
  @Input() entry!: Entry;
  @ViewChild("fieldsEdit") fieldsEditComponent!: FieldsEditComponent;
  edit = false;
  isKeyboardOpen = false;
  updatedData?: InfoForUpdate;

  constructor(
    private cd: ChangeDetectorRef,
    private loadingCtrl: LoadingController,
    private api: Api,
    private modalCtrl: ModalController,
  ) {}

  async ngOnInit() {
    const category = this.entry.requests[0]?.data?.machineTypeData;
    const categoryParams = await this.getParamsForCategory(category?.uuid);
    const customer = this.entry.customer;
    const organization = await this.api.getOrganization(customer?.organization?.uuid || "");
    const previousAddresses = [
      ...(organization.objects?.map((o) => {
        return {
          uuid: o.uuid,
          title: o.title,
          full: o.address?.fullAddress,
          lat: o.address?.coordinates?.[0],
          lng: o.address?.coordinates?.[1],
          object: o,
        };
      }) || []),
    ];
    this.setInitialData(categoryParams, previousAddresses);
  }

  @HostListener("window:message", ["$event"])
  onWindowMessage(ev: MessageEvent) {
    this.cd.markForCheck();
    if (ev.data?.cmd === "keyboard-open") {
      this.isKeyboardOpen = true;
    }
    if (ev.data?.cmd === "keyboard-closed") {
      this.isKeyboardOpen = false;
    }
  }

  async getParamsForCategory(categoryId: string = "") {
    const categories = (await this.api.getMachineCategories()) || [];
    const category = categories?.find(({ uuid }: MachineType) => uuid === categoryId);
    return (
      category?.params
        ?.filter((p: MachineTypeParams) => p?.paramPurpose?.entry)
        ?.filter((p: MachineTypeParams) => p?.ids?.idRentalParam !== 88) || []
    );
  }

  setInitialData(categoryParams: MachineTypeParams[] | null, previousAddresses: any[]) {
    const conditions = this.entry.requests[0]?.conditions;
    const payment = this.entry.requests[0]?.payment;
    const date = this.entry.requests[0]?.conditions?.date?.value;
    const objects = this.entry.objects;
    let calendarDate = dayjs().toISOString();
    if (this.entry.requests[0]?.conditions?.date?.type === "datetime" && date) {
      calendarDate = dayjs.unix(date)?.utc(true).toISOString();
    }
    this.updatedData = {
      calendarDate,
      categoryParams,
      previousAddresses,
      unit: conditions?.work?.type || "shift",
      workDays: conditions?.work?.value || 1,
      paymentType: payment?.type || "none",
      price: (conditions?.budget?.value || 0) / 100,
      comment: this.entry.requests[0]?.data?.comment?.trim() || "",
      objects: [...(objects || [])],
      entryParams: this.entry.requests[0]?.data?.params || [],
      date: date ? dayjs.unix(date) : undefined,
      address: {
        full: objects?.[0]?.address?.fullAddress,
        lat: objects?.[0]?.address?.coordinates?.[0],
        lng: objects?.[0]?.address?.coordinates?.[1],
      },
    };
    this.cd.markForCheck();
  }

  close() {
    return this.modalCtrl.dismiss();
  }

  updateEntryFields(entry: Entry) {
    const newData = this.fieldsEditComponent.getUpdatedData();
    const newEntry: Partial<Entry> = {
      uuid: entry.uuid,
      ids: entry.ids,
      status: entry.status,
      proposes: entry.proposes,
      participants: entry.participants,
      additional: {
        source: "userForm",
      },
    };
    if (entry.requests) {
      newEntry.requests = [
        {
          ...entry.requests[0],
          // @ts-ignore
          data: {
            ...entry.requests[0]?.data,
            comment: newData.comment,
            params: newData.entryParams,
          },
          conditions: {
            ...entry.requests[0]?.conditions,
            budget: {
              type: "total",
              value: newData.price * 100,
            },
            date: {
              type: newData.date ? "datetime" : "none",
              value: newData.date
                ? dayjs(newData.date.toISOString().replace("Z", "")).unix()
                : undefined,
            },
            work: {
              type: newData.unit,
              value: newData.workDays,
            },
          },
          payment: {
            type: newData.paymentType,
            value: newData.price * 100,
          },
        },
      ];
    }
    newEntry.objects = newData?.objects;
    return newEntry;
  }

  async updateEntry() {
    if (!this.edit) {
      this.edit = true;
      return;
    }
    const loading = await this.loadingCtrl.create();
    await loading.present();
    const org = this.entry.customer?.organization?.uuid || "";
    await this.api
      .changeEntry(this.updateEntryFields(this.entry), org, "update")
      .then(() => this.modalCtrl.dismiss(true))
      .catch(() => {
        loading.dismiss();
      });
    await loading.dismiss();
  }
}
